import React from 'react';
import { IconDeviceDesktopAnalytics } from '@tabler/icons-react';
import { ROLES } from '@deecision/dna-interfaces/dist/constants/roles';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import DataElementsStatsView from './deStatsView';
import DataElementsStatsByType from './tabs/stats.tabs.by.type';
import DataElementsDetailsHeader from './detailsHeader';
import DataElementsStatsByStructureChanged from './tabs/stats.tabs.structure.changed';
import DataElementsStatsByRecentCompleted from './tabs/stats.tabs.recent.completed';

const DEsMonitoringTabs: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.admin.dataElementsStatus.tabs.byType.label,
    i18nKey: dnaConfig.routes.admin.dataElementsStatus.tabs.byType.i18nKey,
    path: dnaConfig.routes.admin.dataElementsStatus.tabs.byType.baseUrl,
    element: <DataElementsStatsByType />
  },
  {
    id: dnaConfig.routes.admin.dataElementsStatus.tabs.structureChanged.label,
    i18nKey: dnaConfig.routes.admin.dataElementsStatus.tabs.structureChanged.i18nKey,
    path: dnaConfig.routes.admin.dataElementsStatus.tabs.structureChanged.baseUrl,
    element: <DataElementsStatsByStructureChanged />
  },
  {
    id: dnaConfig.routes.admin.dataElementsStatus.tabs.recentCompleted.label,
    i18nKey: 'Recently Completed',
    path: dnaConfig.routes.admin.dataElementsStatus.tabs.recentCompleted.baseUrl,
    element: <DataElementsStatsByRecentCompleted />
  }
];

const routeDataElementsMonitoring: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.dataElementsStatus.label,
  path: dnaConfig.routes.admin.dataElementsStatus.baseUrl,
  i18nKey: dnaConfig.routes.admin.dataElementsStatus.i18nKey,
  icon: IconDeviceDesktopAnalytics,
  asA: ROLES.DEVELOPER,
  component: <DataElementsStatsView />,
  idComponent: <DataElementsDetailsHeader />,
  tabs: DEsMonitoringTabs,
  displayIndex: true
});

export default routeDataElementsMonitoring;