import React from 'react';
import ColorPicker from '../utils/color-picker';
import { VennDiagramSegment } from '../../types';

type ActionColorProps = {
  segment: Partial<VennDiagramSegment>,
  modifyColor: (color: string) => void,
  usedColors: string[]
};

function ColorSelector(props: ActionColorProps) {
  const availableColors = [ // TODO: Maybe should be received as a props ?
    'rgb(255, 87, 51)',   // Persimmon
    'rgb(51, 255, 87)',   // Spring Green
    'rgb(51, 87, 255)',   // Royal Blue
    'rgb(255, 51, 161)',  // Hot Pink
    'rgb(255, 215, 0)',   // Gold
    'rgb(75, 0, 130)',    // Indigo
    'rgb(255, 69, 0)',    // Orange Red
    'rgb(46, 139, 87)',   // Sea Green
    'rgb(138, 43, 226)',  // Blue Violet
    'rgb(0, 206, 209)',   // Dark Turquoise
    'rgb(255, 20, 147)',  // Deep Pink
    'rgb(139, 0, 0)',     // Dark Red
    'rgb(0, 0, 128)',     // Navy
    'rgb(50, 205, 50)',   // Lime Green
    'rgb(255, 140, 0)',    // Dark Orange
    'rgb(20, 20, 20)'       // Black
  ];
  const colorPickerColors = availableColors.map(color => ({
    color,
    isDisabled: props.usedColors.includes(color) && color !== props.segment.color,
    usedColorsInclude: props.usedColors.includes(color),
    colorDifferent: color !== props.segment.color,
    usedColors: props.usedColors
  }));

  return (
    <ColorPicker
      colors={colorPickerColors}
      onSelect={(colorSelected: string) => props.modifyColor(colorSelected)}
      selectedColor={props.segment.color || undefined}
    />
  );
}

export default ColorSelector;
