import React, { ReactElement, useEffect, useState } from 'react';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { IconJson, IconRefresh, IconRepeatOnce } from '@tabler/icons-react';
import { DataElementsStatsService, StatsByStatusInterface } from '@/api/services/data.elements.stats.api';
import { Column, Row } from '@/components/tables/types';
import { CurrencyFormatterProps } from '@/utils';
import JsonModal from '../../recordSet/components/jsonModal';
import EvenOddTable from '@/components/tables/evenodd/table';

function DataElementsStatsByStructureChanged(): ReactElement{
  const statsServices = new DataElementsStatsService();
  const [data, setData] = useState<StatsByStatusInterface[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [jsonModalStates, setJsonModalStates] = useState<{open: boolean, data: StatsByStatusInterface | null}>({ open: false, data: null });

  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'type', label: 'Type' },
    { id: 'connectorType', label: 'Connector Type' },
    { id: 'avgPrio', label: 'Avg Prio' },
    { id: 'minPrio', label: 'Min Prio' },
    { id: 'maxPrio', label: 'Max Prio' },
    { id: 'count', label: 'Count' },
    { id: 'actions', label: 'Actions' }
  ];

  const rows: Row<CurrencyFormatterProps>[] = data
    .map(info => ({
      id: info._id,
      type: info._id,
      connectorType: info.connectorType,
      avgPrio: info.avgPrio,
      minPrio: info.minPrio,
      maxPrio: info.maxPrio,
      count: info.count,
      actions:
        <Stack direction='row'>
          <Tooltip arrow title='Restart DataElement'>
            <IconButton onClick={() => {
              // dataElementServices.updateStatus(dataElement._id, 'update_needed');
            }}>
              <IconRepeatOnce color='blue'/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow title='See Json Object'>
            <IconButton onClick={() => {
              setJsonModalStates({ open: true, data: info });
            }}>
              <IconJson color='grey'/>
            </IconButton>
          </Tooltip>
        </Stack>
    }));

  const fetchData = () => {
    setRefresh(false);
    statsServices.getDEsByStructureChanged()
      .then((res) => {
        if (res.data) {
          setData(res.data.stats);
          setTotalCount(res.data.total);
        }
      });
  };

  const refreshData = () => {
    setRefresh(true);
  };

  useEffect(() => {
    if (refresh) {
      fetchData();
    }
  }, [refresh]);

  return (
    <Stack spacing={4}>

      <Stack direction='row' alignItems='center' justifyContent='flex-end'>
        <Box>
          <Tooltip arrow title='Refresh Data' placement='top'>
            <IconButton title='Refresh' onClick={refreshData}>
              <IconRefresh />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>

      <Stack>
        <EvenOddTable<CurrencyFormatterProps>
          label='DataElements'
          columns={columns}
          rows={rows || []}
          totalCount={totalCount}
          hideFooter
        />
        <JsonModal<StatsByStatusInterface>
          jsonModalStates={jsonModalStates}
          setJsonModalStates={setJsonModalStates}
        />
      </Stack>
    </Stack>
  );
}

export default DataElementsStatsByStructureChanged;