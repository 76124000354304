import React, { ReactElement } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { AdminFilters } from '../types';

function CheckboxFilter(props: {filter: AdminFilters, handleFilterChange: (filter: AdminFilters, values: string[] | boolean) => void}): ReactElement {
  return (
    <FormControlLabel
      control={
        <Checkbox
        // checked={!!activeFilters[filter.propertyKey]}
          onChange={e => props.handleFilterChange(props.filter, e.target.checked)}
        />
      }
      label={props.filter.propertyKey}
    />
  );
}

export default CheckboxFilter;