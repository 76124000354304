import React from 'react';
import { IconBuildingFactory } from '@tabler/icons-react';
import { ROLES } from '@deecision/dna-interfaces/dist/constants/roles';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import ComponentFactory from './index';
import routesSegmentationFactory from './segmentation/routes';
import routesVennDiagramFactory from './vennDiagram/routes';

const routesFactory = createElementRoutes({
  id: dnaConfig.routes.admin.factory.label,
  i18nKey: dnaConfig.routes.admin.factory.i18nKey,
  path: dnaConfig.routes.admin.factory.baseUrl,
  icon: IconBuildingFactory,
  component: <ComponentFactory />,
  idComponent: <></>,
  asA: ROLES.ADMIN,
  childrens: [
    routesSegmentationFactory,
    routesVennDiagramFactory
  ]
});

export default routesFactory;
