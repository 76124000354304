import React, { ReactElement, useEffect, useState } from 'react';
import { IconFilter, IconTrash } from '@tabler/icons-react';
import { Box, Grid2 } from '@mui/material';
import { isArray, isBoolean } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { AdminFilters } from './types';
import AutocompleteFilter from './FiltersByType/autocomplete';
import MultiSelectFilter from './FiltersByType/multiselect';
import CheckboxFilter from './FiltersByType/checkbox';

type UpdateDataType<T> = (filteredData: T[]) => void;

interface AdminCustomFiltersProps<T> {
  entityType?: string, // to remove
  referenceData: T[],
  updateData: UpdateDataType<T>,
  filters: AdminFilters[]
}

function FilterComponentFactoryDispatchByType(props: { filter: AdminFilters, activeFilters: AdminFilters[], handleFilterChange: (filter: AdminFilters, values: string[] | boolean) => void}): ReactElement | null {
  if (props.filter.type === 'autocomplete') {
    return <AutocompleteFilter filter={props.filter} activeFilters={props.activeFilters} handleFilterChange={props.handleFilterChange} />;
  }
  if (props.filter.type === 'multiselect') {
    return <MultiSelectFilter filter={props.filter} activeFilters={props.activeFilters} handleFilterChange={props.handleFilterChange}/>;
  }
  if (props.filter.type === 'checkbox') {
    return <CheckboxFilter filter={props.filter} handleFilterChange={props.handleFilterChange}/>;
  }

  return null;
}

function AdminCustomFilters<T>(props: AdminCustomFiltersProps<T>): ReactElement {
  const [activeFilters, setActiveFilters] = useState<AdminFilters[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const resetFilters = () => {
    setActiveFilters([]);
    Array.from(searchParams.keys()).forEach((key) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  };

  const updateFiltersObjIdxWithNewValue = (prevFilters: AdminFilters[], filterToUpdateIndex: number, newValues: string[] | boolean) =>
    prevFilters
      // Update the wanted index with new values
      .map((prevFilter, index) => {
        if (index === filterToUpdateIndex) {
          return {
            ...prevFilter,
            values: Array.isArray(newValues) ? newValues : undefined,
            boolean: typeof newValues === 'boolean' ? newValues : undefined
          };
        }

        return (prevFilter);
      })
      // Remove filter when empty values
      .filter((_, index) =>
        !(index === filterToUpdateIndex && Array.isArray(newValues) && newValues.length === 0)
      );

  const updateUrlWithFilters = (filterToUpdate: AdminFilters, values: string[] | boolean) => {
    const deleteConditionBasedOnType = (isArray(values) && values.length === 0) || isBoolean(values);
    let valuesAsString = '';

    if (isBoolean(values)) {
      valuesAsString = values.toString();
    };
    if (isArray(values)) {
      valuesAsString = values.join(',');
    };

    if (searchParams.get(filterToUpdate.propertyKey) && deleteConditionBasedOnType) {
      searchParams.delete(filterToUpdate.propertyKey);
    } else {
      searchParams.set(filterToUpdate.propertyKey, valuesAsString);
    }
    setSearchParams(searchParams);
  };

  const handleFilterChange = (filter: AdminFilters, values: string[] | boolean) => {
    setActiveFilters((prevState) => {
      const filterToUpdateIndex = prevState.findIndex(prev => prev.propertyKey === filter.propertyKey);

      const newFilter = {
        propertyKey: filter.propertyKey,
        label: filter.label,
        type: filter.type,
        values: Array.isArray(values) ? values : undefined,
        boolean: typeof values === 'boolean' ? values : undefined
      };

      // If Filter already exist, change his index with new value
      if (filterToUpdateIndex !== -1) {
        return updateFiltersObjIdxWithNewValue(prevState, filterToUpdateIndex, values);
      }
  
      // If filter based on propertyKey doesn't exist, add a new one
      return [ ...prevState, newFilter ];
    });
    updateUrlWithFilters(filter, values);
  };

  const getNestedValue = (obj: T, keyPath: string): string | undefined => keyPath.split('.').reduce<unknown>((acc, key) => {
    if (acc && typeof acc === 'object') {
      const record = acc as Record<string, unknown>;
      const arrayMatch = key.match(/^(.+)\[(\d+)]$/);
      if (arrayMatch) {
        const arrayKey = arrayMatch[1];
        const index = parseInt(arrayMatch[2], 10);

        if (Array.isArray(record[arrayKey])) {
          return (record[arrayKey] as unknown[])[index];
        }
        
        return undefined;
      }
  
      return record[key];
    }
    
    return undefined;
  }, obj) as string | undefined;
  
  const computeReferenceDataFiltering = () =>
    props.referenceData.filter(refData =>
      activeFilters.every((filter) => {
        const valueOfKey = getNestedValue(refData, filter.propertyKey);
        
        return valueOfKey !== undefined && filter.values?.includes(valueOfKey);
      })
    );

  const applyUrlFilters = () => {
    const urlFiltersToAdd = searchParams.entries();
    const activeFiltersParsedFromUrl: AdminFilters[] = [];

    if (urlFiltersToAdd) {
      searchParams.forEach((value: string, key: string) => {
        const activeFilterToAdd: AdminFilters = {
          propertyKey: key,
          label: props.filters.find(filter => filter.propertyKey === key)?.label || '',
          type: props.filters.find(filter => filter.propertyKey === key)?.type || 'multiselect',
          values: value.split(',')
        };
        activeFiltersParsedFromUrl.push(activeFilterToAdd);
      });
      setActiveFilters(activeFiltersParsedFromUrl);
    }
  };

  useEffect(() => {
    applyUrlFilters();
  }, [searchParams]);

  useEffect(() => {
    const refDataFilteredByActiveFilters = computeReferenceDataFiltering();

    if (activeFilters.length === 0) {
      props.updateData(props.referenceData);
    } else {
      props.updateData(refDataFilteredByActiveFilters);
    }
  }, [activeFilters, props.referenceData]);

  return (
    <Grid2 container spacing={2} alignItems='center'>
      <Box>
        <IconFilter />
      </Box>
      <Grid2 container spacing={2}>
        {props.filters.map(filter => (
          <Grid2 key={filter.propertyKey} style={{ display: 'flex', alignItems: 'center' }}>
            <FilterComponentFactoryDispatchByType
              filter={filter}
              activeFilters={activeFilters}
              handleFilterChange={handleFilterChange}
            />
          </Grid2>
        ))}
      </Grid2>
      <Box onClick={resetFilters} sx={{ cursor: 'pointer' }}>
        <IconTrash />
      </Box>
    </Grid2>
  );
}

export default AdminCustomFilters;