import React, { ReactElement, useEffect, useState } from 'react';
import { Autocomplete, Box, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconJson, IconLabelImportant, IconRefresh, IconRepeatOnce } from '@tabler/icons-react';
import { DataElementsStatsService, StatsByStatusInterface } from '@/api/services/data.elements.stats.api';
import { Column, Row } from '@/components/tables/types';
import { CurrencyFormatterProps } from '@/utils';
import JsonModal from '../../recordSet/components/jsonModal';
import EvenOddTable from '@/components/tables/evenodd/table';
import { DataElementsQueryService } from '@/api/services/query';
import PriorityModal from '../../recordSet/components/priorityModal';

function DataElementsStatsByType(): ReactElement{
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dataElementsServices = new DataElementsQueryService({});
  const statsServices = new DataElementsStatsService();
  const [data, setData] = useState<StatsByStatusInterface[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [autocompleteValue, setAutocompleteValue] = useState<string>();
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(true);
  const [priorityModalStates, setPriorityModalStates] = useState<{open: boolean, data: StatsByStatusInterface | null}>({ open: false, data: null });
  const [jsonModalStates, setJsonModalStates] = useState<{open: boolean, data: StatsByStatusInterface | null}>({ open: false, data: null });

  const possibleStatus = [
    'done',
    'failed',
    'requested',
    'running',
    'startable',
    'update_needed',
    'waiting_for_dependencies'
  ];

  const filterDataByType = (deStat: StatsByStatusInterface) => {
    if (autocompleteValue) {
      return deStat._id.includes(autocompleteValue);
    }

    return true;
  };

  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'type', label: 'Type' },
    { id: 'connectorType', label: 'Connector Type' },
    { id: 'avgPrio', label: 'Avg Prio' },
    { id: 'minPrio', label: 'Min Prio' },
    { id: 'maxPrio', label: 'Max Prio' },
    { id: 'count', label: 'Count' },
    { id: 'actions', label: 'Actions' }
  ];

  const rows: Row<CurrencyFormatterProps>[] = data
    .filter(filterDataByType)
    .map(info => ({
      id: info._id,
      type: info._id,
      connectorType: info.connectorType,
      avgPrio: info.avgPrio,
      minPrio: info.minPrio,
      maxPrio: info.maxPrio,
      count: info.count,
      actions:
        <Stack direction='row'>
          <Tooltip arrow title='Update Priority tmpBoost' placement='top'>
            <IconButton onClick={() => {
              setPriorityModalStates({ open: true, data: info });
            }}>
              <IconLabelImportant color='orange'/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow title='Refresh DataElements' placement='top'>
            <IconButton onClick={() => {
              dataElementsServices.relaunchDataElements([info._id], selectedStatus.length > 0 ? [selectedStatus] : undefined)
                .then(() => {
                  setRefresh(true);
                });
            }}>
              <IconRepeatOnce color='#007BFF'/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow title='See Json Object' placement='top'>
            <IconButton onClick={() => {
              setJsonModalStates({ open: true, data: info });
            }}>
              <IconJson color='grey'/>
            </IconButton>
          </Tooltip>
        </Stack>
    }));

  const fetchData = (status: string) => {
    const formattedStatus: string[] = status && status.length > 0 ? [status] : [];

    setRefresh(false);
    statsServices.getDEsByStatusType(formattedStatus)
      .then((res) => {
        if (res.data) {
          setData(res.data.stats);
          setTotalCount(res.data.total);
        }
      });
  };

  const refreshData = () => {
    setRefresh(true);
  };

  const onSelectChange = (value: string) => {
    if (value !== selectedStatus) {
      searchParams.set('status', value);
      navigate(`?${searchParams.toString()}`, { replace: true });
      setSelectedStatus(value);
    } else {
      searchParams.delete('status');
      navigate(`?${searchParams.toString()}`, { replace: true });
      setSelectedStatus('');
    }
    setRefresh(true);
  };

  const setStatusFromParams = () => {
    const status = searchParams.get('status');
    if (status) {
      setSelectedStatus(status);
    }
    
    return status;
  };

  useEffect(() => {
    const status = setStatusFromParams();
    if (refresh) {
      fetchData(status || '');
    }
  }, [refresh, selectedStatus]);

  return (
    <Stack spacing={4}>

      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={4}>
          <Box width='15vw'>
            <FormControl variant='outlined' size='small' style={{ minWidth: `15vw`, width: 'auto' }}>
              <Autocomplete
                fullWidth
                value={autocompleteValue}
                options={data?.map(deStat => deStat._id) || ['']}
                renderInput={params =>
                  <TextField
                    {...params}
                    label='Select Type'
                    value={autocompleteValue}
                    onChange={(event) => {
                      setAutocompleteValue(event.target.value);
                    }}
                  />
                }
                onChange={(_, value) => {
                  setAutocompleteValue(value || '');
                }}
              />
            </FormControl>
          </Box>
          <Box width='10vw'>
            <FormControl variant='outlined' size='small' style={{ minWidth: `10vw`, width: 'auto' }}>
              <InputLabel>Status</InputLabel>
              <Select
                fullWidth
                label='status'
                name='Status'
                value={selectedStatus}
              >
                {possibleStatus.map(value => (
                  <MenuItem key={value} value={value} onClick={() => {
                    onSelectChange(value);
                  }}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Box>
          <Tooltip arrow title='Refresh Data' placement='top'>
            <IconButton title='Refresh' onClick={refreshData}>
              <IconRefresh />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>

      <Stack>
        <EvenOddTable<CurrencyFormatterProps>
          label='DataElements'
          columns={columns}
          rows={rows || []}
          totalCount={totalCount}
          hideFooter
        />
        <PriorityModal
          priorityModalStates={priorityModalStates}
          setPriorityModalStates={setPriorityModalStates}
          refresh={setRefresh}
        />
        <JsonModal<StatsByStatusInterface>
          jsonModalStates={jsonModalStates}
          setJsonModalStates={setJsonModalStates}
        />
      </Stack>
    </Stack>
  );
}

export default DataElementsStatsByType;