import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { IconChevronDown } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import AccordionDetails from '@mui/material/AccordionDetails';
import { BodaccDataBlock } from '@deecision/dna-interfaces';
import { BodaccElementsProps, Criticity } from './types';
import { BodaccYearEvents } from './events';
import { getDateBodacc, getIdBodacc, getYearsBodacc } from './utils';

function BodaccAccordions(props: Omit<BodaccElementsProps, 'select' | 'criticity' | 'years'> & { sort?: 'asc' | 'dsc' }): ReactElement {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const theme = useTheme();
  const criticity: Criticity = {
    neutral: theme.palette.primary,
    opportunity: theme.palette.success,
    alert: theme.palette.error,
    ambiguous: theme.palette.warning
  };
  const nbOfCriticity = [
    {
      label: 'neutral',
      nb: (year: string) => props.datas.filter(data => (getDateBodacc(data) && year === getDateBodacc(data)?.getFullYear().toString())).filter(data => (data.dataInfo.path === 'company.bodacc.publication' ? (data.data as BodaccDataBlock)._deecision?.criticity === 'neutral' : false)).length,
      color: 'primary' as const
    },
    {
      label: 'opportunity',
      nb: (year: string) => props.datas.filter(data => (getDateBodacc(data) && year === getDateBodacc(data)?.getFullYear().toString())).filter(data => (data.dataInfo.path === 'company.bodacc.publication' ? (data.data as BodaccDataBlock)._deecision?.criticity === 'opportunity' : false)).length,
      color: 'success' as const
    },
    {
      label: 'alert',
      nb: (year: string) => props.datas.filter(data => (getDateBodacc(data) && year === getDateBodacc(data)?.getFullYear().toString())).filter(data => (data.dataInfo.path === 'company.bodacc.publication' ? (data.data as BodaccDataBlock)._deecision?.criticity === 'alert' : true)).length,
      color: 'error' as const
    },
    {
      label: 'ambiguous',
      nb: (year: string) => props.datas.filter(data => (getDateBodacc(data) && year === getDateBodacc(data)?.getFullYear().toString())).filter(data => (data.dataInfo.path === 'company.bodacc.publication' ? (data.data as BodaccDataBlock)._deecision?.criticity === 'ambiguous' : false)).length,
      color: 'warning' as const
    }
  ];

  const handleChange = (year: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(prevState => (isExpanded ? [...prevState, year ] : prevState.filter(p => p !== year)));
  };

  useEffect(() => {
    if (props.selectedId !== '') {
      const dataTmp = props.datas.find(data => getIdBodacc(data) === props.selectedId);
      const dataTimestamp = dataTmp ? getDateBodacc(dataTmp) : undefined;
      const year = dataTimestamp && dataTimestamp?.getFullYear().toString();

      if (year) {
        setExpanded(prevState => [...prevState.filter(f => f !== year), year]);
      }
    }
  }, [props.selectedId]);

  useEffect(() => {
    setYears(getYearsBodacc(props.datas, props.sort));
  }, [props.datas, props.sort]);

  return (
    <Stack sx={{ width: '100%' }}>
      {years.map((year, index) => (
        props.datas.filter(data => (getDateBodacc(data) && year === getDateBodacc(data)?.getFullYear().toString())).length > 0 &&
        <Accordion
          key={`accordion-${year}`}
          sx={{
            width: '100%',
            borderRadius: expanded.includes(year) ? 1 : undefined,
            borderTopRightRadius: expanded.includes(years[index - 1]) ? '12px' : undefined,
            borderTopLeftRadius: expanded.includes(years[index - 1]) ? '12px' : undefined,
            borderBottomRightRadius: expanded.includes(years[index + 1]) ? '12px' : undefined,
            borderBottomLeftRadius: expanded.includes(years[index + 1]) ? '12px' : undefined,
            '&:before': {
              display: expanded.includes(years[index - 1]) ? 'none' : undefined
            }
          }}
          expanded={expanded.includes(year)}
          onChange={handleChange(year)}
        >
          <AccordionSummary
            expandIcon={<IconChevronDown size='1.2rem' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Stack direction='row' spacing={1}>
              <Typography pr={4} variant='h4' width='70px' textAlign='center' pl={4}>{year}</Typography>
              <Typography>
                {props.datas.filter(data => (getDateBodacc(data) && year === getDateBodacc(data)?.getFullYear().toString())).length}
              </Typography>
              <Typography pr={2}>
                {props.datas.filter(data => (getDateBodacc(data) && year === getDateBodacc(data)?.getFullYear().toString())).length > 1 ? t('common.utils.events') : t('common.utils.event')}
              </Typography>
              {nbOfCriticity.map(nb => (nb.nb(year) > 0 &&
                <Tooltip key={nb.label} title={nb.label} placement='top' arrow>
                  <Chip
                    label={nb.nb(year)}
                    color={nb.color}
                    size='small'
                    sx={{
                      ml: '8px !important',
                      '& .MuiChip-labelSmall': {
                        pl: 2,
                        pr: 2
                      }
                    }}
                  />
                </Tooltip>
              ))}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <BodaccYearEvents
              datas={props.datas.filter(data => (getDateBodacc(data) && year === getDateBodacc(data)?.getFullYear().toString()))}
              criticity={criticity}
              selectedId={props.selectedId}
              yearOpen={expanded.includes(year)}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
}

export default BodaccAccordions;
