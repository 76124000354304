import React, { ReactElement, useState } from 'react';
import { FilterClassification, SegmentationPossibleFilter } from '@deecision/dna-interfaces';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { IconCheck, IconMinus, IconPlus } from '@tabler/icons-react';
import Modal from '@mui/material/Modal';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { uniq, upperFirst } from 'lodash';
import Box from '@mui/material/Box';
import { lighten, useTheme } from '@mui/material';
import { filterClassificationColorMapping } from '../../components/classification.chips';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

interface AddCriteriasBuilderSegmentationProps {
  possibleCriterias: SegmentationPossibleFilter[],
  addSegmentationCriterias: (possibleCriteria: SegmentationPossibleFilter[]) => void,
  addSegmentationCriteriaCategory: (category?: string, classification?: FilterClassification) => void,
  fullWidth?: boolean,
  contained?: true
}

export function PossibleCriteriasTreeView(props: AddCriteriasBuilderSegmentationProps & { handleClose: () => void, disableAddAll?: true }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(!props.disableAddAll);
  const [segmentationCriteriaToAdd, setSegmentationCriteriaToAdd] = useState<SegmentationPossibleFilter[]>([]);

  const sortClassificationBySpecificOrder = (a: FilterClassification | undefined, b: FilterClassification | undefined) => {
    const classificationOrder = ['genericCriteria', 'businessCriteria', 'decisionCriteria']; // This order should be changed according to FilterClassification content
    const aClassificationIndex = classificationOrder.indexOf(a || '');
    const bClassificationIndex = classificationOrder.indexOf(b || '');

    return aClassificationIndex - bClassificationIndex;
  };

  return (
    <Stack spacing={2} p={2} maxHeight='80vh'>
      <Stack direction='row' spacing={2} alignItems='center'>
        {!props.disableAddAll &&
          <Typography variant='h3'>{t('segmentation.builder.segmentationCriterias.add')}</Typography>
        }
        <Button onClick={() => setCollapsed(prevState => !prevState)} endIcon={collapsed ? <IconPlus size='1rem' /> : <IconMinus size='1rem' />} size='small'>
          {collapsed ? t('common.utils.expandAll') : t('common.utils.collapseAll')}
        </Button>
      </Stack>
      <Box overflow='auto'>
        <SimpleTreeView
          key={collapsed ? 'collapsed' : 'expanded'}
          sx={{ overflow: 'auto' }}
          defaultExpandedItems={
            !collapsed
              ? uniq(
                props.possibleCriterias
                  .map(possibleCriteria => possibleCriteria.classifications)
                  .flat(1)
                  .filter((classification): classification is FilterClassification => classification !== undefined)
              )
              : []
          }
        >
          {/* Display By Unique Classification */}
          {uniq(props.possibleCriterias.map(possibleCriteria => possibleCriteria.classifications).flat(1))
            .sort(sortClassificationBySpecificOrder)
            .map((classification) => {
              const numberOfCategoriesWithinClassification = uniq(props.possibleCriterias.filter(possibleCriteria => classification && possibleCriteria.classifications?.includes(classification)).flatMap(possibleCriteria => possibleCriteria.categories)).length;
              const numberOfCriteriasWithinClassification = uniq(props.possibleCriterias.filter(possibleCriteria => classification && possibleCriteria.classifications?.includes(classification))).length;

              return (
                <TreeItem
                  itemId={classification || ''}
                  key={classification}
                  label={
                    <Stack
                      direction='row'
                      spacing={4}
                      alignItems='center'
                    >
                      <Typography variant='h3' color={ classification ? filterClassificationColorMapping[classification] : undefined} bgcolor={lighten(filterClassificationColorMapping[classification || ''] || '#ffffff', 0.7 ) } p={1} px={4} borderRadius={4}>
                        {`${t(`segmentation.render.classifications.${classification}`)}`}
                      </Typography>
                      <Chip color='info' size='small' label={`${numberOfCategoriesWithinClassification} ${numberOfCategoriesWithinClassification <= 1 ? t('common.utils.category') : t('common.utils.categories')}`} />
                      <Chip color='info' size='small' label={`${numberOfCriteriasWithinClassification} ${numberOfCriteriasWithinClassification <= 1 ? t('segmentation.criteria.label') : t('segmentation.criteria.multiple')}`} />
                      {!props.disableAddAll &&
                      <Button
                        variant='outlined'
                        startIcon={<IconPlus size='1rem' />}
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                          props.addSegmentationCriteriaCategory(undefined, classification);
                          props.handleClose();
                        }}
                      >
                        {t('segmentation.builder.segmentationCriterias.selection.addAllCat')}
                      </Button>
                      }
                    </Stack>
                  }
                >
                  {/* Display By Unique Categories within the unique classification */}
                  {uniq(props.possibleCriterias
                    .filter(possibleCriteria => classification && possibleCriteria.classifications?.includes(classification))
                    .map(possibleCriteria => possibleCriteria.categories).flat(1)).map(category => (
                    <TreeItem
                      itemId={classification + category}
                      key={classification + category}
                      label={
                        <Stack direction='row' spacing={4} alignItems='center'>
                          <Typography variant='h4'>{`${upperFirst(category.split(/(?=[A-Z])/).join(' '))} (${props.possibleCriterias.filter(possibleCriteria => classification && possibleCriteria.classifications?.includes(classification)).filter(possibleCriteria => possibleCriteria.categories.includes(category)).length})`}</Typography>
                          {!props.disableAddAll &&
                            <Button
                              variant='outlined'
                              startIcon={<IconPlus size='1rem' />}
                              size='small'
                              onClick={(e) => {
                                e.stopPropagation();
                                props.addSegmentationCriteriaCategory(category, classification);
                                props.handleClose();
                              }}
                            >
                              {t('segmentation.builder.segmentationCriterias.selection.addAllCat')}
                            </Button>
                          }
                        </Stack>
                      }
                    >
                      {/* Display Category within the categories */}
                      {props.possibleCriterias
                        .filter(possibleCriteria => classification && possibleCriteria.classifications?.includes(classification))
                        .filter(possibleCriteria => possibleCriteria.categories.includes(category))
                        .map(possibleCriteria => (
                          <TreeItem
                            itemId={`${classification}-${category}-${possibleCriteria.id}`}
                            key={`${classification}-${category}-${possibleCriteria.id}`}
                            onClick={() => {
                              setSegmentationCriteriaToAdd((prevState) => {
                                if (prevState.find(c => c.id === possibleCriteria.id)) {
                                  return prevState.filter(c => c.id !== possibleCriteria.id);
                                }

                                if (props.disableAddAll) {
                                  return [possibleCriteria];
                                }

                                return [...prevState, possibleCriteria];
                              });
                            }}
                            sx={segmentationCriteriaToAdd.find(c => c.id === possibleCriteria.id) ? {
                              bgcolor: theme.palette.success.light,
                              borderRadius: 1
                            } : {
                              borderRadius: 1
                            }}
                            label={
                              <Stack
                                direction='row'
                                spacing={2}
                                width='100%'
                                alignItems='center'
                                pr={2}
                              >
                                <Typography variant='body1'>{possibleCriteria.label}</Typography>
                                <Chip
                                  label={t(`entities.${possibleCriteria.on}.multiple`)}
                                  sx={{
                                    bgcolor: possibleCriteria.on === 'person' ? 'primary.light' : possibleCriteria.on === 'company' ? 'secondary.light' : 'warning.light',
                                    color: possibleCriteria.on === 'person' ? 'primary.dark' : possibleCriteria.on === 'company' ? 'secondary.dark' : 'warning.dark'
                                  }}
                                  size='small'
                                />
                                {!props.disableAddAll && (
                                  segmentationCriteriaToAdd.find(c => c.id === possibleCriteria.id) ?
                                    <IconCheck size='1rem' style={{ marginLeft: 'auto' }} /> :
                                    <IconPlus size='1rem' style={{ marginLeft: 'auto' }} />)
                                }
                              </Stack>
                            }
                          />
                        ))
                      }
                    </TreeItem>
                  ))}
                </TreeItem>
              );
            })}
        </SimpleTreeView>
      </Box>
      {!props.disableAddAll &&
        <Button
          startIcon={<IconPlus size='1.2rem' />}
          onClick={() => {
            props.handleClose();
            props.addSegmentationCriteriaCategory();
          }}
        >
          {`${t('segmentation.builder.segmentationCriterias.selection.addAll')} (${props.possibleCriterias.length})`}
        </Button>
      }
      <Button
        variant='contained'
        onClick={() => {
          props.addSegmentationCriterias(segmentationCriteriaToAdd);
          setSegmentationCriteriaToAdd([]);
          props.handleClose();
        }}
        disabled={segmentationCriteriaToAdd.length === 0}
      >
        {props.disableAddAll
          ? t('segmentation.builder.segmentationCriterias.selection.replaceSelected')
          : `${t('segmentation.builder.segmentationCriterias.selection.addSelected')} ${segmentationCriteriaToAdd.length} ${t('segmentation.builder.segmentationCriterias.selection.addSelected2')}`
        }
      </Button>
    </Stack>
  );
}

function AddCriteriasBuilderSegmentation(props: AddCriteriasBuilderSegmentationProps): ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <Box width={props.fullWidth ? '100%' : 'fit-content'}>
      <Button
        variant='contained'
        startIcon={<IconPlus size='1.2rem' />}
        onClick={handleOpen}
        disabled={props.possibleCriterias.length < 1}
        fullWidth={props.fullWidth}
      >
        {t('segmentation.builder.segmentationCriterias.add')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='criterias-selection-modal-title'
        aria-describedby='criterias-selection-modal-description'
      >
        <Paper sx={style}>
          <PossibleCriteriasTreeView {...props} handleClose={handleClose} />
        </Paper>
      </Modal>
    </Box>
  );
}

export default AddCriteriasBuilderSegmentation;
