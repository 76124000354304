import React, { ReactElement, useState } from 'react';
import { Button, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { IconChevronDown, IconTableImport, IconArrowRight, IconSettings } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

function NewRecordSetButton(props: { handleModalOpening: (modalType: 'fileImport' | 'manualCreation') => void }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id='augment-button'
        variant='contained'
        aria-controls={open ? 'augment-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        endIcon={
          <IconChevronDown
            style={{
              transform: open ? 'rotate(180deg)' : undefined,
              transition: '0.2s ease-in-out'
            }}
            size='1.6rem'
          />
        }
        onClick={handleClick}
      >
        {`${t('common.utils.new')} Record-Set`}
      </Button>
      <Menu
        id='augment-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'augment-button'
        }}
      >
        <MenuItem
          onClick={() => {
            props.handleModalOpening('fileImport');
          }}
        >
          <Stack direction='row' spacing={3} alignItems='center' p={2} width='100%'>
            <IconTableImport size='1.2rem' color={theme.palette.primary.main} />
            <Typography variant='h4' color='primary' pr={2}>
              {`${t('admin.recordSet.creation.fileImport')}`}
            </Typography>
            <IconArrowRight size='1.2rem' style={{ marginLeft: 'auto' }} color={theme.palette.primary.main} />
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.handleModalOpening('manualCreation');
          }}
        >
          <Stack direction='row' spacing={3} alignItems='center' p={2} width='100%'>
            <IconSettings size='1.2rem' color={theme.palette.secondary.main} />
            <Typography variant='h4' color='secondary' pr={2}>
              {`${t('admin.recordSet.creation.manualCreation')} (TODO)`}
            </Typography>
            <IconArrowRight size='1.2rem' style={{ marginLeft: 'auto' }} color={theme.palette.secondary.main} />
          </Stack>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default NewRecordSetButton;