import React, { ReactElement, useEffect, useState } from 'react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import { useLocation, useParams } from 'react-router-dom';
import { JsonEditor } from 'json-edit-react';
import Grid from '@mui/material/Grid2';
import BaseDnaEntitiesService from '../../../../entities/services/entities';
import BaseDnaRncsEntitiesService from '../../../../entities/services/rncs';

function EntitiesSelfObjTabs(): ReactElement {
  const { entityType } = useParams();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const id = pathSegments[4];

  const entityService = (entityType?.includes('deec') ?
    new BaseDnaEntitiesService<CompanyEntity>({ entityType })
    : new BaseDnaRncsEntitiesService<CompanyEntity>({ entityType }));
  const [entityData, setEntityData] = useState<CompanyEntity | PersonEntity | null>(null);

  const fetchData = (entityId: string) => {
    if (!id) {
      return;
    }
    entityService.get(entityId)
      .then((res) => {
        if (res.data) {
          setEntityData(res.data);
        }
      });
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return (
    <Grid container spacing={2} alignItems='center'>
      {entityData ?
        <JsonEditor
          data={entityData}
          viewOnly
          rootName='EntityData'
        />
        :
        null
      }
    </Grid>
  );
}

export default EntitiesSelfObjTabs;
