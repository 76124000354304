import React from 'react';
import { useTheme } from '@mui/material';
import Slider from '@mui/material/Slider';
import { mainRadius } from '@/theme/theme';

const extractRGB = (rgbString: string): { r: number, g: number, b: number } => {
  const match = rgbString.match(/\d+/g);
  if (!match) throw new Error('Invalid RGB format');
  const [r, g, b] = match.map(Number);

  return { r, g, b };
};

const interpolateColor = (color1: string, color2: string, factor: number): string => {
  const c1 = extractRGB(color1);
  const c2 = extractRGB(color2);

  const r = Math.round((c2.r - c1.r) * factor + c1.r);
  const g = Math.round((c2.g - c1.g) * factor + c1.g);
  const b = Math.round((c2.b - c1.b) * factor + c1.b);

  return `rgb(${r}, ${g}, ${b})`;
};

const getColorFromValue = (value: number, colors: string[]): string => {
  const clampedValue = Math.max(0, Math.min(3, value));
  const index = Math.floor(clampedValue);
  const factor = clampedValue - index;

  if (index >= colors.length - 1) return colors[colors.length - 1];

  return interpolateColor(colors[index], colors[index + 1], factor);
};

function GradientSlider(props: { value: number, indexStart?: number }) {
  const { value } = props;
  const theme = useTheme();
  const indexIncrementation = props.indexStart || 0;

  const thumbStyle = {
    height: '1.6rem',
    width: '3.2rem',
    backgroundColor: getColorFromValue(value, [theme.palette.success.main, theme.palette.warning.main, theme.palette.error.main, theme.palette.grey[800]]),
    boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
    borderRadius: mainRadius,
    transform: 'translate(-50%, -50%)'
  };

  return (
    <Slider
      value={value + indexIncrementation}
      min={0 + indexIncrementation}
      max={3 + indexIncrementation}
      step={0.01}
      disabled
      sx={{
        '& .MuiSlider-thumb': {
          ...thumbStyle,
          '&::after': {
            content: `"${(value + indexIncrementation).toFixed(2)}"`,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }
        },
        '& .MuiSlider-rail': {
          opacity: 1,
          background: `linear-gradient(to right, ${theme.palette.success.main}, ${theme.palette.warning.main}, ${theme.palette.error.main}, ${theme.palette.grey['800']})`,
          height: '1rem',
          borderRadius: 4
        },
        '& .MuiSlider-track': {
          opacity: 0,
          border: 'none'
        },
        '&.Mui-disabled': {
          color: 'inherit',
          opacity: 1,
          '& .MuiSlider-thumb': {
            ...thumbStyle,
            opacity: 1
          },
          '& .MuiSlider-rail': {
            opacity: 1,
            background: `linear-gradient(to right, ${theme.palette.success.main}, ${theme.palette.warning.main}, ${theme.palette.error.main}, ${theme.palette.grey['800']})`
          }
        }
      }}
    />
  );
}

export default GradientSlider;
