import React, { ReactElement, useEffect, useState } from 'react';
import { BatchInterface } from '@deecision/dna-interfaces';
import { IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { IconArrowRight, IconBuildingFactory2 } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurrencyFormatterProps } from '@/utils';
import { Column, Row } from '@/components/tables/types';
import EvenOddTable from '@/components/tables/evenodd/table';
import { BatchesService } from '@/api/services/batches';

function BatchesListing(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const batchesServices = new BatchesService();
  const [batchesData, setBatchesData] = useState<BatchInterface[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'batchId', label: 'Batch Id' },
    { id: 'recordSetId', label: 'RecordSet Id' },
    { id: 'topStepId', label: 'TopStep Id' },
    { id: 'recordSetCode', label: 'RecordSet Code' },
    { id: 'status', label: 'Status' },
    { id: 'priority', label: 'Priority' },
    { id: 'startTime', label: 'Start Time' },
    { id: 'endTime', label: 'End Time' },
    { id: 'actions', label: 'Actions' }
  ];

  const rows: Row<CurrencyFormatterProps>[] = batchesData.map(batch => ({
    id: batch._id,
    batchId: <Link href={`${batch._id}`}>{batch._id}</Link>,
    recordSetId: <Link href={`/admin/recordSets/${batch.recordSetId}`}>{batch.recordSetId}</Link>,
    topStepId: <Link href={`/admin/steps/${batch.topStepId}`}>{batch.topStepId}</Link>,
    recordSetCode: batch.recordSetCode,
    priority: batch.batchSettings.priority,
    status: batch.status,
    startTime: batch.startTime,
    endTime: batch.endTime,
    actions:
      <Stack>
        <Tooltip arrow title='Go to Batch Page' placement='top'>
          <IconButton onClick={() => {
            navigate(`/admin/steps/${batch._id}`);
          }}>
            <IconArrowRight color='blue'/>
          </IconButton>
        </Tooltip>
      </Stack>
  }));

  const getBatchesData = () => {
    batchesServices.getAll()
      .then((res) => {
        if (res.data) {
          setBatchesData(res.data);
          setTotalCount(res.totalCount);
        }
      });
  };

  useEffect(() => {
    getBatchesData();
  }, []);

  return (
    <Stack>
      <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={2}>
          <IconBuildingFactory2 />
          <Typography variant='h1'>{t('batches.list')}</Typography>
        </Stack>
      </Stack>

      <EvenOddTable<CurrencyFormatterProps> label='entities' columns={columns} rows={rows || []} totalCount={totalCount}/>
    </Stack>
  );
}

export default BatchesListing;