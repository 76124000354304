import React, { Dispatch, ReactElement, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import {
  IconBuildings,
  IconChartCircles,
  IconDeviceFloppy,
  IconDotsVertical,
  IconEdit,
  IconEditOff,
  IconPencil,
  IconRefresh,
  IconRotate,
  IconTimelineEventPlus,
  IconTrash,
  IconUsers
} from '@tabler/icons-react';
import { Chip, darken, lighten, Stack, Typography } from '@mui/material';
import { ChipOwnProps } from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { VennDiagramContext } from '@/main/containers/vennDiagram/wrapper';
import { VennDiagramResult } from '@/main/containers/vennDiagram/types';
import TitleComponent from '@/components/title';
import { getEntityTypeColor, getEntityTypeI18nKey } from '@/main/providers/getter';
import DeleteModalsRenderSegmentations
  from '@/main/containers/segmentations/render/modals/delete';

interface VennDiagramTitleProps {
  isDraft: boolean,
  setIsDraft: Dispatch<SetStateAction<boolean>>,
  isEditMode: boolean,
  setIsEditMode: (isEditMode: boolean) => void,
  setDiagram: Dispatch<SetStateAction<VennDiagramResult[] | undefined>>
}

function VennDiagramTitle(props: VennDiagramTitleProps): ReactElement {
  const diagramContext = useContext(VennDiagramContext);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };
  const handleCloseOpenConfirmDelete = () => {
    setOpenConfirmDelete(false);
    handleClose();
  };
  const handleDeleteSegmentation = () => {
    diagramContext?.deleteVennDiagram();
  };

  return (
    <Grid size={12} container spacing={2} alignItems='center' sx={{ position: 'sticky', top: 0, bgcolor: theme.palette.grey['50'], zIndex: 3, pt: 2, pb: 2 }}>
      <Grid>
        <TitleComponent title={diagramContext?.vennDiagram?.label || ''} icon={IconChartCircles} />
      </Grid>
      {props.isEditMode &&
        <Grid>
          <IconPencil size='1.4rem' />
        </Grid>
      }
      <Grid>
        <Chip
          label={
            <Stack direction='row' spacing={1} alignItems='center'>
              {diagramContext?.vennDiagram?.objectType === 'deecPerson' ? <IconUsers size='1rem' /> : <IconBuildings size='1rem' />}
              <Typography variant='body2'>
                {t(getEntityTypeI18nKey(diagramContext?.vennDiagram?.objectType))}
              </Typography>
            </Stack>
          }
          size='small'
          color={getEntityTypeColor(diagramContext?.vennDiagram?.objectType) !== 'secondary' ? getEntityTypeColor(diagramContext?.vennDiagram?.objectType) as ChipOwnProps['color'] : undefined}
          sx={{
            bgcolor: getEntityTypeColor(diagramContext?.vennDiagram?.objectType) === 'secondary' ? theme.palette.secondary.light : undefined,
            color: getEntityTypeColor(diagramContext?.vennDiagram?.objectType) === 'secondary' ? theme.palette.secondary.dark : undefined
          }}
        />
      </Grid>
      <Grid>
        <Chip
          label={
            <Stack direction='row' spacing={1} alignItems='center'>
              <IconChartCircles size='1rem' />
              <Typography variant='body2'>
                {`${diagramContext?.vennDiagram?.segments.length || 0} ${t('segmentation.vennDiagram.render.segments.label')}`}
              </Typography>
            </Stack>
          }
          color='primary'
          size='small'
        />
      </Grid>
      <Grid>
        <Tooltip title={t('common.dates.creationDate')} arrow placement='top'>
          <Chip
            label={
              <Stack direction='row' spacing={1} alignItems='center'>
                <IconTimelineEventPlus size='1rem' />
                <Typography variant='body2'>
                  {diagramContext?.vennDiagram?.date ? `${new Date(diagramContext?.vennDiagram.date || '').toLocaleDateString()} ${new Date(diagramContext?.vennDiagram.date || '').toLocaleTimeString()}` : ''}
                </Typography>
              </Stack>
            }
            color='success'
            size='small'
          />
        </Tooltip>
      </Grid>
      <Grid>
        <Tooltip title={t('common.dates.lastUpdate')} arrow placement='top'>
          <Chip
            label={
              <Stack direction='row' spacing={1} alignItems='center'>
                <IconRefresh size='1rem' />
                <Typography variant='body2'>
                  {diagramContext?.vennDiagram?.lastUpdate ? `${new Date(diagramContext?.vennDiagram.lastUpdate).toLocaleDateString()} ${new Date(diagramContext?.vennDiagram.lastUpdate).toLocaleTimeString()}` : ''}
                </Typography>
              </Stack>
            }
            sx={{ bgcolor: theme.palette.secondary.light, color: theme.palette.secondary.dark }}
            size='small'
          />
        </Tooltip>
      </Grid>
      {diagramContext?.vennDiagram?.segments.map(segment => (
        <Grid>
          <Chip
            key={segment.id}
            label={<Typography color={darken(segment.color, 0.5)}>{segment.label}</Typography>}
            style={{ backgroundColor: segment.color ? lighten(segment.color, 0.5) : undefined }}
            size='small'
          />
        </Grid>
      ))}
      {props.isDraft &&
        <Grid ml='auto'>
          <Tooltip title={t('common.utils.reset')} placement='top' arrow>
            <IconButton onClick={() => navigate(0)}>
              <IconRotate size='1.2rem' style={{ transform: 'rotate(180deg)' }} />
            </IconButton>
          </Tooltip>
        </Grid>
      }

      {(diagramContext?.vennDiagram?.ownerType === 'me' || props.isDraft) &&
        <Grid ml={props.isDraft ? undefined : 'auto'}>
          <Button
            variant='contained'
            startIcon={<IconDeviceFloppy size='1.6rem' />}
            sx={{ minWidth: 'max-content' }}
            onClick={() => {
              diagramContext?.save('me', (location.pathname.includes('create') || !(diagramContext?.vennDiagram?.ownerType === 'me')) ? true : undefined);
              props.setDiagram(undefined);
              props.setIsDraft(false);
              props.setIsEditMode(false);
            }}
            disabled={!props.isDraft}
          >
            {diagramContext?.vennDiagram?.ownerType === 'me' ? t('segmentation.vennDiagram.render.settings.save.label') : t('segmentation.vennDiagram.render.settings.save.new')}
          </Button>
        </Grid>
      }
      {diagramContext?.vennDiagram?.ownerType === 'me' &&
        <Grid>
          <IconButton
            id='segmentation-settings-button'
            onClick={handleClick}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            aria-controls={open ? 'segmentation-settings' : undefined}
          >
            <IconDotsVertical size='1.6rem' />
          </IconButton>
          <Menu
            id='segmentation-settings'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'segmentation-settings-button'
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                props.setIsEditMode(!props.isEditMode);
              }}
            >
              <Stack direction='row' spacing={2} alignItems='center' p={2} pr={4}>
                {props.isEditMode ? <IconEditOff size='1.6rem' /> : <IconEdit size='1.6rem' />}
                <Typography variant='h5'>{t('segmentation.vennDiagram.render.settings.edit')}</Typography>
              </Stack>
            </MenuItem>
            {!location.pathname.includes('/create') &&
              <MenuItem
                onClick={() => {
                  handleOpenConfirmDelete();
                }}
              >
                <Stack direction='row' spacing={2} alignItems='center' p={2} pr={4}>
                  <IconTrash size='1.6rem' color={theme.palette.error.main}/>
                  <Typography color='error' variant='h5'>{t('segmentation.vennDiagram.render.settings.delete')}</Typography>
                </Stack>
              </MenuItem>
            }
          </Menu>
          <DeleteModalsRenderSegmentations
            openConfirmDelete={openConfirmDelete}
            handleCloseOpenConfirmDelete={handleCloseOpenConfirmDelete}
            handleDeleteSegmentation={handleDeleteSegmentation}
            title={t('segmentation.vennDiagram.render.settings.confirmDelete')}
          />
        </Grid>
      }
    </Grid>
  );
}

export default VennDiagramTitle;
