import React, { ReactElement, useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography, useTheme, Stack } from '@mui/material';
import { IconChevronDown, IconArrowRight, IconUser, IconBuilding } from '@tabler/icons-react';

function AddNewRecordButton(props: { onAddRecord: (templateType?: 'company' | 'person' | 'file' | undefined) => void }): ReactElement {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box pb={4}>
      <Button
        id='augment-button'
        variant='contained'
        aria-controls={open ? 'augment-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        endIcon={
          <IconChevronDown
            style={{
              transform: open ? 'rotate(180deg)' : undefined,
              transition: '0.2s ease-in-out'
            }}
            size='1.6rem'
          />
        }
        onClick={handleClick}
      >
        Create Record
      </Button>
      <Menu
        id='augment-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'augment-button'
        }}
      >
        <MenuItem
          onClick={() => {
            props.onAddRecord('person');
          }}
        >
          <Stack direction='row' spacing={3} alignItems='center' p={2} width='100%'>
            <IconUser size='1.2rem' color={theme.palette.primary.main} />
            <Typography variant='h4' color='primary' pr={2}>
              New Person
            </Typography>
            <IconArrowRight size='1.2rem' style={{ marginLeft: 'auto' }} color={theme.palette.primary.main} />
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            props.onAddRecord('company');
          }}
        >
          <Stack direction='row' spacing={3} alignItems='center' p={2} width='100%'>
            <IconBuilding size='1.2rem' color={theme.palette.secondary.main} />
            <Typography variant='h4' color='secondary' pr={2}>
              New Company
            </Typography>
            <IconArrowRight size='1.2rem' style={{ marginLeft: 'auto' }} color={theme.palette.secondary.main} />
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            props.onAddRecord('file');
          }}
        >
          <Stack direction='row' spacing={3} alignItems='center' p={2} width='100%'>
            <IconBuilding size='1.2rem' color={theme.palette.warning.main} />
            <Typography variant='h4' color='warning' pr={2}>
              Import through File
            </Typography>
            <IconArrowRight size='1.2rem' style={{ marginLeft: 'auto' }} color={theme.palette.warning.main} />
          </Stack>
        </MenuItem>

      </Menu>
    </Box>
  );
}

export default AddNewRecordButton;