import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { IconTarget } from '@tabler/icons-react';
import { PersonRncsOriginDataBlockContent } from '@deecision/dna-interfaces';
import { Tooltip, Typography } from '@mui/material';

function getColor(score: number): 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' {
  if (score === 1) {
    return 'primary';
  }

  if (score > .9) {
    return 'warning';
  }

  return 'error';
}

function RncsOriginChip(props: { rncsOrigin: PersonRncsOriginDataBlockContent | undefined }): ReactElement {
  const { t } = useTranslation();

  // can happen for end of network persons
  if (!props.rncsOrigin) {
    return <></>;
  }

  const label = `${t('common.rncsOrigin.score')}: ${(props.rncsOrigin.score * 100).toFixed(2)}%`;

  return (
    <Stack
      direction='row'
      spacing={2}
      maxWidth={340}
      flexWrap='wrap'
      useFlexGap
    >
      <Tooltip
        key='amf-chip'
        title={
          <Stack spacing={1}>
            <Typography>
              {t(`common.rncsOrigin.nbProviderEntities`)}: {props.rncsOrigin?.nbProviderEntity}
            </Typography>
            <Typography>
              {t(`common.rncsOrigin.nbProviderEntitiesWithWarning`)}: {props.rncsOrigin?.nbProviderEntityWithWarning ?? 0}
            </Typography>
            {
              Object.entries(props.rncsOrigin?.warnings ?? {}).map(([warningType, numberOfWarning]) => (
                <Typography key={`${warningType}-${numberOfWarning}`}>
                  {t(`common.rncsOrigin.warnings.types.${warningType}`)}: {numberOfWarning}
                </Typography>
              ))
            }
          </Stack>
        }
        arrow
        placement='top'
      >
        <Chip
          icon={
            <IconTarget
              size='1.5rem'
              style={{ marginLeft: '12px', marginRight: '-4px' }}
            />
          }
          size='medium'
          color={getColor(props.rncsOrigin.score)}
          label={label}
        />
      </Tooltip>
    </Stack>
  );
}

export default RncsOriginChip;
