import React from 'react';
import { IconUserScan } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import EntitiesListing from './entitiesList';
import EntityDetailPageHeader from '../entityPage/entityDetailPage';
import EntitiesDataElementTabs from '../entityPage/tabs/entities.tabs.dataElement';
import EntitiesSelfObjTabs from '../entityPage/tabs/entities.tabs.selfObj';
import EntitiesDataBlockTabs from '../entityPage/tabs/entities.tabs.dataBlock';
import EntitiesLinksTabs from '../entityPage/tabs/entityLinks/entities.tabs.entityLinks';
import EntitiesActionsTabs from '../entityPage/tabs/entities.tabs.actions';

const entityDetailTabs: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.admin.entitiesListing.tabs.dataElements.label,
    i18nKey: dnaConfig.routes.admin.entitiesListing.tabs.dataElements.i18nKey,
    path: dnaConfig.routes.admin.entitiesListing.tabs.dataElements.baseUrl,
    element: <EntitiesDataElementTabs />
  },
  {
    id: dnaConfig.routes.admin.entitiesListing.tabs.dataBlocks.label,
    i18nKey: dnaConfig.routes.admin.entitiesListing.tabs.dataBlocks.i18nKey,
    path: dnaConfig.routes.admin.entitiesListing.tabs.dataBlocks.baseUrl,
    element: <EntitiesDataBlockTabs />
  },
  {
    id: dnaConfig.routes.admin.entitiesListing.tabs.entityLinks.label,
    i18nKey: dnaConfig.routes.admin.entitiesListing.tabs.entityLinks.i18nKey,
    path: dnaConfig.routes.admin.entitiesListing.tabs.entityLinks.baseUrl,
    element: <EntitiesLinksTabs />
  },
  {
    id: dnaConfig.routes.admin.entitiesListing.tabs.actions.label,
    i18nKey: dnaConfig.routes.admin.entitiesListing.tabs.actions.i18nKey,
    path: dnaConfig.routes.admin.entitiesListing.tabs.actions.baseUrl,
    element: <EntitiesActionsTabs />
  },
  {
    id: dnaConfig.routes.admin.entitiesListing.tabs.details.label,
    i18nKey: dnaConfig.routes.admin.entitiesListing.tabs.details.i18nKey,
    path: dnaConfig.routes.admin.entitiesListing.tabs.details.baseUrl,
    element: <EntitiesSelfObjTabs />
  }
];

const routeEntitiesListing: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.entitiesListing.label,
  path: `${dnaConfig.routes.admin.entitiesListing.baseUrl}`,
  i18nKey: 'entities.dynamic',
  icon: IconUserScan,
  component: <EntitiesListing />, // Handles /entities/:entityType
  idComponent: <EntityDetailPageHeader />, // Handles /entities/:entityType/:id
  slug: 'entityType',
  slugSuffix: true,
  displayIndex: true,
  tabs: entityDetailTabs
});

export default routeEntitiesListing;
