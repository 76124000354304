import React, { ReactElement, useEffect, useState } from 'react';
import { IDataBlock } from '@deecision/dna-interfaces';
import { Grid2, IconButton, Stack, Tooltip } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import { IconJson } from '@tabler/icons-react';
import { DataBlocksQueryService } from '../../../../../../api/services/query';
import AdminCustomFilters from '../../listing/filters/adminCustomFilters';
import { AdminFilters } from '../../listing/filters/types';
import { CurrencyFormatterProps } from '@/utils';
import { Column, Row } from '@/components/tables/types';
import EvenOddTable from '@/components/tables/evenodd/table';
import JsonModal from '../../../recordSet/components/jsonModal';

function EntitiesDataBlockTabs(): ReactElement {
  const { entityType } = useParams();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const entityId = pathSegments[4];
  const dataBlockServices = new DataBlocksQueryService<IDataBlock>({ dataPath: undefined, entityType });
  const [dataBlocks, setDataBlocks] = useState<IDataBlock[]>([]);
  const [filteredDataBlocks, setFilteredDataBlocks] = useState<IDataBlock[]>([]);
  const [filters, setFilters] = useState<AdminFilters[]>([]);
  const [jsonModalStates, setJsonModalStates] = useState<{open: boolean, data: IDataBlock | null}>({ open: false, data: null });

  const sortByTimestamp = (a: IDataBlock, b: IDataBlock) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();

  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'type', label: 'Type' },
    { id: 'path', label: 'Path' },
    { id: 'provider', label: 'Provider' },
    { id: 'timestamp', label: 'Timestamp' },
    { id: 'actions', label: 'Actions' }
  ];

  const rows: Row<CurrencyFormatterProps>[] = filteredDataBlocks
    .sort(sortByTimestamp)
    .map(dataBlock =>({
      id: dataBlock.dataInfo.dataType,
      type: dataBlock.dataInfo.dataType,
      path: dataBlock.dataInfo.path,
      provider: dataBlock._source?.provider?.id,
      timestamp: dataBlock.timestamp,
      actions:
        <Stack direction='row'>
          <Tooltip arrow title='See Json Object'>
            <IconButton onClick={() => {
              setJsonModalStates({ open: true, data: dataBlock });
            }}>
              <IconJson color='grey'/>
            </IconButton>
          </Tooltip>
        </Stack>
    }));

  const buildDataBlocksFilters = (data: IDataBlock[]) => {
    // data Parsing logic to get the propertyKey we want and possibleValues
    const filtersParsing: AdminFilters[] = [];
    filtersParsing.push({
      propertyKey: 'dataInfo.path',
      label: 'Path',
      type: 'autocomplete',
      values: [...new Set(data.map(dataBlock => dataBlock.dataInfo.path))]
    });
    filtersParsing.push({
      propertyKey: 'dataInfo.dataType',
      label: 'Type',
      type: 'autocomplete',
      values: [...new Set(data.map(dataBlock => dataBlock.dataInfo.dataType))]
    });
    filtersParsing.push({
      propertyKey: '_source.provider.id ',
      label: 'Provider',
      type: 'multiselect',
      values: [...new Set(data.map(dataBlock => (dataBlock._source ? dataBlock._source.provider.id : '') ))]
    });

    setFilters(filtersParsing);
  };

  const fetchDataBlocksById = (id: string) => {
    dataBlockServices.findByQuery({ entityId: id })
      .then((res) => {
        if (res.data) {
          const sortedByTimestampData = res.data.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

          setDataBlocks(sortedByTimestampData);
          setFilteredDataBlocks(sortedByTimestampData);
          buildDataBlocksFilters(sortedByTimestampData);
        }
      })
      .catch(err => alert(err));
  };

  const updateFilteredData = (filteredData: IDataBlock[]) => {
    setFilteredDataBlocks(filteredData);
  };

  useEffect(() => {
    fetchDataBlocksById(entityId);
  }, [entityId]);

  return (
    <>
      {entityType &&
        <Stack spacing={4}>
          <Grid2>
            <AdminCustomFilters<IDataBlock>
              entityType={entityType}
              referenceData={dataBlocks}
              updateData={updateFilteredData}
              filters={filters}
            />
          </Grid2>
          <Stack>
            <EvenOddTable<CurrencyFormatterProps>
              label='entities'
              columns={columns}
              rows={rows || []}
              totalCount={filteredDataBlocks.length}
              hideFooter
            />
            <JsonModal<IDataBlock>
              jsonModalStates={jsonModalStates}
              setJsonModalStates={setJsonModalStates}
            />
          </Stack>
        </Stack>
      }
    </>
  );
}

export default EntitiesDataBlockTabs;
