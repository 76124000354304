import React, { ReactElement } from 'react';
import { Modal, Paper } from '@mui/material';
import { JsonEditor } from 'json-edit-react';

interface JsonModalInterface<T> {
  jsonModalStates: {
    open: boolean,
    data: T | null
  },
  setJsonModalStates: React.Dispatch<React.SetStateAction<{
    open: boolean,
    data: T | null
  }>>,
  jsonRootTitle? : string
}

function JsonModal<T>(props: JsonModalInterface<T>): ReactElement {
  const handleClose = () => {
    props.setJsonModalStates({ open: false, data: null });
  };

  return (
    <Modal
      open={props.jsonModalStates.open}
      onClose={handleClose}
    >
      <Paper
        sx={{
          width: 'min-content',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
          maxHeight: '90vh',
          overflow: 'auto'
        }}
      >
        {props.jsonModalStates.data &&
          <JsonEditor
            data={props.jsonModalStates.data}
            viewOnly
            indent={2}
            minWidth={700}
            rootName={props.jsonRootTitle || 'Obj'}
          />
        }
      </Paper>
    </Modal>
  );
}

export default JsonModal;