import React, { ReactElement, useEffect, useState } from 'react';
import { EntityLinksAggregation } from '@deecision/dna-interfaces';
import { Tooltip, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { IconArrowRight, IconJson } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EvenOddTable from '@/components/tables/evenodd/table';
import { Column, Row } from '@/components/tables/types';
import JsonModal from '@/main/modules/admin/recordSet/components/jsonModal';
import { CurrencyFormatterProps } from '@/utils';
import AdminCustomFilters from '../../../../listing/filters/adminCustomFilters';
import { AdminFilters } from '../../../../listing/filters/types';

interface LinksTableProps {
  links: EntityLinksAggregation[]
}

function ToLinksTable(props: LinksTableProps): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [jsonModalStates, setJsonModalStates] = useState<{open: boolean, data: EntityLinksAggregation | null}>({ open: false, data: null });
  const [filteredLinks, setFilteredLinks] = useState<EntityLinksAggregation[]>([]);
  const [filters, setFilters] = useState<AdminFilters[]>([]);
  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'type', label: 'Type' },
    { id: 'name', label: `Name => ${props.links.length > 0 ? props.links[0].entity2.name : 'THIS'}` },
    { id: 'entityId', label: 'Id' },
    { id: 'linkType', label: 'Link Type' },
    { id: 'provider', label: 'Provider' },
    { id: 'actions', label: 'Actions' }
  ];

  const rows: Row<CurrencyFormatterProps>[] = filteredLinks
    .map(link =>({
      id: link.entity1.entityId,
      name: link.entity1.name,
      type: link.entity1.entityType,
      entityId: link.entity1.entityId,
      linkType: link.links[0].type,
      provider: link.links[0]._source?.provider?.id || 'undefined',
      actions:
        <Stack direction='row'>
          <Tooltip arrow title='See Json Object'>
            <IconButton onClick={() => {
              setJsonModalStates({ open: true, data: link });
            }}>
              <IconJson color='grey'/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={t('common.utils.seeEntityPage')}>
            <IconButton onClick={() => {
              navigate(`/${link.entity1.entityType.toLowerCase().includes('person') ? 'persons' : 'companies'}/${link.entity1.entityId}`);
            }}>
              <IconArrowRight color='blue' size='1.2rem'/>
            </IconButton>
          </Tooltip>
        </Stack>
    }));

  const buildLinksFilters = (data: EntityLinksAggregation[]) => {
    const filtersParsing: AdminFilters[] = [];
    filtersParsing.push({
      propertyKey: 'entity1.entityType',
      label: 'EntityType',
      type: 'multiselect',
      values: [...new Set(data.map(link => link.entity1.entityType))]
    });
    filtersParsing.push({
      propertyKey: 'links[0].type',
      label: 'LinkType',
      type: 'multiselect',
      values: [...new Set(data.map(link => link.links[0].type))]
    });

    setFilters(filtersParsing);
  };

  const updateFilteredData = (filteredData: EntityLinksAggregation[]) => {
    setFilteredLinks(filteredData);
  };

  useEffect(() => {
    buildLinksFilters(props.links);
  }, [props.links]);

  return (
    <Stack>
      <Stack pb={2}>
        <AdminCustomFilters<EntityLinksAggregation>
          referenceData={props.links}
          updateData={updateFilteredData}
          filters={filters}
        />
      </Stack>

      <EvenOddTable<CurrencyFormatterProps>
        label='ToLinks'
        columns={columns}
        rows={rows || []}
        totalCount={filteredLinks.length}
        hideFooter
      />

      <JsonModal<EntityLinksAggregation>
        jsonModalStates={jsonModalStates}
        setJsonModalStates={setJsonModalStates}
      />
    </Stack>
  );
}

export default ToLinksTable;