import { GroupDesc } from '@deecision/dna-interfaces';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { IconCheck, IconMinus, IconPlus } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { uniq, upperFirst } from 'lodash';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

interface AddFiltersBuilderSegmentationProps {
  possibleFilters: GroupDesc[],
  addSegmentationFilters: (possibleFilter: GroupDesc[]) => void,
  addSegmentationFilterCategory: (category?: string) => void,
  fullWidth?: true,
  contained?: true
}

export function PossibleFiltersTreeView(props: AddFiltersBuilderSegmentationProps & { handleClose: () => void, disableAddAll?: true }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(!props.disableAddAll);
  const [segmentationFiltersToAdd, setSegmentationFiltersToAdd] = useState<GroupDesc[]>([]);

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={2} p={2} alignItems='center'>
        {!props.disableAddAll &&
          <Typography variant='h3'>{t('segmentation.vennDiagram.builder.segments.fields.addFilter')}</Typography>
        }
        <Button onClick={() => setCollapsed(prevState => !prevState)} endIcon={collapsed ? <IconPlus size='1rem' /> : <IconMinus size='1rem' />} size='small'>
          {collapsed ? t('common.utils.expandAll') : t('common.utils.collapseAll')}
        </Button>
      </Stack>
      <Box maxHeight='80vh' overflow='auto'>
        <SimpleTreeView
          key={collapsed ? 'collapsed' : 'expanded'}
          sx={{ overflow: 'auto' }}
          defaultExpandedItems={!collapsed ?
            uniq(props.possibleFilters.map(possibleFilter => possibleFilter.categories || '').flat(1)) :
            []
          }
        >
          {uniq(props.possibleFilters.map(possibleFilter => possibleFilter.categories).flat(1)).map(category => (
            <TreeItem
              itemId={category || ''}
              key={category}
              label={
                <Stack direction='row' spacing={4} alignItems='center'>
                  <Typography variant='h4'>{`${upperFirst(category?.split(/(?=[A-Z])/).join(' '))} (${props.possibleFilters.filter(possibleFilter => category && possibleFilter.categories?.includes(category)).length})`}</Typography>
                  {!props.disableAddAll &&
                    <Button
                      variant='outlined'
                      startIcon={<IconPlus size='1rem' />}
                      size='small'
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleClose();
                        props.addSegmentationFilterCategory(category);
                      }}
                    >
                      {t('segmentation.vennDiagram.builder.segments.fields.addCategory')}
                    </Button>
                  }
                </Stack>
              }
            >
              {props.possibleFilters.filter(possibleFilter => category && possibleFilter.categories?.includes(category)).map(possibleFilter => (
                <TreeItem
                  itemId={`${category}-${possibleFilter.subGroupId}`}
                  key={`${category}-${possibleFilter.subGroupId}`}
                  onClick={() => {
                    setSegmentationFiltersToAdd((prevState) => {
                      if (prevState.find(c => c.subGroupId === possibleFilter.subGroupId)) {
                        return prevState.filter(c => c.subGroupId !== possibleFilter.subGroupId);
                      }

                      if (props.disableAddAll) {
                        return [possibleFilter];
                      }

                      return [...prevState, possibleFilter];
                    });
                  }}
                  sx={segmentationFiltersToAdd.find(c => c.subGroupId === possibleFilter.subGroupId) ? {
                    bgcolor: theme.palette.success.light,
                    borderRadius: 1
                  } : {
                    borderRadius: 1
                  }}
                  label={
                    <Stack direction='row' spacing={2} width='100%' alignItems='center' pr={2}>
                      <Typography variant='body1'>{possibleFilter.label as string || possibleFilter.subGroupId}</Typography>
                      {!props.disableAddAll && (
                        segmentationFiltersToAdd.find(c => c.subGroupId === possibleFilter.subGroupId) ?
                          <IconCheck size='1rem' style={{ marginLeft: 'auto' }} /> :
                          <IconPlus size='1rem' style={{ marginLeft: 'auto' }} />)
                      }
                    </Stack>
                  }
                />
              ))}
            </TreeItem>
          ))}
        </SimpleTreeView>
      </Box>
      {!props.disableAddAll &&
        <Button
          startIcon={<IconPlus size='1.2rem' />}
          onClick={() => {
            props.handleClose();
            props.addSegmentationFilters(props.possibleFilters);
          }}
        >
          {`${t('segmentation.vennDiagram.builder.segments.fields.addAll')} (${props.possibleFilters.length})`}
        </Button>
      }
      <Button
        variant='contained'
        onClick={() => {
          props.addSegmentationFilters(segmentationFiltersToAdd);
          setSegmentationFiltersToAdd([]);
          props.handleClose();
        }}
        disabled={segmentationFiltersToAdd.length === 0}
      >
        {props.disableAddAll
          ? t('segmentation.vennDiagram.builder.segments.filters.replaceSelected')
          : `${t('segmentation.vennDiagram.builder.segments.filters.addSelected')} ${segmentationFiltersToAdd.length} ${t('segmentation.vennDiagram.builder.segments.filters.addSelected2')}`
        }
      </Button>
    </Stack>
  );
}

function AddFiltersBuilderVenn(props: AddFiltersBuilderSegmentationProps): ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Button
        variant='contained'
        startIcon={<IconPlus size='1.2rem' />}
        onClick={handleOpen}
        disabled={props.possibleFilters.length < 1}
        fullWidth={props.fullWidth}
      >
        {t('segmentation.vennDiagram.builder.segments.fields.addFilter')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='criterias-selection-modal-title'
        aria-describedby='criterias-selection-modal-description'
      >
        <Paper sx={style}>
          <PossibleFiltersTreeView {...props} handleClose={handleClose} />
        </Paper>
      </Modal>
    </Box>
  );
}

export default AddFiltersBuilderVenn;
