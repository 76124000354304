interface DnaEnv {
    REACT_APP_DNA_API_BASE_URL: string,
    REACT_APP_DNA_PLATFORM: string,
    REACT_APP_DNA_SOCKET_URL: string,
    REACT_APP_WEBAPP_NAME: string,
    REACT_APP_DISABLE_ZOD_VALIDATION_WARNING: string
}

declare global {
    interface Window {
        __DNA_ENV__: DnaEnv
    }
}

export const API_BASE_URL = process.env.REACT_APP_DNA_API_BASE_URL ?? window.__DNA_ENV__.REACT_APP_DNA_API_BASE_URL;
export const PLATFORM = process.env.REACT_APP_DNA_PLATFORM ?? window.__DNA_ENV__.REACT_APP_DNA_PLATFORM;
export const DNA_SOCKET_URL = process.env.REACT_APP_DNA_SOCKET_URL ?? window.__DNA_ENV__.REACT_APP_DNA_SOCKET_URL;
export const WEBAPP_NAME = process.env.REACT_APP_WEBAPP_NAME ?? window.__DNA_ENV__.REACT_APP_WEBAPP_NAME;
export const DISABLE_ZOD_VALIDATION_WARNING = process.env.REACT_APP_DISABLE_ZOD_VALIDATION_WARNING ?? window.__DNA_ENV__.REACT_APP_DISABLE_ZOD_VALIDATION_WARNING;
