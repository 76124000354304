import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconCircleArrowDownFilled,
  IconCircleArrowUpFilled,
  IconDatabase,
  IconInfoCircle,
  IconRuler2,
  IconTargetArrow,
  IconX
} from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import { Divider, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import {
  IDataBlock,
  PersonEntity,
  zExtrapolatedWeasseDataBlock,
  zIDataBlock,
  zWeasseDataBlock
} from '@deecision/dna-interfaces';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { z } from 'zod';
import { ExtrapolatedWeasseDataBlock } from '@deecision/dna-interfaces/dist/dataBlocks/extrapolatedWeasseDataBlock';
import { Completeness as IComplenetess } from '@deecision/dna-interfaces/dist/entityBase';
import { PersonGetter } from '@/main/providers/getter';
import EconomicalPotentialInfo, { EconomicalPotentialLevelInfo } from '@/main/containers/economical-potential/info';
import BaseWidget from '@/main/containers/widgets/base-widget';
import TitleComponent from '@/components/title';
import { WidgetProps } from '@/main/containers/widgets/types';
import Completeness from '@/components/chips/completeness';
import { DataBlocksQueryService } from '@/api/services/query';
import { weasseDataPaths } from '@/main/modules/entities/persons/components/tabs/weasse';
import Accuracy from '@/components/chips/accuracy';

function WidgetEconomicalPotential(props: WidgetProps<PersonEntity>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const entityGetter = new PersonGetter({ data: props.data, errorMsg: t('common.utils.unknown') });
  const economicalPotentialLevel = entityGetter.getEconomicalPotentialLevel();
  const economicalPotentialInfo = EconomicalPotentialLevelInfo.find(info => info.levelString === economicalPotentialLevel);
  const [open, setOpen] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(false);
  const dataBlockService = new DataBlocksQueryService<ExtrapolatedWeasseDataBlock>({ dataPath: weasseDataPaths, entityType: 'deecPerson', schema: zIDataBlock(z.union([
    zWeasseDataBlock,
    zExtrapolatedWeasseDataBlock
  ])) });
  const [weasseDataBlocks, setWeasseDataBlocks] = useState<IDataBlock<ExtrapolatedWeasseDataBlock>[]>();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dataBlockService.get(props.data.entityId)
      .then((res) => {
        if (res.data) {
          setWeasseDataBlocks(res.data.filter(d => d.dataInfo.path === 'person.extrapolatedWeasse'));
        }
      });
  }, [props.data.entityId]);

  return (
    <BaseWidget
      {...props}
      title={
        <TitleComponent title={t('economicalPotential.label')} icon={IconRuler2} small />
      }
      action={
        <>
          <Tooltip title={t('common.informations.tooltip')} arrow>
            <IconButton onClick={() => setOpen(true)} color='primary'>
              <IconInfoCircle size='1.2rem' />
            </IconButton>
          </Tooltip>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll='paper'
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
            maxWidth='sm'
            fullWidth
          >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <DialogTitle id='scroll-dialog-title' sx={{ fontSize: '1.2rem' }}>
                {t('common.utils.informations')}
              </DialogTitle>
              <DialogActions>
                <IconButton onClick={handleClose}>
                  <IconX size='1.2rem' />
                </IconButton>
              </DialogActions>
            </Stack>
            <DialogContent dividers>
              <DialogContentText
                id='scroll-dialog-description'
                tabIndex={-1}
              >
                <EconomicalPotentialInfo />
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      }
    >
      <Stack spacing={4}>
        <Stack spacing={2} direction='row' width='100%' alignItems='center' justifyContent='center'>
          {EconomicalPotentialLevelInfo.map(info => (
            economicalPotentialLevel === info.levelString
              ? <Box
                key={info.label}
                sx={{ borderRadius: 2, border: 'solid 4px', borderColor: info.bgcolor, p: '2px' }}
              >
                <Stack
                  sx={{
                    bgcolor: info.bgcolor,
                    width: 32,
                    height: 32,
                    borderRadius: 2,
                    textOverflow: 'clip',
                    color: theme.palette.primary.contrastText
                  }}
                  alignItems='center'
                  justifyContent='center'
                >
                  <Typography width={32} fontWeight={700} textAlign='center'>
                    {info.level}
                  </Typography>
                </Stack>
              </Box>
              : <Stack
                sx={{
                  opacity: 0.5,
                  bgcolor: info.bgcolor,
                  width: 32,
                  height: 32,
                  borderRadius: 2,
                  textOverflow: 'clip',
                  color: theme.palette.primary.contrastText
                }}
                alignItems='center'
                justifyContent='center'
              >
                <Typography width={32} fontWeight={700} textAlign='center'>
                  {info.level}
                </Typography>
              </Stack>
          ))}
        </Stack>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
          onClick={() => setDisplayDetails(prevState => !prevState)}
          sx={{ cursor: 'pointer' }}
        >
          <Typography alignSelf='center' mr={1}>
            {displayDetails ? t('common.utils.hideDetails') : t('common.utils.showDetails')}
          </Typography>
          {displayDetails ? <IconCircleArrowUpFilled size='1rem' /> : <IconCircleArrowDownFilled size='1rem' />}
        </Box>
        {displayDetails && economicalPotentialInfo
          ? <Stack key={economicalPotentialInfo.label} spacing={2} justifyContent='center' alignItems='center' width='100%'>
            <Chip
              label={t(economicalPotentialInfo.label)}
              sx={{
                width: '100%',
                maxWidth: 320,
                bgcolor: economicalPotentialInfo.bgcolor,
                '.MuiChip-label': {
                  fontWeight: 700,
                  color: theme.palette.primary.contrastText
                }
              }}
            />
            <Typography
              variant='body1'
              sx={{
                textAlign: 'center',
                maxWidth: 380,
                whiteSpace: 'pretty'
              }}
              dangerouslySetInnerHTML={{
                __html: t(economicalPotentialInfo.description)
              }}
            />
          </Stack>
          : null
        }
        <Divider />
        <Typography variant='body1' maxWidth={380} pl={2} pr={2}>
          <span style={{ marginRight: '4px' }}>
            <Completeness
              completeness={entityGetter.getPersonCompleteness()}
              icon={IconDatabase}
            />
          </span>
          {t('economicalPotential.completeness')}
        </Typography>
        <Divider />
        <Typography variant='body1' maxWidth={380} pl={2} pr={2}>
          <span style={{ marginRight: '4px' }}>
            <Completeness
              completeness={(weasseDataBlocks?.[0]?.data.values?.extrapolatedWeasse?.completeness as IComplenetess)?.value}
              icon={IconDatabase}
            />
          </span>
          {t('economicalPotential.financialCompleteness')}
        </Typography>
        <Divider />
        <Typography variant='body1' maxWidth={380} pl={2} pr={2}>
          <span style={{ marginRight: '4px' }}>
            <Accuracy
              accuracy={weasseDataBlocks?.[0]?.data.values?.extrapolatedWeasse?.accuracy}
              icon={IconTargetArrow}
            />
          </span>
          {t('economicalPotential.financialAccuracy')}
        </Typography>
      </Stack>
    </BaseWidget>
  );
}

export default WidgetEconomicalPotential;
