import React, { ReactElement } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import TitleComponent from '@/components/title';
import { VeegilenzElementsProps } from './types';
import { CardPart } from '@/components/cards/keyvalue.cards';

function CorporateNetworkVeegilenz(props: VeegilenzElementsProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  const keyValues: { key: string, value: string, score: number }[] = [
    {
      key: t('veegilenz.keys.mandatesCurrentWithAML'),
      value: t('veegilenz.errorResponse.notSearched'),
      score: 0
    },
    {
      key: t('veegilenz.keys.mandatesCurrentWithListedCompanies'),
      value: t('veegilenz.errorResponse.notSearched'),
      score: 0
    }
  ];

  return (
    <Paper variant='hoverElevation1' sx={{ opacity: props.disabled ? 0.6 : undefined }}>
      <Grid container spacing={2} width='min-content'>
        <Grid size={12} pt={2}>
          <TitleComponent title={t('veegilenz.corporateNetwork')} />
        </Grid>
        <Grid size={12} p={2}>
          <Paper
            elevation={0}
            sx={{
              bgcolor: props.disabled ? theme.palette.grey[400] : props.scoreColor[Math.floor(props.score || 0)].light,
              color: props.disabled ? theme.palette.grey[700] : props.scoreColor[Math.floor(props.score || 0)].dark,
              width: '100%'
            }}
          >
            <Stack
              sx={{
                width: '100%',
                height: '32px',
                textAlign: 'center',
                justifyContent: 'center'
              }}
            >
              {props.disabled ?
                <Typography variant='h3'>{t('veegilenz.errorResponse.notCalculated')}</Typography> :
                <Typography variant='h1'>{props.score}</Typography>
              }
            </Stack>
          </Paper>
        </Grid>
        <Grid size={12} p={2} pr={4} pl={4}>
          <CardPart
            values={
              keyValues.map(keyValue => ({
                key: keyValue.key,
                value: keyValue.value,
                align: 'right',
                status: keyValue.score < 2 ? 'success' : keyValue.score < 4 ? 'warning' : 'error'
              }))
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CorporateNetworkVeegilenz;
