import React from 'react';
import { IconUserShield } from '@tabler/icons-react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import { ROLES } from '@deecision/dna-interfaces/dist/constants/roles';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../config/dna.config.json';
import routesFactory from './factory/routes';
import routeDashboard from './Dashboard/routes.dashboard';
// import routeDataElements from './DataElements/routes.dataElements';
import routeEntities from './Entities/route.entities';
// import routeDataBlocks from './DataBlocks/route.dataBlocks';
import HomePageAdminComponent from './components/tabs/adminHomePage';
import { CustomRouteObject } from '@/router/types';
import routeEntitiesListing from './Entities/listing/route.entities.listing';
import routeBatches from './batches/route.batches';
import routeRecordSets from './recordSet/route.recordSet';
import routeBatchesConfigurationCreation from './batchesConfigurationAndCreation/route.batchesConfCreate';
import routeSteps from './steps/route.steps';
import routeSuperAdminActions from './superAdminActions/route.superAdminActions';
import routeDataElementsMonitoring from './dataElementsMonitoring/routes.dataElementsMonitoring';
import routeAdminPortfolios from './portfolios/route.admin.portfolios';

const routesAdmin: CustomRouteObject[] = [
  createElementRoutes< PersonEntity | CompanyEntity>({
    id: dnaConfig.routes.admin.label,
    path: dnaConfig.routes.admin.baseUrl,
    i18nKey: dnaConfig.routes.admin.i18nKey,
    icon: IconUserShield,
    sectionTitle: dnaConfig.routes.admin.i18nKey,
    component: <HomePageAdminComponent />,
    asA: ROLES.ADMIN,
    childrens: [
      routesFactory,
      routeDashboard,
      routeAdminPortfolios,
      routeDataElementsMonitoring,
      routeSuperAdminActions,
      routeEntities,
      routeEntitiesListing,
      // routeDataElements,
      // routeDataBlocks,
      routeBatches,
      routeRecordSets,
      routeSteps,
      routeBatchesConfigurationCreation
    ]
  })
];

export default routesAdmin;
