import type Keycloak from 'keycloak-js';
import { ReactNode } from 'react';
import { CanProps } from './types';

interface CheckAbilityProps extends CanProps {
  keycloak: Keycloak
}

export default class CheckAbility {
  private _isAllowed = false;

  private _keycloak: Keycloak;

  props: CanProps;

  constructor(props: CheckAbilityProps) {
    this._keycloak = props.keycloak;
    this.props = props;
  }

  private _hasKeycloakResourceRole(role?: string) {
    return role ? this._keycloak.hasResourceRole(role, this._keycloak.clientId) : false;
  }

  private hasAbility(roles?: (string | undefined)[]) {
    if (roles && roles.length > 0) {
      this._isAllowed = roles.some(role => this._hasKeycloakResourceRole(role));
    }

    return this.props.not ? !this._isAllowed : this._isAllowed;
  }

  private _passThrough(roles?: (string | undefined)[], passIfNoRole?: boolean, passIfNoPermission?: boolean, passIfNoRessource?: boolean, passThrough?: boolean) {
    return !!(
      passIfNoRole && (roles || []).length === 0
      || passThrough
    );
  }

  private _canRender(): boolean {
    const props = this.props;
    const roles = Array.isArray(props.asA) ? props.asA : props.asA ? [props.asA] : [];

    const passThrough = this._passThrough(roles, props.passIfNoRole, props.passThrough);
    const ability = this.hasAbility(roles);

    return passThrough || ability;
  }

  render() {
    this._isAllowed = this._canRender();

    return this.props.passThrough || this._isAllowed ? this._renderChildren() : this._renderFallback();
  }

  private _renderFallback() {
    const { fallback } = this.props;
    const elements = typeof fallback === 'function'
      ? fallback(this._isAllowed) || null
      : fallback || null;

    return elements as ReactNode;
  }

  private _renderChildren() {
    const { children } = this.props;
    const elements =  typeof children === 'function'
      ? children(this._isAllowed) || true
      : children || true;

    return elements as ReactNode;
  }
}
