import React, { ReactElement, useEffect, useState } from 'react';
import { IconBuildings, IconUsers } from '@tabler/icons-react';
import { SegmentationPossibleFilter } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import TitleComponent from '@/components/title';
import BaseWidget from '@/main/containers/widgets/base-widget';
import PieCharts from '@/components/charts/pie';
import { VeegilenzPortfolioDashboardConfig } from '../dashboards/portfolio/veegilenz';
import SegmentationServices, { CustomSegmentationFiltersService } from '../segmentations/services';
import { getEntityTypeEquivalent } from '@/main/providers/getter';

interface VeegilenzGlobalSegmentationChartProps {
  baseGroupId: string,
  config: VeegilenzPortfolioDashboardConfig,
  callSegmentation: (entityType?: string, groupId?: string, workflowId?: string, filterIds?: string[], filterValues?: string[]) => void
}

function VeegilenzGlobalSegmentationChartWidget(props: VeegilenzGlobalSegmentationChartProps): ReactElement {
  const { t } = useTranslation();
  const segmentationServices = new SegmentationServices();
  const segmentationFiltersServices = new CustomSegmentationFiltersService<SegmentationPossibleFilter>();
  const [veegilenzCriterias, setVeegilenzCriterias] = useState<SegmentationPossibleFilter[]>([]);
  const [selected, setSelected] = useState<number[]>();
  const [datas, setDatas] = useState<{
    count: number | null,
    value?: string,
    range?: {
        min: number,
        max: number
    } | undefined,
    displayValue?: string | undefined
  }[]>([]);

  const criteriaType = {
    deecPerson: 'person',
    deecCompany: 'company'
  }[props.config.entityType];

  const outputEntities = {
    deecPerson: 'person1' as const,
    deecCompany: 'company' as const
  }[props.config.entityType];

  const filterId = {
    deecPerson: 'person_groupMember',
    deecCompany: 'company_groupMember'
  }[props.config.entityType];

  const veegilenzTranslatedColors: Record<string, string> = {
    vert: 'green',
    rouge: 'red',
    noir: 'black'
  };

  const getVeegilenzCriterias = () => {
    segmentationFiltersServices.getAll({ filters: [{ scope: 'on', id: 'on', value: criteriaType }] })
      .then((res) => {
        const veegilenzGlobalCriteria = res.data?.find(filter => filter.label?.includes('Veegilenz (global)'));
        setVeegilenzCriterias(veegilenzGlobalCriteria ? [veegilenzGlobalCriteria] : []);
      });
  };

  const veegilenzColorsAsEnglishKey = (value: string): string => veegilenzTranslatedColors[value] || value;

  const performSegmentation = () => {
    segmentationServices.perform({
      dataSetId: getEntityTypeEquivalent(props.config.entityType),
      globalFilteringItems: [],
      filters: [
        {
          id: filterId,
          filterId,
          type: 'filter',
          on: outputEntities,
          values: [
            `${props.baseGroupId}/${props.config.groupId}`
          ]
        }
      ],
      entitiesSettings: {
        includeEntities: true,
        findOptions: {}
      },
      outputEntities,
      segmentationCriterias: veegilenzCriterias.map(veegilenzCriteria => ({
        ...veegilenzCriteria,
        type: 'segmentationCriteria',
        filterId: veegilenzCriteria.id,
        filterLabel: veegilenzCriteria.label,
        on: outputEntities
      }))
    })
      .then((res) => {
        if (res.data) {
          Object.values(res.data?.segmentationCriterias).forEach((criteria) => {
            if (criteria.values) {
              const criteriaAsData = criteria.values.map(value => ({ count: value.count, value: t(`veegilenz.colors.${value.value}`) }));
              setDatas(criteriaAsData);
            }
          });
        }
      });
  };

  useEffect(() => {
    getVeegilenzCriterias();
  }, []);

  useEffect(() => {
    if (veegilenzCriterias.length > 0) {
      performSegmentation();
    }
  }, [veegilenzCriterias]);

  useEffect(() => {
    if (veegilenzCriterias.length > 0 && selected) {
      const veegilenzSelected = datas[selected[0]].value || '';
      const filterValueAsEnglishValue = veegilenzColorsAsEnglishKey(veegilenzSelected);

      props.callSegmentation(
        props.config.entityType,
        props.config.groupId,
        undefined,
        [veegilenzCriterias[0]?.id],
        [filterValueAsEnglishValue]);
    }
  }, [selected]);

  return (
    <BaseWidget
      title={
        <TitleComponent
          title={`Veegilenz / ${t('entities.persons.person.charts.radar.conformity')} Global - ${t(`entities.${criteriaType}.label`)}`}
          icon={props.config.entityType === 'deecPerson' ? IconUsers : IconBuildings}
          size='small'
        />
      }
      size='small'
      fullWidth
      fullHeight
    >
      <PieCharts
        datas={[datas]}
        selected={selected}
        setSelected={setSelected}
      />
    </BaseWidget>
  );
}

export default VeegilenzGlobalSegmentationChartWidget;
