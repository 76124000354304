import React, { ReactElement, useEffect, useState } from 'react';
import { EntityLinkQueryBuilder, EntityLinksAggregation } from '@deecision/dna-interfaces';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { FormControlLabel, FormGroup, Grid2, Stack, Switch } from '@mui/material';
import { EntityLinksQueryAggregatorService } from '@/api/services/query';
import FromLinksTable from './components/fromLinksTable';
import ToLinksTable from './components/toLinksTable';

function EntitiesLinksTabs(): ReactElement {
  const { entityType } = useParams();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const entityId = pathSegments[4];
  const [fromLinks, setFromLinks] = useState<EntityLinksAggregation[]>([]);
  const [toLinks, setToLinks] = useState<EntityLinksAggregation[]>([]);
  const [tableMode, setTableMode] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const resetFilters = () => {
    Array.from(searchParams.keys()).forEach((key) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  };

  const onTableModeChange = () => {
    resetFilters();
    setTableMode(!tableMode);
    // should reset params url
  };

  const fetchLinks = (id: string, type?: string) => {
    if (!type) {
      return;
    }
    const fromLinksQuery = new EntityLinkQueryBuilder()
      .fromEntity(type, id)
      .withActive('all')
      .build();
    const toLinksQuery = new EntityLinkQueryBuilder()
      .toEntity(type, id)
      .withActive('all')
      .build();
    const linksService = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({ entityType: type });

    // Get from Links
    linksService.aggregateEntityLinks(fromLinksQuery)
      .then((res) => {
        if (res.data) {
          setFromLinks(res.data);
        }
      })
      .catch((err) => {
        alert(err);
      });

    // Get to Links
    linksService.aggregateEntityLinks(toLinksQuery)
      .then((res) => {
        if (res.data) {
          setToLinks(res.data);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    if (entityId && entityType) {
      fetchLinks(entityId, entityType);
    }
  }, [entityId, entityType]);

  return (
    <>
      {entityType ?
        <Stack spacing={4}>
          <Grid2 container direction='row' alignItems='center'>
            <FormGroup>
              <FormControlLabel control={<Switch checked={tableMode} onChange={onTableModeChange} />} label={` Links ${tableMode ? 'To' : 'From'} "${fromLinks.length > 0 ? fromLinks[0].entity1.name : entityId}"`} />
            </FormGroup>
          </Grid2>

          {!tableMode ?
            <FromLinksTable links={fromLinks} />
            :
            <ToLinksTable links={toLinks} />
          }
        </Stack>
        :
        null
      }
    </>
  );
}

export default EntitiesLinksTabs;