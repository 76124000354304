import React from 'react';
import { ROLES } from '@deecision/dna-interfaces/dist/constants/roles';
import { IconFolders } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import RecordSetDetailsHeader from './recordSetDetailsHeader';
import RecordSetsListing from './recordSetListing';
import RecordSetTabsRecordListing from './tabs/recordListing.tabs';
import RecordSetTabsSelfObj from './tabs/recordSet.tabs.selfObj';

const recordSetDetailTabs: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.admin.recordSets.tabs.listing.label,
    i18nKey: dnaConfig.routes.admin.recordSets.tabs.listing.i18nKey,
    path: dnaConfig.routes.admin.recordSets.tabs.listing.baseUrl,
    element: <RecordSetTabsRecordListing />
  },
  {
    id: dnaConfig.routes.admin.recordSets.tabs.details.label,
    i18nKey: dnaConfig.routes.admin.recordSets.tabs.details.i18nKey,
    path: dnaConfig.routes.admin.recordSets.tabs.details.baseUrl,
    element: <RecordSetTabsSelfObj />
  }
];

const routeRecordSets: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.recordSets.i18nKey,
  path: dnaConfig.routes.admin.recordSets.baseUrl,
  i18nKey: dnaConfig.routes.admin.recordSets.i18nKey,
  icon: IconFolders,
  asA: ROLES.DEVELOPER,
  component: <RecordSetsListing />,
  idComponent: <RecordSetDetailsHeader />,
  displayIndex: true,
  tabs: recordSetDetailTabs
});

export default routeRecordSets;
