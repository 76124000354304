import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { PersonEntity } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { IconAlertTriangleFilled, IconGrave } from '@tabler/icons-react';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { startCase } from 'lodash';
import CountriesFlags from '@/components/countriesflags';
import { currencyFormatter, CurrencyFormatterProps } from '@/utils';
import { makeRes, PersonGetter } from '../../providers/getter';
import EvenOddTable from '@/components/tables/evenodd/table';
import { Column } from '@/components/tables/types';
import dnaConfig from '../../../config/dna.config.json';
import { ListProps } from './types';
import Accuracy from '../../../components/chips/accuracy';
import Status from '../../../components/status';
import Completeness from '@/components/chips/completeness';

function PersonList(props: ListProps<PersonEntity>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  // console.log((i18nPossibleLanguages.find(lang => lang.global === (i18n.language || i18nPossibleLanguages[0].global)) || i18nPossibleLanguages[0]).version);

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'name',
      label: t('common.identity.name'),
      sort: !props.disableSort && !props.localSort ? 'name' : undefined,
      sortPath: 'props.children',
      minWidth: 240,
      noMaxWidth: true
    },
    {
      id: 'veegilenz',
      label: t('veegilenz.label'),
      sort: !props.disableSort && !props.localSort ? 'dv_veegilenzLevel' : undefined,
      align: 'center'
    },
    {
      id: 'tags',
      label: t('common.utils.tags')
    },
    {
      id: 'politicalExposure',
      label: t('politicalExposure.label'),
      sort: !props.disableSort && !props.localSort ? 'dv_politicalExposureLevel' : undefined
    },
    {
      id: 'nationality',
      label: t('common.identity.nationalities'),
      sort: !props.disableSort && !props.localSort ? 'dv_persons_allNationalities' : undefined
    },
    {
      id: 'residence',
      label: t('common.identity.countries'),
      sort: !props.disableSort && !props.localSort ? 'dv_persons_allCountries' : undefined
    },
    {
      id: 'nbActiveCompanies',
      label: t('entities.companies.keys.nbActive'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_stats_activeCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'nbClosedCompanies',
      label: t('entities.companies.keys.nbClosed'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_stats_inactiveCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'nbTotalCompanies',
      label: t('entities.companies.keys.nbTotal'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_stats_allCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'nbActiveRelations',
      label: t('entities.relations.keys.nbActive'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_network_stats_activeCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'nbInactiveRelations',
      label: t('entities.relations.keys.nbInactive'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_network_stats_inactiveCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'nbTotalRelations',
      label: t('entities.relations.keys.nbTotal'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_network_stats_allCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'dismemberment',
      label: t('patrimonialComplexity.dismemberment.label'),
      align: 'right',
      render: value => makeRes(value),
      sort: !props.disableSort && !props.localSort ? 'dv_asset_complexity_nb_dismemberment' : undefined
    },
    {
      id: 'turnoverSum',
      label: t('common.financial.turnover.sum'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_turnoverSum' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'ebitdaSum',
      label: t('common.financial.ebitda.sum'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_ebitdaSum' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'capitalSum',
      label: t('common.financial.capital.sum'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_capitalSum' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'valuationSum',
      label: t('common.financial.valuation.sum'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_valuationSum' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'weasse',
      label: t('common.financial.weasse.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_person_weasse' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    /*
    {
      id: 'weasseMax',
      label: t('common.financial.weasseMax.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_person_weasseMax' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
     */
    {
      id: 'extrapolatedWeasse',
      label: t('common.financial.extrapolatedWeasse.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_person_extrapolatedWeasse' : undefined,
      render: value => currencyFormatter({ value: value as number })
    }
  ];

  const rows = props.data.map((person) => {
    const personGetter = new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') });

    return {
      id: person.entityId,
      name:
      <Link
        href={typeof props.link === 'string' ? props.link : props.link?.(person.entityId as string, 'deecPerson') || `${dnaConfig.routes.persons.relativeUrl}/${person.entityId as string}`}
        sx={{ whiteSpace: 'nowrap', fontSize: '0.9rem' }}
      >
        {person.name}
      </Link>,
      veegilenz: personGetter.getVeegilenzLevel() !== t('common.utils.unknown') ?
        <Stack direction='row' spacing={1} alignItems='center'>
          <Tooltip
            title={
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='body2'>{t(`veegilenz.reason.${personGetter.getVeegilenzReason() || 'noReason'}`)}</Typography>
                <Status
                  statusAsInt={
                    personGetter.getVeegilenzReason() === 'atLeastOneBlackFact' && 3 ||
                      personGetter.getVeegilenzReason() === 'atLeastOneRedFact' && 2 ||
                      personGetter.getVeegilenzReason() === 'atLeastOneOrangeFact' && 1 ||
                      personGetter.getVeegilenzReason() === 'moreThanTreeOrangeFacts' && 1 ||
                      personGetter.getVeegilenzReason() === 'onlyGreenFacts' && 0 ||
                      0
                  }
                  size='1rem'
                />
              </Stack>
            }
            arrow
            placement='top'
          >
            <Box height='1.6rem' width='1.6rem'>
              <Status statusAsInt={personGetter.getVeegilenzLevel(true) as number} size='1.6rem' />
            </Box>
          </Tooltip>
          {personGetter.getVeegilenzCompleteness() < 1
            ? <Completeness completeness={personGetter.getVeegilenzCompleteness()} />
            : null
          }
        </Stack> : t('common.utils.unknown'),
      tags: <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
        {personGetter.getTags().filter(tag => tag.family !== 'batchTag').map(tag =>
          <Tooltip key={`${tag.value}-${tag.family}`} title={tag.family} arrow placement='top'>
            <Chip size='small' label={tag.value} />
          </Tooltip>
        )}
        {personGetter.getIsDead() ?
          <Tooltip
            title={
              <Typography>
                {t('common.utils.deceased')} ({personGetter.getDeathDate()}) <Accuracy accuracy={personGetter.getDeathMatchAccuracy()}/>
              </Typography>
            }
            arrow
            placement='top'
          >
            <Chip
              sx={{
                ml: '-4px !important',
                transform: 'scale(0.8)'
              }}
              color='error'
              label={
                <Stack spacing={1} direction='row' alignItems='center'>
                  <IconGrave
                    size='1rem'
                    color={ theme.palette.error.dark}
                  />
                  <Typography fontSize={15}>{t('common.utils.deceased')}</Typography>
                </Stack>
              }
              size='small'
            />
          </Tooltip> : undefined}
        {personGetter.getSanctions().map(sanction => (
          <Tooltip title={t('tags.otherTags.sanctionType')} arrow placement='top'>
            <Chip
              color='error'
              label={
                <Stack spacing={1} direction='row' alignItems='center'>
                  <IconAlertTriangleFilled
                    size='1rem'
                    color={ theme.palette.error.dark}
                  />
                  <Typography>{t(`veegilenz.sanctionType.${sanction}`)}</Typography>
                </Stack>
              }
              size='small'
            />
          </Tooltip>
        ))
        }
        {personGetter.isAmfRelated()
          ? <Tooltip title={t('amf.chip.tooltip')} arrow placement='top'>
            <Chip
              color='error'
              label={
                <Stack spacing={1} direction='row' alignItems='center'>
                  <IconAlertTriangleFilled
                    size='1rem'
                    color={theme.palette.error.dark}
                  />
                  <Typography>{t('amf.chip.label')}</Typography>
                </Stack>
              }
              size='small'
            />
          </Tooltip>
          : <></>
        }
      </Stack>,
      politicalExposure:
        <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
          {
            personGetter.getPoliticalExposureLevels().map(exposureLevel =>
              <Chip color='error' size='small' label={t(`politicalExposure.values.${exposureLevel}`)} />
            )
          }
        </Stack>,
      nationality:
      <Grid container spacing={1} wrap={personGetter.getNationalities().length > 5 ? 'wrap' : 'nowrap'}>
        {CountriesFlags({ countries: personGetter.getNationalitiesCodes(), size: 'small' })
          .map((flag, index) => (
            <Grid key={`nationality-${flag.key}`}>
              <Tooltip
                title={startCase(personGetter.getNationalities()[index]?.toLowerCase())}
                arrow
                placement='top'
              >
                {flag}
              </Tooltip>
            </Grid>
          ))}
      </Grid>,
      residence:
      <Grid container spacing={1} wrap={personGetter.getCountries().length > 5 ? 'wrap' : 'nowrap'}>
        {CountriesFlags({
          countries: new PersonGetter({
            data: person,
            errorMsg: t('common.utils.unknown')
          }).getCountriesCodes(),
          size: 'small'
        }).map((flag, index) => (
          <Grid key={`country-${flag.key}`}>
            <Tooltip
              title={startCase(personGetter.getCountries()[index]?.toLowerCase())}
              arrow
              placement='top'
            >
              {flag}
            </Tooltip>
          </Grid>
        ))}
      </Grid>,
      nbActiveCompanies: personGetter.getNbActiveCompanies(true),
      nbClosedCompanies: personGetter.getNbInactiveCompanies(true),
      nbTotalCompanies: personGetter.getNbCompanies(true),
      nbActiveRelations: personGetter.getNbCurrentRelations(true),
      nbInactiveRelations: personGetter.getNbOldRelations(true),
      nbTotalRelations: personGetter.getNbRelations(true),
      dismemberment: personGetter.getIsMemberOfDismemberment() ? personGetter.getNbDismemberment(true) : undefined,
      turnoverSum: personGetter.getTurnoverSum(true),
      ebitdaSum: personGetter.getEbitdaSum(true),
      capitalSum: personGetter.getCapitalSum(true),
      valuationSum: personGetter.getValuationSum(true),
      weasse: personGetter.getWeasse(true),
      // weasseMax: personGetter.getWeasseMax(true),
      extrapolatedWeasse: personGetter.getExtrapolatedWeasse(true)
    };
  });

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <EvenOddTable<CurrencyFormatterProps> label='PersonList' totalCount={props.data.length} {...props} entityType='person' columns={columns} rows={rows} disableLocalSort={[...(props.disableLocalSort || []), 'tags']} />
    </Box>
  );
}

export default PersonList;
