import React, { ReactElement, useEffect, useState } from 'react';
import { BatchInterface } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';
import { IconBuildingFactory2 } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import TitleComponent from '@/components/title';
import { BatchesService } from '@/api/services/batches';
import BaseWidget from '@/main/containers/widgets/base-widget';
import KeyValueList, { KeyValue } from '@/components/keyvalue/keyvaluelist';

function BatchesDetailsHeader(): ReactElement {
  const { id } = useParams();
  const batchesServices = new BatchesService();
  const [batchData, setBatchData] = useState<BatchInterface>();

  const batchDatas: KeyValue[] = batchData ? [
    { key: 'Id', value: batchData?._id || '' },
    { key: 'RecordSetId', value: batchData?.recordSetId || '', redirectTo: `/dna/admin/recordSets/${batchData?.recordSetId}`, color: 'info' },
    { key: 'TopStepId', value: batchData?.topStepId || '', redirectTo: `/dna/admin/steps/${batchData?.topStepId}`, color: 'info' }
  ] : [];

  const batchTimingDatas: KeyValue[] = batchData ? [
    { key: 'Status', value: batchData?.status || '' },
    { key: 'Start Time', value: batchData?.startTime?.toString() || '' },
    { key: 'End Time', value: batchData?.endTime?.toString() || 'undefined' }
  ] : [];

  const fetchRecordSetDataFromId = (batchId: string) => {
    batchesServices.get(batchId)
      .then((res) => {
        if (res.data) {
          setBatchData(res.data);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    if (id) {
      fetchRecordSetDataFromId(id);
    }
  }, [id]);

  return (
    <Grid container spacing={2} alignItems='center' width='100%'>
      <Grid container justifyContent='space-between' width='100%' alignItems='center'>
        <Grid>
          <TitleComponent title={`Batch ${batchData?._id}`} icon={IconBuildingFactory2} />
        </Grid>

      </Grid>
      <Stack spacing={2} direction='row'>
        <BaseWidget title='Batch Details'>
          <KeyValueList values={[batchDatas]}/>
        </BaseWidget>

        <BaseWidget title='Batch Processing'>
          <KeyValueList values={[batchTimingDatas]}/>
        </BaseWidget>
      </Stack>

    </Grid>
  );
}

export default BatchesDetailsHeader;