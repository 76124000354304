import React, { ReactElement, useEffect, useState } from 'react';
import { Button, FormControl, Modal, Paper, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DashboardGroupService } from '@/api/services/dashboard.group';

interface CreateFormType {
  label: string | null,
  banqueId: string | null
}

interface BaseGroupModalCreationProps {
  creationModal: boolean,
  setCreationModal: React.Dispatch<React.SetStateAction<boolean>>,
  refreshData: React.Dispatch<React.SetStateAction<boolean>>
}

function BaseGroupModalCreation(props: BaseGroupModalCreationProps): ReactElement {
  const dashboardServices = new DashboardGroupService();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [createForm, setCreateForm] = useState<CreateFormType>({
    label: null,
    banqueId: null
  });

  const handleEditChange = (value: string, propertyKey: string) => {
    setCreateForm(prevState => ({
      ...prevState,
      [propertyKey]: value
    }));
  };

  const handleClose = () => {
    setCreateForm({ label: null, banqueId: null });
    props.setCreationModal(false);
  };

  const createNewBaseGroup = () => {
    if (isReady) {
      dashboardServices.createBaseGroup(createForm)
        .then(() => {
          props.refreshData(true);
          handleClose();
        });
    }
  };

  const determinateIfRecordIsReadyToCreate = (createFormToCheck: CreateFormType) => {
    const allKeysHaveValues = (obj: CreateFormType | ArrayLike<unknown>) =>
      Object.values(obj)
        .every(value =>
          value !== null && value !== undefined && value !== ''
        );
    const isPersonFormValid = allKeysHaveValues(createFormToCheck);
  
    if (isPersonFormValid) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  };

  useEffect(() => {
    determinateIfRecordIsReadyToCreate(createForm);
  }, [createForm]);

  return (
    <Modal
      open={props.creationModal}
      onClose={handleClose}
    >
      <Paper
        sx={{
          width: 500,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4
        }}
      >
        <Stack spacing={4}>
          <Typography variant='h3'>New BaseGroup</Typography>
          {
            Object.entries(createForm).map(([key, value]) => (
              <FormControl key={key} fullWidth>
                <TextField
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  variant='outlined'
                  value={value}
                  onChange={e => handleEditChange(e.target.value, key)}
                />
              </FormControl>
            ))
          }
          <Stack direction='row' width='100%' justifyContent='flex-end' spacing={4}>
            <Button variant='text' onClick={handleClose} color='error'>Cancel</Button>
            <Button variant='contained' onClick={createNewBaseGroup} disabled={!isReady}>Create BaseGroup</Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default BaseGroupModalCreation;