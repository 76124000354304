import { ApiResponse, ApiArrayResponse } from '@deecision/deecision-interfaces';
import axiosInstance from '../axios/instance.axios';
import { BaseService } from './base.api';

export class AdminService extends BaseService{
  constructor() {
    super({ axiosInstance, elementPrefixUrl: 'admin' });
  };

  get(id?: string): Promise<ApiResponse<unknown> | ApiArrayResponse<unknown>> {
    throw new Error(`Method not implemented. ${id}`);
  }

  getAll(props?: unknown): Promise<ApiResponse<unknown> | ApiArrayResponse<unknown>> {
    throw new Error(`Method not implemented.${props} `);
  }

  async refreshConfig() {
    await this.axiosInstance.get(this.constructUrl(`/refreshConfig`));
  }

  async deleteAllData(){
    await this.axiosInstance.get(this.constructUrl(`/deleteAllData`));
  }
}