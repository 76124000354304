import React from 'react';
import {
  CompanyEntity,
  EntityLinksAggregation,
  PersonEntity,
  EntityLink,
  AnyEntity
} from '@deecision/dna-interfaces';
import { cardHeight, EdgeType, labelEdgeColor, NodeType } from '../../types';
import { calculateLowestYAndLengthAtX } from '../../utils';
import { SimpleSort } from '@/components/sort';
import { SettingsProps } from '@/components/filters/simple/switch';

export interface DataType {
  source?: 'Right' | 'Left',
  target?: 'Right' | 'Left',
  label: string,
  entity: EntityLinksAggregation,
  sortList: React.MutableRefObject<SimpleSort[]>,
  nodes: React.MutableRefObject<NodeType[]>,
  settingsList: React.MutableRefObject<SettingsProps[]>,
  isAdvisor: boolean,
  isListedCompany: boolean,
  cardData: AnyEntity,
  cardLinks: EntityLinksAggregation[]
}

export const deployHandling = (
  data: NodeType['data'],
  relatedPersons: EntityLinksAggregation[],
  shareholderTotalPercentage: number,
  lastDeployAction: 'parent' | 'children',
  parsedDuplicateCompanies: EntityLinksAggregation[],
  parentNodes: NodeType,
  entityLinks: {
    entityId: string,
    results: (EntityLinksAggregation | boolean)[]
  }[],
  personsData?: PersonEntity[],
  companiesData?: CompanyEntity[]
) => {
  let shaTotalPercentage = shareholderTotalPercentage;
  const numberOfNewPersonNode = (relatedPersons || []).filter((person: EntityLinksAggregation) => {
    const isParent = person.links[0].type === 'has.representative' || person.links[0].type === 'has.shareholder';
    const AlreadyExistingNode = data.nodes?.current.find(n => n.data.entity?.entity2?.entityId === person.entity2.entityId);

    return !(AlreadyExistingNode || ((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)));
  }).length;
  const numberOfNewCompaniesNode = (parsedDuplicateCompanies || []).filter((company: EntityLinksAggregation) => {
    const isParent = company.links[0].type === 'has.representative';
    const AlreadyExistingNode = data.nodes?.current.find(n => n.data.entity?.entity2?.entityId === company.entity2.entityId);

    return !(AlreadyExistingNode || ((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)));
  }).length;
  /// SETTING NEW NODES
  data?.setNodes?.((previousState) => {
    const offsetXParentChildren = 800;
    const offsetYBetweenChildrenBranches = 75;
    const parentNode = previousState?.find(n => n?.id === data.id);
    const upwardDirection = (parentNode?.position?.y || 0) <= (parentNode?.data.entityBasePoint?.y || 0);
    let customPersonIndex = -1;
    let customCompanyIndex = -1;

    const defaultDataProperties = {
      ...data,
      parentNode,
      branchNodeIds: parentNode?.data.branchNodeIds ? parentNode.data.branchNodeIds.concat(parentNode.id || '') : [parentNode?.id || ''],
      companyLinkedNodes: parentNode?.data.companyLinkedNodes ? parentNode.data.companyLinkedNodes.concat(parentNode.data.label || '') : [ parentNode?.data.label || '']
      // entityData: companyGetter
    };
    /// SETTING NEW PERSON
    const childrenPersonsNewNodes = (relatedPersons || []).map((person: EntityLinksAggregation) => {
      const isParent = person.links[0].type === 'has.representative' || person.links[0].type === 'has.shareholder';
      const AlreadyExistingNode = data.nodes?.current.find(n => n.data.label === person.entity2.name);
      const existingNodeAtX = calculateLowestYAndLengthAtX(previousState, parentNode, offsetXParentChildren);
      const existingNodeAtXParent = calculateLowestYAndLengthAtX(previousState, parentNode, -offsetXParentChildren);
      const startingYUpwardPersonsPoint = (isParent ? existingNodeAtXParent : existingNodeAtX).lowestYPosition < (parentNode?.position?.y || 0) ? (isParent ? existingNodeAtXParent : existingNodeAtX).lowestYPosition - offsetYBetweenChildrenBranches : (parentNode?.position?.y || 0);
      const startingYDownwardPersonsPoint = (isParent ? existingNodeAtXParent : existingNodeAtX).highestYPosition > (parentNode?.position?.y || 0) ? (isParent ? existingNodeAtXParent : existingNodeAtX).highestYPosition + offsetYBetweenChildrenBranches * 2 : (parentNode?.position?.y || 0) + offsetYBetweenChildrenBranches;

      const yUpwardPosition = startingYUpwardPersonsPoint - (numberOfNewCompaniesNode * cardHeight) - (numberOfNewPersonNode * cardHeight) + (customPersonIndex * cardHeight) + cardHeight;
      const yDownwardPosition = startingYDownwardPersonsPoint + cardHeight + (customPersonIndex * cardHeight);

      if (isParent &&( person.links[0].type === 'has.shareholder' || person.links[0].details?.positionTypes?.includes('sha'))) {
        let highestSharePercentage = 0;

        person.links.forEach((link: EntityLink) => {
          if ((link.details?.sharesPercentage || 0) > highestSharePercentage) {
            highestSharePercentage = link.details?.sharesPercentage || 0;
          }
        });
        shaTotalPercentage += highestSharePercentage;
      }
      if (AlreadyExistingNode || (((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)))) return null;
      customPersonIndex += 1;

      return ({
        id: String(customPersonIndex + (previousState?.length || 0) + 1),
        type: 'personEntity' as const,
        position: {
          x: (parentNode?.position?.x || 0) + (isParent ? -offsetXParentChildren : offsetXParentChildren),
          y: upwardDirection ? yUpwardPosition : yDownwardPosition
        },
        nodeFrom: parentNode?.id,
        nodeFromType: lastDeployAction,
        data: {
          ...defaultDataProperties,
          hidden: data.filtersId?.includes('parent') ? data.filters?.current.find((filter: { id: string }) => filter.id === 'parent')?.active : false,
          label: person.entity2.name || '' as string,
          entity: person,
          source: 'Right' as const,
          target: 'Left' as const,
          category: 'person',
          cardData: personsData?.find(relatedPerson => relatedPerson.entityId === person.entity2?.entityId),
          filtersId: [
            ...(data.filtersId || []),
            (person.links[0].type === 'has.shareholder' || person.links[0].details?.positionTypes?.includes('sha') ? 'shareholders' : null),
            (person.links[0].details?.positionTypes?.includes('exe') ? 'executive' : null)
          ].filter(Boolean)
        }
      });
    }).filter(Boolean);

    /// SETTING NEW COMPANIES
    const childrenCompaniesNewNodes = (parsedDuplicateCompanies || []).map((company) => { // EntityLinksAggregation but there is no positionTypes in role
      const isParent = company.links[0].type === 'has.representative';
      const isAdv = company.links[0].details?.positionTypes?.includes('adv');
      const AlreadyExistingNode = data.nodes?.current.find(n => n.data.entity?.entity2?.entityId === company.entity2.entityId);
      const existingNodeAtX = calculateLowestYAndLengthAtX(previousState, parentNode, offsetXParentChildren);
      const existingNodeAtXParent = calculateLowestYAndLengthAtX(previousState, parentNode, -offsetXParentChildren);
      const startingYUpwardCompaniesPoint = (isParent ? existingNodeAtXParent : existingNodeAtX).lowestYPosition < (parentNode?.position?.y || 0) ?
        (isParent ? existingNodeAtXParent : existingNodeAtX).lowestYPosition - offsetYBetweenChildrenBranches
        : (parentNode?.position?.y || 0);
      const startingYDownwardCompaniesPoint = (isParent ? existingNodeAtXParent : existingNodeAtX).lowestYPosition > (parentNode?.position?.y || 0) ||
      (isParent ? existingNodeAtXParent : existingNodeAtX).highestYPosition > (parentNode?.position?.y || 0) ?
        (isParent ? existingNodeAtXParent : existingNodeAtX).highestYPosition + 2 * offsetYBetweenChildrenBranches
        : (parentNode?.position?.y || 0) + offsetYBetweenChildrenBranches;

      if (isParent && company.links[0].type === 'has.shareholder') {
        let highestSharePercentage = 0;

        company.links.forEach((link: EntityLink) => {
          if ((link.details?.sharesPercentage || 0) > highestSharePercentage) {
            highestSharePercentage = link.details?.sharesPercentage || 0;
          }
        });
        shaTotalPercentage += highestSharePercentage;
      }

      if (AlreadyExistingNode || (((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)))) return null;
      customCompanyIndex += 1;
      const yUpwardPosition = startingYUpwardCompaniesPoint - (numberOfNewCompaniesNode * cardHeight) + ((customCompanyIndex) * cardHeight);
      const yDownwardPosition = startingYDownwardCompaniesPoint + (numberOfNewPersonNode * cardHeight) + ((customCompanyIndex * cardHeight));

      return ({
        id: String(customCompanyIndex + childrenPersonsNewNodes.length + previousState.length + 1),
        type: 'companyEntity' as const,
        position: {
          x: (parentNode?.position?.x || 0) + (isParent ? -offsetXParentChildren : offsetXParentChildren),
          y: upwardDirection ? yUpwardPosition : yDownwardPosition
        },
        nodeFrom: parentNode?.id,
        nodeFromType: lastDeployAction,
        hidden: (data.filtersId?.length || 0) > 0 ? data.filters?.current.find((filter: { id: string }) => filter.id === (isAdv ? 'advisors' : isParent ? 'parent' : 'children'))?.active : false,
        data: {
          ...defaultDataProperties,
          isAdvisor: isAdv && isParent,
          label: company.entity2.name || '',
          entity: company,
          source: 'Right' as const,
          target: 'Left' as const,
          category: 'company',
          isListedCompany: company.entity1Object?.[0]?.tags?.some((elem: { value: string }) => elem.value === 'listedCompany') || false,
          cardData: companiesData?.find(relatedCompany => relatedCompany.entityId === company.entity2?.entityId),
          cardLinks: entityLinks.find(link => link.entityId === company.entity2.entityId)?.results,
          filtersId: [
            ...(data.filtersId || []),
            isAdv ? 'advisors' : null,
            (company.links[0].type === 'has.shareholder' || company.links[0].details?.positionTypes?.includes('sha') ? 'shareholders' : null),
            (company.links[0].details?.positionTypes?.includes('exe') ? 'executive' : null)
          ].filter(Boolean)
        }
      });
    }).filter(Boolean);

    const updatedNodes = previousState.map((node) => {
      if (node?.id === data.id) {
        return {
          ...node, data: { ...node?.data, deploy: true }
        };
      }

      return node;
    });

    // SETTING WARNING SHA NODES
    const existingNodeAtXParent = calculateLowestYAndLengthAtX(previousState, parentNode, -offsetXParentChildren);
    const startingYUpwardCompaniesPoint = existingNodeAtXParent.lowestYPosition < (parentNode?.position?.y || 0) ? (existingNodeAtXParent.lowestYPosition - offsetYBetweenChildrenBranches) : (parentNode?.position?.y || 0);
    const startingYDownwardCompaniesPoint = existingNodeAtXParent.highestYPosition > (parentNode?.position?.y || 0) ? (existingNodeAtXParent.highestYPosition + (2 * offsetYBetweenChildrenBranches)) : ((parentNode?.position?.y || 0) + offsetYBetweenChildrenBranches);
    const yUpwardPosition = startingYUpwardCompaniesPoint - (numberOfNewPersonNode * cardHeight) - (numberOfNewCompaniesNode * cardHeight) - cardHeight;
    const yDownwardPosition = startingYDownwardCompaniesPoint + (numberOfNewPersonNode * cardHeight) + (numberOfNewCompaniesNode * cardHeight);
    let highestSharePercentage = -1;

    relatedPersons.forEach((person) => {
      person.links.forEach((link: EntityLink) => {
        if ((link.details?.sharesPercentage || 0) > highestSharePercentage && link.type === 'has.shareholder' || link.details?.positionTypes?.includes('sha')) {
          highestSharePercentage = link.details?.sharesPercentage || 0;
        }
      });
    });
    const warningShaNode = (lastDeployAction === 'parent' && shaTotalPercentage < 99.5 && (highestSharePercentage === -1 || highestSharePercentage > 0.5)) ?
      {
        id: String(previousState.length + numberOfNewPersonNode + numberOfNewCompaniesNode + 1),
        type: 'warningShaParent' as const,
        position: {
          x: (parentNode?.position?.x || 0) - offsetXParentChildren,
          y: upwardDirection ? yUpwardPosition : yDownwardPosition
        },
        nodeFrom: parentNode?.id,
        nodeFromType: lastDeployAction,
        data: {
          ...defaultDataProperties,
          shareholderTotalPercentage: shaTotalPercentage,
          source: 'Right' as const,
          target: 'Left' as const
        },
        category: 'company'
      }
      : null;

    return [...updatedNodes, ...childrenPersonsNewNodes, ...childrenCompaniesNewNodes, warningShaNode].filter(Boolean) as NodeType[];
  });

  /// SETTING NEW EDGES
  data.setEdges?.((previousState) => {
    let customPersonIndex = -1;
    let customCompanyIndex = -1;

    const newPersonEdges = (relatedPersons ?? []).map((person: EntityLinksAggregation, index: number) => {
      const isParent = person.links[0].type === 'has.representative' || person.links[0].type === 'has.shareholder';
      const AlreadyExistingNode = data.nodes?.current.find(n => n.data.entity?.entity2?.entityId === person.entity2.entityId);
      const defaultEdgeProperty = {
        id: `e${isParent ? String(index + (data.nodes?.current.length || 0) + 1) : parentNodes.id}-${isParent ? parentNodes.id : String(index + (data.nodes?.current.length || 0) + 1)}`,
        type: 'custom' as const,
        edgeFrom: parentNodes.id,
        edgeFromType: lastDeployAction,
        label: person.links[0]?.details?.positionTypes || [],
        branchNodeIds: parentNodes.data.branchNodeIds ? parentNodes.data.branchNodeIds.concat(parentNodes.id || '') : [parentNodes.id || ''],
        style: {
          strokeWidth: 3
        }
      };
      let actualSharePercentage = -1;

      person.links.forEach((link: EntityLink) => {
        if ((link.details?.sharesPercentage || 0) > actualSharePercentage) {
          actualSharePercentage = link.details?.sharesPercentage || 0;
        }
      });

      if (AlreadyExistingNode) {

        return (
          {
            ...defaultEdgeProperty,
            source: isParent ? AlreadyExistingNode.id : parentNodes.id,
            target: isParent ? parentNodes.id : AlreadyExistingNode.id,
            data: {
              display: 'right',
              parentOrChildrenDirection: lastDeployAction,
              sourceType: parentNodes.data.entity?.entity2.entityType.includes('Person') ? 'person' : 'company',
              targetType: person.entity2.entityType.includes('Person') ? 'person' : 'company',
              arrowAt: parentNodes.data.source === 'Left' && isParent ? 'start' as const : 'end' as const,
              sharesPercentage: actualSharePercentage,
              sharesPercentageWarningLabel: !(actualSharePercentage || (actualSharePercentage && actualSharePercentage < 0.5 && shaTotalPercentage > 99.5)),
              labelEdgeColor:
              person.links[0]?.details?.positionTypes?.includes('sha') && person.links[0]?.details?.positionTypes?.includes('exe') ?
                labelEdgeColor.both :
                person.links[0]?.details?.positionTypes?.includes('sha') ?
                  labelEdgeColor.sha : person.links[0]?.details?.positionTypes?.includes('exe') ?
                    labelEdgeColor.exe : undefined
            },
            style: {
              strokeWidth: 3
            }
          }
        );
      }
      if (AlreadyExistingNode || (((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)))) {
        return null;
      }
      customPersonIndex += 1;

      return ({
        ...defaultEdgeProperty,
        source: isParent ? String(customPersonIndex + ((data.nodes?.current.length || 0) || 0) + 1) : parentNodes.id,
        target: isParent ? parentNodes.id : String(customPersonIndex + ((data.nodes?.current.length || 0) || 0) + 1),
        data: {
          mainEntity: previousState[0]?.data?.mainEntity,
          display: 'right',
          parentOrChildrenDirection: lastDeployAction,
          arrowAt: parentNodes.data.source === 'Left' && isParent ? 'start' as const : 'end' as const,
          sharesPercentage: actualSharePercentage,
          sharesPercentageWarningLabel: !(actualSharePercentage || (actualSharePercentage && actualSharePercentage < 0.5 && shaTotalPercentage > 99.5)),
          labelEdgeColor:
              person.links[0]?.details?.positionTypes?.includes('sha') && person.links[0]?.details?.positionTypes?.includes('exe') ?
                labelEdgeColor.both :
                person.links[0]?.details?.positionTypes?.includes('sha') ?
                  labelEdgeColor.sha : person.links[0]?.details?.positionTypes?.includes('exe') ?
                    labelEdgeColor.exe : undefined
        },
        edgeFromType: lastDeployAction,
        branchNodeIds: parentNodes.data.branchNodeIds ? parentNodes.data.branchNodeIds.concat(parentNodes.id || '') : [parentNodes.id]
      });
    });

    const newCompanyEdges = (parsedDuplicateCompanies ?? []).map((company) => {
      const isParent = company.links[0].type === 'has.representative';
      const AlreadyExistingNode = data.nodes?.current.find(n => n.data.entity?.entity2?.entityId === company.entity2.entityId);
      const defaultEdgeProperty = {
        type: 'custom' as const,
        edgeFrom: parentNodes.id,
        edgeFromType: lastDeployAction,
        label: company.links[0]?.details?.positionTypes || [],
        branchNodeIds: parentNodes.data.branchNodeIds ? parentNodes.data.branchNodeIds.concat(parentNodes.id || '') : [parentNodes.id],
        data: {
          entity: parentNodes.data.entity,
          display: 'right',
          parentOrChildrenDirection: lastDeployAction,
          sourceType: parentNodes.data.entity?.entity2.entityType.includes('Person') ? 'person' : 'company',
          targetType: company.entity2.entityType.includes('Person') ? 'person' : 'company',
          arrowAt: parentNodes.data.source === 'Left' && isParent ? 'start' as const : 'end' as const,
          labelEdgeColor:
              company.links[0]?.details?.positionTypes?.includes('sha') && company.links[0]?.details?.positionTypes?.includes('exe') ?
                labelEdgeColor.both :
                company.links[0]?.details?.positionTypes?.includes('sha') ?
                  labelEdgeColor.sha : company.links[0]?.details?.positionTypes?.includes('exe') ?
                    labelEdgeColor.exe : undefined
        },
        style: {
          strokeWidth: 3
        }
      };

      if (AlreadyExistingNode && ((lastDeployAction === 'children' && !isParent) || (lastDeployAction === 'parent' && isParent))) {

        return (
          {
            id: `e${isParent ? AlreadyExistingNode.id : parentNodes.id}-${isParent ? parentNodes.id : AlreadyExistingNode.id}`,
            source: isParent ? AlreadyExistingNode.id : parentNodes.id,
            target: isParent ? parentNodes.id : AlreadyExistingNode.id,
            ...defaultEdgeProperty,
            data: {
              ...defaultEdgeProperty.data,
              arrowAt: parentNodes.data.source === 'Left' ? 'start' as const : 'end' as const
            }
          }
        );
      }

      if (AlreadyExistingNode || (((lastDeployAction === 'children' && isParent) || (lastDeployAction === 'parent' && !isParent)))) {
        return null;
      }
      customCompanyIndex += 1;

      return ({
        id: `e${isParent ? String(customCompanyIndex + (data.nodes?.current.length || 0) + 1 + (numberOfNewPersonNode ?? 0)) : parentNodes.id}-${isParent ? parentNodes.id : String(customCompanyIndex + (data.nodes?.current.length || 0) + 1 + (numberOfNewPersonNode ?? 0))}`,
        source: isParent ? String(customCompanyIndex + (data.nodes?.current.length || 0) + 1 + (numberOfNewPersonNode ?? 0)) : parentNodes.id,
        target: isParent ? parentNodes.id : String(customCompanyIndex + (data.nodes?.current.length || 0) + 1 + (numberOfNewPersonNode ?? 0)),
        ...defaultEdgeProperty
      });
    }).filter(Boolean);

    let highestSharePercentage = -1;

    relatedPersons.forEach((person) => {
      person.links.forEach((link: EntityLink) => {
        if ((link.details?.sharesPercentage || 0) > highestSharePercentage && link.type === 'has.shareholder' || link.details?.positionTypes?.includes('sha')) {
          highestSharePercentage = link.details?.sharesPercentage || 0;
        }
      });
    });
    const warningShaEdge = (shaTotalPercentage < 99.5 && lastDeployAction === 'parent' && (highestSharePercentage === -1 || highestSharePercentage > 0.5)) ? {
      id: (`e${(data.nodes?.current.length || 0) + numberOfNewPersonNode + numberOfNewCompaniesNode + 1}-${parentNodes.id}`),
      type: 'custom' as const,
      source: String((data.nodes?.current.length || 0) + numberOfNewPersonNode + numberOfNewCompaniesNode + 1),
      target: parentNodes.id,
      edgeFrom: parentNodes.id,
      edgeFromType: lastDeployAction,
      data: {
        arrowAt: 'end' as const
      },
      branchNodeIds: parentNodes.data.branchNodeIds ? parentNodes.data.branchNodeIds.concat(parentNodes.id || '') : [parentNodes.id],
      style: {
        strokeWidth: 3
      }
    } : null;

    return ([...previousState, ...newPersonEdges, ...newCompanyEdges, warningShaEdge].filter(Boolean)) as EdgeType[];
  });
};

export const undeployHandling = (deployParentRelation: boolean | undefined, deployChildrenRelation: boolean | undefined, lastDeployAction: 'parent' | 'children', data: NodeType['data'], parentNodes: NodeType, setDeployParentRelation: React.Dispatch<React.SetStateAction<boolean | undefined>>, setDeployChildrenRelation: React.Dispatch<React.SetStateAction<boolean | undefined>>) => {
  const deletedNodeId: string[] = [];

  data.setNodes?.(previousState => previousState.map((node) => {
    // This condition should be able to work but exclude parent if we are undeploying children

    if (node?.nodeFrom === data.id &&
        ((deployParentRelation === false) || (deployChildrenRelation === false)) &&
        node?.nodeFromType === lastDeployAction) {
      return null;
    }

    // Find a way to exclude parent or children with lastDeployAction if we are undeploying children and that is from node.nodeFrom the data.id
    if (node && node.id && data.id && node.data.branchNodeIds?.includes(data.id) && node.nodeFrom !== data.id) {
      deletedNodeId.push(node.id);

      return null;
    }

    // Add a condition to check if parents and children are undeployed
    return node?.id === data.id && ((deployChildrenRelation === undefined && deployParentRelation === false) || (deployChildrenRelation === false && deployParentRelation === undefined))? {
      ...node,
      data: {
        ...node?.data,
        deploy: false
      }
    } : node;
  }).filter(Boolean) as NodeType[]);
  data.setEdges?.(previousState =>
    previousState.filter(edge => !((edge?.edgeFrom && deletedNodeId.includes(edge.edgeFrom)) ||
        (((data.id && edge?.branchNodeIds?.includes(data.id) || edge?.edgeFrom === parentNodes.id) && edge?.edgeFromType === lastDeployAction))))
  );
  lastDeployAction === 'parent' ?
    setDeployParentRelation(undefined) :
    setDeployChildrenRelation(undefined);
};
