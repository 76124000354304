import { ApiResponse, ApiArrayResponse } from '@deecision/deecision-interfaces';
import { BaseGroup } from '@deecision/dna-interfaces';
import axiosInstance from '../axios/instance.axios';
import { BaseService } from './base.api';

export class DashboardGroupService extends BaseService{
  constructor() {
    super({ axiosInstance, elementPrefixUrl: 'dashboard-groups' });
  };

  get(id?: string): Promise<ApiResponse<BaseGroup>> {
    throw new Error(`Method not implemented. ${id}`);
  }

  getAll(): Promise<ApiArrayResponse<BaseGroup>> {
    return this.axiosInstance.get(this.constructUrl(`/`))
      .then(res => this.handleArrayResponse<BaseGroup>(res));
  }

  delete(id: string): Promise<ApiResponse<void>> {
    return this.axiosInstance.delete(this.constructUrl(`/${id}`));
  }

  put(id: string, body: { label: string | null, banqueId: string | null }): Promise<ApiResponse<BaseGroup>> {
    return this.axiosInstance.put(this.constructUrl(`/${id}`), body)
      .then(res => this.handleResponse<BaseGroup>(res));
  }

  createBaseGroup(body: { label: string | null, banqueId: string | null }): Promise<ApiResponse<BaseGroup>> {
    return this.axiosInstance.post(this.constructUrl(`/`), body)
      .then (res => this.handleResponse<BaseGroup>(res));
  }

  async clearAllGroups() {
    await this.axiosInstance.get(this.constructUrl(`/actions/clearAllGroups`));
  }

  async buildAllGroups(){
    await this.axiosInstance.get(this.constructUrl(`/actions/buildAllGroupDescs`));
  }
  
  async refreshAllGroups() {
    await this.axiosInstance.get(this.constructUrl(`/actions/refreshAllGroups/`));
  }
}