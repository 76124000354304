import React, { ReactElement, useRef } from 'react';
import { Outlet, useLoaderData, useLocation, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { IconScreenshot } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import CustomTabs, { TabsComponentsProps } from '../layouts/tabs';
import { CustomRouteObject } from '@/router/types';
import captureScreenshot from '@/utils/screenshot';
import Can from '@/auth/can';
import { ErrorPage } from '@/components/error';
import { WEBAPP_NAME } from '@/env/env';
import { CanProps } from '@/auth/types';

export interface DataOutletProps extends Omit<TabsComponentsProps, 'tabs' | 'params'>, Pick<CanProps, 'asA'> {
  layout?: ReactElement,
  tabs?: CustomRouteObject[],
  childrens?: CustomRouteObject[],
  idChildrens?: CustomRouteObject[],
  wrapper?: (props: { children: ReactElement }) => React.JSX.Element
}

function Wrapper(props: Pick<DataOutletProps, 'wrapper'> & { children: ReactElement }): ReactElement {
  return props.wrapper ? props.wrapper({ children: props.children }) : props.children;
}

function DataOutlet(props: DataOutletProps): ReactElement {
  const data = useLoaderData();
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const ref = useRef(null);
  const UnauthorizedPage = (
    <ErrorPage
      app={WEBAPP_NAME}
      errMessage='Unauthorized'
      errTitle='403'
    />
  );

  if (!props.layout && !props.tabs) {
    return (
      <Can {...props} fallback={UnauthorizedPage} passIfNoRole>
        <Outlet context={data} />
      </Can>
    );
  }

  return (
    <Wrapper {...props}>
      <Can {...props} fallback={UnauthorizedPage} passIfNoRole>
        <Stack spacing={2}>
          {props.layout}
          {props.tabs && !props.idChildrens?.find(idChildren => (idChildren.path && location.pathname.includes(idChildren.path.replaceAll(/\/:[a-zA-Z]+\?/g, '')))) && !props.childrens?.find(children => (children.path && location.pathname.includes(children.path.replaceAll(/\/:[a-zA-Z]+\?/g, '')))) &&
            <CustomTabs
              tabs={props.tabs}
              params={params}
              actions={
                <Tooltip title={t('common.utils.captureScreenshot')} placement='top' arrow>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      captureScreenshot(ref);
                    }}
                    disableTouchRipple
                    size='small'
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    <IconScreenshot size='1.2rem' />
                  </IconButton>
                </Tooltip>
              }
              {...props}
            />
          }
          <div ref={ref}>
            <Outlet context={data} />
          </div>
        </Stack>
      </Can>
    </Wrapper>
  );
}

export default DataOutlet;
