import { EntityLinksAggregation } from '@deecision/dna-interfaces';
import { NodeType } from './types';

export const calculateLowestYAndLengthAtX = (previousState: (NodeType | null)[], parentNode: NodeType | null | undefined, offsetXParentChildren: number) => {
  let lowestYPosition = 0;
  let highestYPosition = 0;
  let nodeCount = 0;
  let initialized = false;

  previousState?.forEach((node) => {
    if (node?.position?.x === (parentNode?.position?.x || 0) + offsetXParentChildren) {
      nodeCount += 1;
      if (node.position.y < lowestYPosition) {
        lowestYPosition = node.position.y;
        initialized = true;
      }
      if (node.position.y > highestYPosition) {
        highestYPosition = node.position.y;
      }
    }
  });
  if (lowestYPosition === 0 && !initialized) lowestYPosition = (parentNode?.position?.y || 0);

  return ({ lowestYPosition, highestYPosition, nodeCount });
};

export const countNodes = (nodes: EntityLinksAggregation[]) =>
  nodes.reduce((count, node) => ((node.links[0].type === 'has.representative' || node.links[0].type === 'has.shareholder')
    ? count + 1
    : count), 0);

export const getHighestPercentageShare = (elements: EntityLinksAggregation) => {
  let actualSharePercentage = -1;

  elements.links.forEach((link) => {
    const sharesPercentage = link.details?.sharesPercentage;

    if (sharesPercentage !== undefined && sharesPercentage > actualSharePercentage) {
      actualSharePercentage = link.details?.sharesPercentage || 0;
    }
  });

  return (actualSharePercentage);
};

export const getTotalPercentageShare = (elementsPerson: EntityLinksAggregation[], elementsCompanies: EntityLinksAggregation[]) => {
  let shareholderTotalPercentage = 0;

  elementsPerson.forEach((person) => {
    // if (( person.relations[0].type === 'has.shareholder' || person.relations[0].details?.positionTypes?.includes('sha'))) {
    shareholderTotalPercentage += getHighestPercentageShare(person);
    // }
  });

  elementsCompanies.forEach((company) => {
    const isParent = company.links[0].type === 'has.representative' || company.links[0].type === 'has.shareholder';
    if (isParent && company.links[0].type === 'has.shareholder') {
      shareholderTotalPercentage += getHighestPercentageShare(company);
    }
  });

  return (shareholderTotalPercentage);
};
