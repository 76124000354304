import React, { ReactElement, useRef, useState } from 'react';
import { Card, CardActionArea, CardContent, CardHeader, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { CardHeaderOwnProps } from '@mui/material/CardHeader';
import { useTheme } from '@mui/material/styles';
import { IconScreenshot } from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { baseChartWidth, getBaseChartHeight } from '@/components/charts/utils';
import captureScreenshot from '@/utils/screenshot';
import useWindowDimensions from '@/hooks/window-dimensions';

export interface BaseWidgetProp extends CardHeaderOwnProps {
  ref?: React.RefObject<HTMLDivElement>,
  link?: string,
  maxWidth?: number,
  fullHeight?: boolean,
  fullWidth?: boolean,
  chart?: boolean,
  size?: 'large' | 'medium' | 'small',
  bgColor?: string,
  disabled?: boolean,
  disableRipple?: boolean,
  disableTouchRipple?: boolean,
  children?: ReactElement
}

function BaseWidget(props: BaseWidgetProp): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const widgetRef = useRef(null);
  const [hovered, setHovered] = useState(false);
  const { height } = useWindowDimensions();

  const screenCaptureButton = !props.disabled ? (
    <Box pl={1} pr={1}>
      <Tooltip title={t('common.utils.captureScreenshot')} placement='top' arrow>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            captureScreenshot(props.ref || widgetRef);
          }}
          disableTouchRipple
          size='small'
          sx={{ color: hovered ? theme.palette.text.secondary : 'transparent' }}
        >
          <IconScreenshot size='1.2rem' />
        </IconButton>
      </Tooltip>
    </Box>
  ) : null;

  return (
    <Card
      ref={props.ref || widgetRef}
      onClick={() => (props.link && !props.disabled ? navigate(props.link) : undefined)}
      sx={{
        ...props.sx,
        p: 0,
        alignItems: 'center',
        cursor: props.link && !props.disabled ? 'pointer' : '',
        display: 'flex',
        height: props.fullHeight ? '100%' : undefined,
        width: props.fullWidth ? '100%' : undefined,
        maxWidth: props.maxWidth,
        minWidth: 'min-content',
        bgcolor: props.bgColor || (props.disabled ? theme.palette.grey[100] : undefined),
        borderColor: props.disabled ? theme.palette.grey[200] : undefined,
        overflow: 'visible'
      }}
    >
      {props.link && !props.disabled ?
        <CardActionArea
          sx={{
            height: '100%',
            overflow: 'visible',
            minWidth: 'min-content'
          }}
          disableRipple={props.disableRipple}
          disableTouchRipple={props.disableTouchRipple}
        >
          <Box
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            sx={{
              display: 'grid',
              gridTemplateRows: 'min-content auto',
              alignItems: 'flex-start',
              height: '100%',
              width: '100%'
            }}
          >
            {props.title
              ? <CardHeader
                {...props}
                sx={{ p: typeof props.title === 'string' ? 2 : undefined }}
                action={
                  <Stack direction='row' alignItems='center' spacing={2}>
                    {screenCaptureButton}
                    {props.action}
                  </Stack>
                }
              />
              : null
            }
            <CardContent
              sx={{
                p: 2,
                pb: '8px !important',
                height: '100%',
                overflow: 'visible',
                minWidth: props.chart ? props.size === 'small' ? baseChartWidth / 1.4 : baseChartWidth : 'min-content',
                minHeight: props.chart ? getBaseChartHeight(height) / 1.4 : undefined
              }}
            >
              {props.children}
            </CardContent>
          </Box>
        </CardActionArea> :
        <Box
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          sx={{
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            alignItems: 'flex-start',
            height: '100%',
            width: '100%',
            overflow: 'visible',
            minWidth: 'min-content'
          }}
        >
          {props.title
            ? <CardHeader
              {...props}
              sx={{ p: typeof props.title === 'string' ? 2 : undefined }}
              action={
                <Stack direction='row' alignItems='center' spacing={2}>
                  {screenCaptureButton}
                  {props.action}
                </Stack>
              }
            />
            : null
          }
          {!props.disabled ?
            <CardContent
              sx={{
                p: 2,
                pb: '8px !important',
                height: '100%',
                minWidth: props.chart ? props.size === 'small' ? baseChartWidth / 1.4 : baseChartWidth : 'min-content',
                minHeight: props.chart ? getBaseChartHeight(height) / 1.4 : undefined,
                overflow: 'visible'
              }}
            >
              {props.children}
            </CardContent> :
            null
          }
        </Box>
      }
    </Card>
  );
}

export default BaseWidget;
