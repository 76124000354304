import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid2';
import {
  SegmentationData,
  SegmentationPossibleDisplayTypes, SegmentationPossibleFilter,
  SegmentationPossibleOutputEntityTypes
} from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { IconAlertTriangle, IconDeviceFloppy, IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useLocation } from 'react-router-dom';
import { Box, Chip } from '@mui/material';
import DispatchChartsSegmentations, { ChartDispatchSegmentationsProps } from '../charts/dispatch';
import {
  CustomSegmentationData,
  PotentialSegmentationCriteria
} from '../../types';
import { SegmentationContext } from '../../wrapper';
import CriteriasBuilderSegmentation from '../../builder/criterias';
import InheritedCriterias from './inherited';
import { possibleCriteriaTypes, segmentationPossibleWorkflows } from '../../builder/workflows';
import { baseChartWidth, getBaseChartHeight } from '@/components/charts/utils';
import { useContainerRef } from '@/hooks/container';
import { CustomSegmentationFiltersService } from '@/main/containers/segmentations/services';
import useWindowDimensions from '@/hooks/window-dimensions';

interface RenderSegmentationCriteriasProps extends Omit<ChartDispatchSegmentationsProps, 'criteria' | 'totalCount'> {
  getCriterias: (outputEntitiesTmp?: SegmentationPossibleOutputEntityTypes) => PotentialSegmentationCriteria[],
  setCustomSegmentationData: (segmentationDataTmp: CustomSegmentationData) => void,
  updateSegmentation: () => void,
  outputEntities: SegmentationPossibleOutputEntityTypes,
  dataSetId: CustomSegmentationData['possibleDataSetIds'][0],
  segmentationData?: SegmentationData,
  searchCriterias?: string[],
  isFiltered?: boolean
}

function RenderSegmentationCriterias(props: RenderSegmentationCriteriasProps): ReactElement {
  const segmentationContext = useContext(SegmentationContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();
  const ref = useContainerRef();
  const parentRef = useRef<HTMLDivElement>(null);
  const [columnCount, setColumnCount] = useState(1);

  const updateGridDimensions = () => {
    if (parentRef?.current) {
      const containerWidth = parentRef.current.offsetWidth;
      const calculatedColumnCount = Math.max(1, Math.floor(containerWidth / baseChartWidth));

      setColumnCount(calculatedColumnCount);
    }
  };

  const makeCriteria = (potentialCriterias: PotentialSegmentationCriteria[], segmentationData?: SegmentationData) =>
    potentialCriterias.map(potentialCriteria => ({
      ...potentialCriteria,
      ...(segmentationData?.segmentationCriterias?.[potentialCriteria.id] || {})
    }))
      .filter(segmentationCriteria => ((props.searchCriterias?.length || 0) > 0 ? props.searchCriterias?.some(criteria => segmentationCriteria.filterLabel.toLowerCase().includes(criteria.toLowerCase() || '')) : true));
  const [possibleCriterias, setPossibleCriterias] = useState<SegmentationPossibleFilter[]>([]);
  const [potentialCriterias, setPotentialCriterias] = useState<PotentialSegmentationCriteria[]>([]);
  const [criterias, setCriterias] = useState(makeCriteria(potentialCriterias, props.segmentationData));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isCriteriaOrdering, setIsCriteriaOrdering] = useState(pathname.includes('create'));
  const open = Boolean(anchorEl);
  const possibleCriteriasService = new CustomSegmentationFiltersService<SegmentationPossibleFilter>();
  const { height } = useWindowDimensions();

  const getPossibleCriterias = (outputEntities?: SegmentationPossibleOutputEntityTypes) => {
    setPossibleCriterias([]);

    possibleCriteriaTypes(
      segmentationContext?.segmentation?.data.possibleDataSetIds,
      outputEntities ? [outputEntities] : undefined
    )?.forEach(criteriaType => possibleCriteriasService.getAll({ filters: [{ scope: 'on', id: 'on', value: criteriaType }] })
      .then((res) => {
        setPossibleCriterias(prevState => [
          ...prevState.filter(possibleCriteria => !res.data?.find(dataFilter => dataFilter.id === possibleCriteria.id)),
          ...res.data
            ? res.data.filter(possibleCriteria => !possibleCriteria.categories.find(category => category === 'hidden') && possibleCriteria.canUseForSegmentation)
            : []
        ]);
      }));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (segmentationCriteria: ChartDispatchSegmentationsProps['criteria']) => props.setCustomSegmentationData({ ...(segmentationContext?.segmentation?.data as CustomSegmentationData), potentialSegmentationCriterias: (segmentationContext?.segmentation?.data.potentialSegmentationCriterias || potentialCriterias).filter(potentialCriteria => potentialCriteria.id !== segmentationCriteria.id) });
  const handleChangeDisplayType = (displayType: SegmentationPossibleDisplayTypes, segmentationCriteria: ChartDispatchSegmentationsProps['criteria']) => props.setCustomSegmentationData({ ...(segmentationContext?.segmentation?.data as CustomSegmentationData), potentialSegmentationCriterias: (segmentationContext?.segmentation?.data.potentialSegmentationCriterias || potentialCriterias).map(potentialCriteria => (potentialCriteria.id === segmentationCriteria.id ? { ...potentialCriteria, displayInfo: { ...potentialCriteria.displayInfo, displayType } } : potentialCriteria)) });

  const rowCount = Math.ceil(criterias.length / columnCount);

  const virtualizer = useVirtualizer({
    count: rowCount,
    estimateSize: () => getBaseChartHeight(height) + 55 + 8,
    getScrollElement: () => ref?.current || null,
    overscan: 2
  });

  useEffect(() => {
    getPossibleCriterias(props.outputEntities);
  }, [props.outputEntities, segmentationContext]);

  useEffect(() => {
    virtualizer.measure();
  }, [height]);

  useEffect(() => {
    if (props.segmentationData?.segmentationCriterias) {
      const segmentationCriterias = props.getCriterias()
        .filter(criteria => !props.filters.find(filter => filter.filterId === criteria.filterId && Object.values(segmentationPossibleWorkflows
          .find(possibleWorkflow => possibleWorkflow.dataSetId === props.dataSetId)?.entryPoints
          .find(entryPoint => entryPoint.entryPoint === props.outputEntities)?.correlationTable || {}).includes(filter.on)))
        .map((criteria) => {
          const possibleCriteria = possibleCriterias.find(pc => pc.id === criteria.filterId);

          if (!possibleCriteria) return criteria;

          return {
            ...possibleCriteria,
            ...criteria
          };
        });
      setPotentialCriterias(segmentationCriterias);
      setCriterias(makeCriteria(segmentationCriterias, props.segmentationData));
    }
  }, [props.filters, props.segmentationData, possibleCriterias, props.searchCriterias]);

  useEffect(() => {
    updateGridDimensions();
    window.addEventListener('resize', updateGridDimensions);
    virtualizer._willUpdate();

    return () => window.removeEventListener('resize', updateGridDimensions);
  }, [ref?.current, criterias, height]);

  return (
    <Stack>
      {isCriteriaOrdering && potentialCriterias.length === 0 && !pathname.includes('venn')
        ? <Box pl={5} pr={5} width='100%'>
          <Chip
            label={
              <Stack direction='row' spacing={1} alignItems='center'>
                <IconAlertTriangle size='1.2rem' />
                <Typography>{t('segmentation.render.segmentationCriterias.noCriteriaWarning')}</Typography>
              </Stack>
            }
            sx={{
              width: '100%',
              bgcolor: theme.palette.warning.light,
              color: theme.palette.warning.dark
            }}
          />
        </Box>
        : null
      }
      {props.outputEntities && segmentationContext?.segmentation?.data &&
        <Stack
          direction='row'
          spacing={2}
          alignItems='center'
          width='100%'
          p={2}
          sx={!isCriteriaOrdering ? {
            position: 'sticky',
            top: 50,
            zIndex: 1
          } : {
            position: 'block'
          }}
        >
          <CriteriasBuilderSegmentation
            customSegmentationData={segmentationContext.segmentation.data}
            setCustomSegmentationData={props.setCustomSegmentationData}
            possibleCriterias={possibleCriterias
              .filter(filter => !segmentationContext?.segmentation?.data.potentialSegmentationCriterias?.find(f => f.filterId === filter.id))
              .filter(filter => (props.outputEntities ? possibleCriteriaTypes(undefined, [props.outputEntities]).includes(filter.on) : filter))
            }
            outputEntities={props.outputEntities}
            onlyAddBtn={!isCriteriaOrdering}
            fullWidth={potentialCriterias.length === 0}
            noAccordion
          />
          {!isCriteriaOrdering ?
            <Button
              variant='outlined'
              onClick={() => {
                handleClose();
                setIsCriteriaOrdering(true);
              }}
              startIcon={<IconEdit size='1.2rem'/>}
              sx={{
                minWidth: 'max-content',
                bgcolor: theme.palette.background.paper,
                borderWidth: '1px',
                borderColor: theme.palette.grey['500']
              }}
            >
              {t('segmentation.render.segmentationCriterias.editMode')}
            </Button>
            : null
          }

          {!isCriteriaOrdering &&
            <IconButton
              size='small'
              id='criteria-menu-button'
              onClick={handleClick}
              aria-controls={open ? 'criteria-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
            >
              <IconDotsVertical size='1.2rem'/>
            </IconButton>
          }
          <Menu
            id='criteria-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'criteria-menu-button'
            }}
          >
            <MenuItem onClick={() => {
              handleClose();
              if (segmentationContext?.segmentation) {
                props.setCustomSegmentationData({
                  ...segmentationContext.segmentation.data,
                  potentialSegmentationCriterias: []
                });
              }
            }}>
              <Stack direction='row' spacing={2} alignItems='center' p={2} pl={0} color={theme.palette.error.main}>
                <IconTrash size='1.2rem'/>
                <Typography>{t('segmentation.render.segmentationCriterias.removeAllCriterias')}</Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </Stack>
      }
      {!isCriteriaOrdering && (criterias.length > 0 ?
        <Stack
          ref={parentRef}
          sx={{
            width: '100%',
            height: `${virtualizer.getTotalSize() + 8}px`,
            position: 'relative'
          }}
          p={2}
        >
          {virtualizer.getVirtualItems().map(item => (
            <Grid
              key={item.index}
              p={2}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${item.size}px`,
                transform: `translateY(${item.start}px)`
              }}
              container
              spacing={2}
            >
              {Array.from(Array(columnCount)).map((_, i) => (
                item.index * columnCount + i < criterias.length ?
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid key={i} size='grow' pb={2}>
                    <DispatchChartsSegmentations
                      {...props}
                      criteria={criterias[item.index * columnCount + i]}
                      handleDelete={handleDelete}
                      handleChangeDisplayType={handleChangeDisplayType}
                    />
                  </Grid> :
                  null
              ))}
            </Grid>
          ))}
        </Stack> :
        <Grid container p={3} spacing={2} alignItems='center' justifyContent='center'>
          <Grid size={12}>
            <Typography textAlign='center'>{t('common.utils.or')}</Typography>
          </Grid>
          <Grid>
            <InheritedCriterias
              setCriterias={newCriterias => (segmentationContext?.segmentation ?
                props.setCustomSegmentationData({
                  ...(segmentationContext.segmentation.data),
                  potentialSegmentationCriterias: newCriterias
                }) :
                undefined)
              }
            />
          </Grid>
        </Grid>
      )}
      {isCriteriaOrdering &&
        <Stack
          direction='row'
          spacing={4}
          alignItems='center'
          p={5}
          pt={0}
          sx={{
            position: 'sticky',
            bottom: -4,
            zIndex: 1
          }}
        >
          <Button
            variant='contained'
            onClick={() => {
              setIsCriteriaOrdering(false);
              props.updateSegmentation();
            }}
            startIcon={<IconDeviceFloppy size='1.6rem' />}
            sx={{
              ml: 'auto !important'
            }}
            disabled={potentialCriterias.length === 0}
          >
            {t('segmentation.render.segmentationCriterias.closeEditMode')}
          </Button>
        </Stack>
      }
    </Stack>
  );
}

export default RenderSegmentationCriterias;
