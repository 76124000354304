import React, { ReactElement, useEffect, useState } from 'react';
import { Button, FormControl, Modal, Paper, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { BatchesRecordSetsService } from '@/api/services/batches';

export interface CommonRecordData {
  type: string,
  banque: string,
  marche: string,
  portfolio: string
};
export interface PersonRecordData extends CommonRecordData {
  firstName: string,
  lastName: string,
  birthDate: string
}
export interface CompanyRecordData extends CommonRecordData {
  siren: string,
  denomination: string,
  type: string
}
export type RecordData = PersonRecordData | CompanyRecordData;
export interface RecordAddState {
  open: boolean,
  data: RecordData | null,
  recordSetId: string,
  type: 'person' | 'company'
}
interface AddRecordModalInterface {
  addRecordModalStates: RecordAddState,
  setAddRecordModalStates: React.Dispatch<React.SetStateAction<RecordAddState>>,
  refreshData: React.Dispatch<React.SetStateAction<boolean>>
}

function AddRecordModal(props: AddRecordModalInterface): ReactElement {
  const recordSetServices = new BatchesRecordSetsService();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [addFormAsPerson, setAddFormAsPerson] = useState<PersonRecordData>({
    firstName: (props.addRecordModalStates.data as PersonRecordData)?.firstName,
    lastName: (props.addRecordModalStates.data as PersonRecordData)?.lastName,
    birthDate: (props.addRecordModalStates.data as PersonRecordData)?.birthDate,
    type: (props.addRecordModalStates.data as PersonRecordData)?.type,
    banque: (props.addRecordModalStates.data as PersonRecordData)?.banque,
    marche: (props.addRecordModalStates.data as PersonRecordData)?.marche,
    portfolio: (props.addRecordModalStates.data as PersonRecordData)?.portfolio
  });
  const [addFormAsCompany, setAddFormAsCompany] = useState<CompanyRecordData>({
    siren: (props.addRecordModalStates.data as CompanyRecordData)?.siren,
    denomination: (props.addRecordModalStates.data as CompanyRecordData)?.denomination,
    type: (props.addRecordModalStates.data as CompanyRecordData)?.type,
    banque: (props.addRecordModalStates.data as CompanyRecordData)?.banque,
    marche: (props.addRecordModalStates.data as CompanyRecordData)?.marche,
    portfolio: (props.addRecordModalStates.data as CompanyRecordData)?.portfolio
  });

  const handleEditChange = (value: string, propertyKey: string, recordTypeToEdit: 'person' | 'company') => {
    if (recordTypeToEdit === 'person') {
      setAddFormAsPerson(prevState => ({
        ...prevState,
        [propertyKey]: value
      }));
    }
    if (recordTypeToEdit === 'company') {
      setAddFormAsCompany(prevState => ({
        ...prevState,
        [propertyKey]: value
      }));
    }
  };

  const handleClose = () => {
    props.setAddRecordModalStates({ open: false, data: null, recordSetId: '', type: 'person' });
  };

  const createNewRecord = () => {
    if (props.addRecordModalStates.data) {
      const { type, recordSetId } = props.addRecordModalStates;
      const dataToUse =
        type === 'person' ? addFormAsPerson :
          type === 'company' ? addFormAsCompany :
            null;

      if (dataToUse) {
        recordSetServices.createRecordInSet(recordSetId, type, dataToUse)
          .then(() => {
            props.refreshData(true);
            handleClose();
          });
      }
    }
  };

  const determinateIfRecordIsReadyToCreate = (personForm: PersonRecordData, companyForm: CompanyRecordData) => {
    const allKeysHaveValues = (obj: RecordData | ArrayLike<unknown>) =>
      Object.values(obj)
        .every(value =>
          value !== null && value !== undefined && value !== ''
        );
    const isPersonFormValid = allKeysHaveValues(personForm);
    const isCompanyFormValid = allKeysHaveValues(companyForm);
  
    if (isPersonFormValid || isCompanyFormValid) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  };

  useEffect(() => {
    determinateIfRecordIsReadyToCreate(addFormAsPerson, addFormAsCompany);
  }, [addFormAsPerson, addFormAsCompany]);

  return (
    <Modal
      open={props.addRecordModalStates.open}
      onClose={handleClose}
    >
      <Paper
        sx={{
          width: 500,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4
        }}
      >
        <Stack spacing={4}>
          <Typography variant='h3'>New {props.addRecordModalStates.type}</Typography>
          {props.addRecordModalStates.type === 'person' ?
            Object.entries(addFormAsPerson).map(([key, value]) => (
              <FormControl key={key} fullWidth>
                <TextField
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  variant='outlined'
                  value={value}
                  onChange={e => handleEditChange(e.target.value, key, 'person')}
                />
              </FormControl>
            ))
            :
            Object.entries(addFormAsCompany).map(([key, value]) => (
              <FormControl key={key} fullWidth>
                <TextField
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  variant='outlined'
                  value={value}
                  onChange={e => handleEditChange(e.target.value, key, 'company')}
                />
              </FormControl>
            ))
          }
          <Stack direction='row' width='100%' justifyContent='flex-end' spacing={4}>
            <Button variant='text' onClick={handleClose} color='error'>Cancel</Button>
            <Button variant='contained' onClick={createNewRecord} disabled={!isReady}>Create Record</Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default AddRecordModal;