import { AnyEntity } from '@deecision/dna-interfaces';
import axiosInstance, { BaseServiceProps, CustomBaseService } from '@/api';

class VeegilenzService extends CustomBaseService<unknown> {
  entity: AnyEntity;

  constructor(props: Omit<BaseServiceProps, 'axiosInstance' | 'elementPrefixUrl'> & { entity: AnyEntity }) {
    super({ ...props, axiosInstance, elementPrefixUrl: 'veegilenz' });

    this.entity = props.entity;
  }

  async getComplianceReport() {
    const res = await this.axiosInstance.get(`${this.constructUrl()}/reports/${this.entity.entityType}/${this.entity.entityId}`, {
      responseType: 'arraybuffer'
    });

    return this.downloadPdf(res.data, `${this.entity.entityType}-${this.entity.entityId}`);
  }
}

export default VeegilenzService;
