import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Grid2, IconButton, Stack, Tooltip } from '@mui/material';
import { IconDeviceDesktopAnalytics, IconRefresh } from '@tabler/icons-react';
import TitleComponent from '@/components/title';
import StatusCard from './components/statusCard';
import { DataElementsStatsService, AllStatsInterface } from '@/api/services/data.elements.stats.api';

function DataElementsDetailsHeader(): ReactElement {
  const statsServices = new DataElementsStatsService();
  const [data, setData] = useState<AllStatsInterface>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(true);

  const fetchData = () => {
    setRefresh(false);
    statsServices.getAllStatsByStatus()
      .then((res) => {
        if (res.data) {
          let count = 0;
          Object.values(res.data).forEach((value) => {
            count += value;
          });
          setTotalCount(count);
          setData(res.data);
        }
      });
  };

  const refreshData = () => {
    setRefresh(true);
  };

  useEffect(() => {
    if (refresh) {
      fetchData();
    }
  }, [refresh]);

  return (
    <Stack>
      <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between'>
        <TitleComponent title='Data Elements Monitoring' icon={IconDeviceDesktopAnalytics} />
        <Stack direction='row'>
          <Box>
            <Tooltip arrow title='Refresh Data' placement='top'>
              <IconButton title='Refresh' onClick={refreshData}>
                <IconRefresh />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>

      <Grid2 container flexDirection='row' spacing={2}>
        {data ?
          Object.entries(data).map(([key, value]) =>
            <Grid2>
              <StatusCard
                statusType={key}
                count={value}
                totalCount={totalCount}
                onClick={() => {}}
              />
            </Grid2>
          )
          :
          null
        }
      </Grid2>
    </Stack>
  );
}

export default DataElementsDetailsHeader;