import React, { ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaseGroup, GroupDesc } from '@deecision/dna-interfaces';
import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import GroupsServices from '../../services';
import dnaConfig from '../../../../../../config/dna.config.json';
import PortfolioDashboardContainers from '../../../../../containers/dashboards/portfolio';
import { DashboardConfig } from '@/main/containers/dashboards/portfolio/types';

const dashboardConfig: DashboardConfig = {
  sections: [
    {
      id: 'indicators',
      noLabel: true,
      type: 'full',
      groups: [
        {
          subGroupId: 'persons.network.personal',
          subGroups: [
            {
              subGroupId: 'persons.network.personal.client'
            },
            {
              subGroupId: 'persons.network.personal.notClient',
              subGroups: [
                {
                  subGroupId: 'persons.network.personal.notClient.sameFamily'
                }
              ]
            }
          ]
        },
        {
          subGroupId: 'persons.network.business',
          subGroups: [
            {
              subGroupId: 'persons.network.business.client'
            },
            {
              subGroupId: 'persons.network.business.notClient',
              subGroups: [
                {
                  subGroupId: 'persons.network.business.notClient.sameFamily'
                }
              ]
            }
          ]
        },
        {
          subGroupId: 'persons.network.extended',
          subGroups: [
            {
              subGroupId: 'persons.network.extended.client'
            },
            {
              subGroupId: 'persons.network.extended.notClient',
              subGroups: [
                {
                  subGroupId: 'persons.network.extended.notClient.sameFamily'
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

function ProxemeeTabsPortfolios(): ReactElement {
  const theme = useTheme();
  const data = useOutletContext() as { data: BaseGroup };
  const navigate = useNavigate();
  const portfoliosServices = new GroupsServices();
  const [dataAllValues, setDataAllValues] = useState<GroupDesc[]>();

  const callSegmentation = (entityType?: string, groupId?: string) => {
    if (groupId && entityType) {
      navigate(`../${dnaConfig.routes.portfolios.childrens.augment.baseUrl}?entityType=${entityType}&groupId=${groupId}`);
    }
  };

  useEffect(() => {
    portfoliosServices.getGroupSubGroupDescs(data.data._id)
      .then(res => setDataAllValues(res.data));
  }, []);

  return (
    <Grid container alignItems='stretch' spacing={6}>
      {dataAllValues ?
        <PortfolioDashboardContainers dashboardConfig={dashboardConfig} baseGroupId={data.data._id} callSegmentation={callSegmentation} /> :
        <Grid container spacing={2}>
          {[...Array(8)].map((arr, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={`${arr}-${index}`} size='grow' minWidth={200} height={140}>
              <Skeleton
                sx={{ bgcolor: theme.palette.background.default, borderRadius: 2 }}
                animation='wave'
                variant='rectangular'
                width='100%'
                height='100%'
              />
            </Grid>
          ))}
        </Grid>
      }
    </Grid>
  );
}

export default ProxemeeTabsPortfolios;
