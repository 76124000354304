import React, { ReactElement, useEffect, useState } from 'react';
import { Button, FormControl, Modal, Paper, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { BaseGroup } from '@deecision/dna-interfaces';
import { DashboardGroupService } from '@/api/services/dashboard.group';

interface EditFormType {
  label: string | null,
  banqueId: string | null
}

interface BaseGroupModalEditProps {
  editModal: { open: boolean, data: BaseGroup | null },
  setEditModal: React.Dispatch<React.SetStateAction<{ open: boolean, data: BaseGroup | null }>>,
  refreshData: React.Dispatch<React.SetStateAction<boolean>>
}

function BaseGroupModalEdit(props: BaseGroupModalEditProps): ReactElement {
  const dashboardServices = new DashboardGroupService();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [editForm, setEditForm] = useState<EditFormType>({
    label: props.editModal.data?.label || null,
    banqueId: props.editModal.data?.banqueId || null
  });

  const handleEditChange = (value: string, propertyKey: string) => {
    setEditForm(prevState => ({
      ...prevState,
      [propertyKey]: value
    }));
  };

  const handleClose = () => {
    setEditForm({ label: null, banqueId: null });
    props.setEditModal({ open: false, data: null });
  };

  const editNewBaseGroup = () => {
    if (isReady && props.editModal.data?._id) {
      dashboardServices.put(props.editModal.data?._id, editForm)
        .then(() => {
          props.refreshData(true);
          handleClose();
        });
    }
  };

  const determinateIfRecordIsReadyToCreate = (createFormToCheck: EditFormType) => {
    const allKeysHaveValues = (obj: EditFormType | ArrayLike<unknown>) =>
      Object.values(obj)
        .every(value =>
          value !== null && value !== undefined && value !== ''
        );
    const isPersonFormValid = allKeysHaveValues(createFormToCheck);
  
    if (isPersonFormValid) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  };

  useEffect(() => {
    determinateIfRecordIsReadyToCreate(editForm);
  }, [editForm]);

  useEffect(() => {
    if (props.editModal.data) {
      setEditForm({
        label: props.editModal.data?.label,
        banqueId: props.editModal.data?.banqueId
      });
    }
  }, [props.editModal.data]);

  return (
    <Modal
      open={props.editModal.open}
      onClose={handleClose}
    >
      <Paper
        sx={{
          width: 500,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4
        }}
      >
        <Stack spacing={4}>
          <Typography variant='h3'>Edit BaseGroup</Typography>
          {
            Object.entries(editForm).map(([key, value]) => (
              <FormControl key={key} fullWidth>
                <TextField
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  variant='outlined'
                  value={value}
                  onChange={e => handleEditChange(e.target.value, key)}
                />
              </FormControl>
            ))
          }
          <Stack direction='row' width='100%' justifyContent='flex-end' spacing={4}>
            <Button variant='text' onClick={handleClose} color='error'>Cancel</Button>
            <Button variant='contained' onClick={editNewBaseGroup} disabled={!isReady}>Edit BaseGroup</Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default BaseGroupModalEdit;