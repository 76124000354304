import React, { ReactElement, useState } from 'react';
import { IconSettings, IconAlertTriangle, IconUser } from '@tabler/icons-react';
import { Box, Button, darken, Grid2, Paper, Stack, Typography, useTheme } from '@mui/material';
import { DashboardGroupService } from '@/api/services/dashboard.group';
import { AdminService } from '@/api/services/admin';
import CustomDialog from '@/components/dialog';

interface ActionCardProps {
  title: string,
  onClick: () => void,
  icon: JSX.Element,
  backgroundColor: string
}

export function ActionCard(props: ActionCardProps): ReactElement {
  return (
    <Paper
      elevation={0}
      variant='hoverElevation2'
      onClick={props.onClick}
      sx={{
        width: '100%',
        border: 0.7,
        p: 2,
        bgcolor: props.backgroundColor,
        overflowX: 'auto',
        borderRadius: 1,
        cursor: 'pointer'
      }}
    >
      <Box
        width='fit-content'
        borderRadius={1}
        borderColor={darken(props.backgroundColor, 0.3)}
        bgcolor={darken(props.backgroundColor, 0.1)}
        p={2}
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{
          aspectRatio: '1 / 1'
        }}>
        {props.icon}
      </Box>
      <Typography variant='h5'>{props.title}</Typography>
    </Paper>
  );
}

function AdminActionsHub(): ReactElement {
  const theme = useTheme();
  const dashboardServices = new DashboardGroupService();
  const adminServices = new AdminService();
  const [clearDataModal, setClearDataModal] = useState<boolean>(false);
  const [deleteDataModal, setDeleteDataModal] = useState<boolean>(false);

  const clearAllGroups = () => {
    dashboardServices.clearAllGroups();
    setClearDataModal(false);
  };

  const deleteAllDatas = () => {
    adminServices.deleteAllData();
    setDeleteDataModal(false);
  };

  const actionCategories = [
    {
      title: 'General',
      pt: 0,
      card: [
        {
          title: 'Refresh config',
          icon: <IconSettings size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: 'warning',
          onClick: () => {
            adminServices.refreshConfig();
          }
        },
        {
          title: 'Delete all data',
          icon: <IconAlertTriangle size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: 'error',
          onClick: () => {
            setDeleteDataModal(true);
          }
        }
      ]
    },
    {
      title: 'Group',
      pt: 0,
      card: [
        {
          title: 'Clear All Group',
          icon: <IconUser size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: 'secondary',
          onClick: () => {
            setClearDataModal(true);
          }
        },
        {
          title: 'Build All Group',
          icon: <IconUser size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: 'secondary',
          onClick: () => {
            dashboardServices.buildAllGroups();
          }
        },
        {
          title: 'Refresh All Group',
          icon: <IconUser size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: 'secondary',
          onClick: () => {
            dashboardServices.refreshAllGroups();
          }
        }
      ]
    }
  ];

  return (
    <Grid2>
      <Typography variant='h2' pb={6}>
        Super Admin Actions
      </Typography>

      {actionCategories.map(category =>
        <Stack borderRadius={1} p={4} pt={category.pt} spacing={2}>
          <Typography variant='h5'>
            {category.title}
          </Typography>
          <Grid2 container height='85%' spacing={2}>
            {category.card.map(card =>
              <Button variant='contained' onClick={card.onClick} startIcon={card.icon} color={card.bgColor as 'secondary' | 'warning' | 'error' | 'inherit' | 'primary' | 'success' | 'info'}>
                {card.title}
              </Button>
              // <Grid2 minWidth={123} p={2}>
              //   <ActionCard title={card.title} icon={card.icon} backgroundColor={card.bgColor} onClick={card.onClick}/>
              // </Grid2>
            )}
          </Grid2>
        </Stack>
      )}

      <CustomDialog
        open={clearDataModal}
        handleClose={() => setClearDataModal(false)}
        title='Do you want really want to clear all groups ?'
        content='Clearing all groups will requires to rebuild them which may takes few hours'
        buttonConfirmationAction={clearAllGroups}
      />
      <CustomDialog
        open={deleteDataModal}
        handleClose={() => setDeleteDataModal(false)}
        title='Do you want really want to delete ALL Datas ?'
        content='This is a Destructive and Irreversible action. You should be sure of deleting ALL Datas'
        buttonConfirmationAction={deleteAllDatas}
      />

    </Grid2>
  );
}

export default AdminActionsHub;