import React, { ReactElement } from 'react';
import { Grid2 } from '@mui/material';
import Construction from '@/components/construction';

function EntitiesActionsTabs(): ReactElement {
  // const theme = useTheme();
  // const actionCategories = [
  //   {
  //     title: 'Reprocess this Entity Data',
  //     pt: 0,
  //     card: [
  //       {
  //         title: 'Update only this entity',
  //         icon: <IconUser size='1.6rem' color={theme.palette.grey['800']}/>,
  //         bgColor: theme.palette.secondary.light,
  //         onClick: () => {

  //         }
  //       }
  //     ]
  //   }
  // ];

  return (
    <Grid2>
      <Construction />
      {/* {actionCategories.map(category =>
        <Stack borderRadius={1} p={4} pt={category.pt} spacing={2}>
          <Typography variant='h5'>
            {category.title}
          </Typography>
          <Grid2 container height='85%' spacing={2}>
            {category.card.map(card =>
              <Grid2 minWidth={123} p={2}>
                <ActionCard title={card.title} icon={card.icon} backgroundColor={card.bgColor} onClick={card.onClick}/>
              </Grid2>
            )}
          </Grid2>
        </Stack>
      )} */}
    </Grid2>
  );
}

export default EntitiesActionsTabs;