import React, { ReactElement, useEffect, useState } from 'react';
import { NewBatchConfigurationDto, RecordSetInterface } from '@deecision/dna-interfaces';
import { githubDarkTheme, githubLightTheme, JsonData, JsonEditor } from 'json-edit-react';
import { Box, Button, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { IconBulb, IconBulbOff, IconClockEdit } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TitleComponent from '@/components/title';
import { BatchesRecordSetsService, BatchesService } from '@/api/services/batches';

function ConfigurateNewBatch(): ReactElement {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const batcheRecordSetServices = new BatchesRecordSetsService();
  const batchServices = new BatchesService();
  const [nightMode, setNightMode] = useState<boolean>(false);
  const [recordSetData, setRecordSetData] = useState<RecordSetInterface>();
  const [batchConfDto, setBatchConfDto] = useState<NewBatchConfigurationDto>({
    batchSettings: {
      initialContextsPerType: {
        person: {
          personLevel: 1,
          personLevelMax: 3,
          companyLevel: 0,
          companyLevelMax: 3
        },
        company: {
          personLevel: 0,
          personLevelMax: 3,
          companyLevel: 1,
          companyLevelMax: 3
        }
      },
      priority: 5
    },
    profilesPerType: {
      'person': ['test.person.rncs.search'],
      'company': ['test.company.search']
    },
    recordSetCode: recordSetData?.recordSetCode || ''
  });

  const updateData = (data: JsonData) => {
    setBatchConfDto(data as NewBatchConfigurationDto);
  };

  const createNewBatch = () => {
    batchServices.createCustomBatch(batchConfDto)
      .then((res) => {
        if (res.data) {
          navigate(`/admin/batches/${res.data?._id}`);
        }
      });
  };

  const fetchRecordSetDataFromId = (recordSetId: string) => {
    batcheRecordSetServices.get(recordSetId)
      .then((res) => {
        if (res.data) {
          setRecordSetData(res.data);
          setBatchConfDto(prevState => ({
            ...prevState,
            recordSetCode: res.data?.recordSetCode || ''
          }));
        };
      })
      .catch(err => alert(err));
  };

  useEffect(() => {
    if (id) {
      fetchRecordSetDataFromId(id);
    }
  }, [id]);

  return (
    <Stack spacing={4}>
      <Grid container justifyContent='space-between' width='100%' alignItems='center'>
        <TitleComponent title={`${t('common.utils.configurate')} "${recordSetData?.originalFileName}" (${recordSetData?.recordSetCode})`} icon={IconClockEdit} />
      </Grid>

      <Stack direction='row' justifyContent='center'>
        <Box maxHeight='67vh' overflow='auto'>
          <JsonEditor
            data={ batchConfDto }
            setData={ updateData }
            indent={2}
            theme={nightMode ? githubDarkTheme : githubLightTheme}
            enableClipboard={false}
            rootName='Batch'
            restrictEdit = { ({ key }) => key === 'recordSetCode' }
            restrictDelete = { ({ key }) => key === 'recordSetCode' }
          />
        </Box>
        <Box
          height='30px'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setNightMode(!nightMode);
          }}
        >
          {nightMode ?
            <IconBulbOff />
            :
            <IconBulb />
          }
        </Box>
      </Stack>

      <Stack direction='row' justifyContent='flex-end'>
        <Box>
          <Button variant='contained' onClick={createNewBatch}>
            {t('admin.batch.creation')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

export default ConfigurateNewBatch;