import React, { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import KeyValueWithCard from '@/components/keyvalue/keyvaluewithcard';
import { VeegilenzScores } from './index';
import Status from '../../../components/status';

interface VeegilenzCardProps {
  score: number,
  veegilenzDatas: VeegilenzScores
}

function VeegilenzCard(props: VeegilenzCardProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  const scoreColor = [
    theme.palette.success,
    theme.palette.warning,
    theme.palette.error,
    {
      light: theme.palette.grey['800'],
      main: theme.palette.grey['800'],
      dark: theme.palette.grey['200'],
      contrastText: theme.palette.grey['300']
    }
  ];

  return (
    <Stack direction='row' spacing={2}>
      <Stack spacing={2} width='100%'>
        <Tooltip
          title={(props.veegilenzDatas.directIndexReason && props.veegilenzDatas.directIndexReason !== t('common.utils.unknown')) ?
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography variant='body2'>{t(`veegilenz.reason.${props.veegilenzDatas.directIndexReason || 'noReason'}`)}</Typography>
              <Status
                statusAsInt={
                  props.veegilenzDatas.directIndexReason === 'atLeastOneBlackFact' && 3 ||
                  props.veegilenzDatas.directIndexReason === 'atLeastOneRedFact' && 2 ||
                  props.veegilenzDatas.directIndexReason === 'atLeastOneOrangeFact' && 1 ||
                  props.veegilenzDatas.directIndexReason === 'moreThanTreeOrangeFacts' && 1 ||
                  props.veegilenzDatas.directIndexReason === 'onlyGreenFacts' && 0 ||
                  0
                }
                size='1rem'
              />
            </Stack> :
            t('veegilenz.errorResponse.notSearched')
          }
          arrow
          placement='right'
        >
          <Box>
            <KeyValueWithCard
              keyValueCard={{
                bgColor: (props.veegilenzDatas.directIndexReason && props.veegilenzDatas.directIndexReason !== t('common.utils.unknown')) ? scoreColor[Math.floor(props.veegilenzDatas.directIndex ?? 0)]?.light : theme.palette.grey['400'],
                color: (props.veegilenzDatas.directIndexReason && props.veegilenzDatas.directIndexReason !== t('common.utils.unknown')) ? scoreColor[Math.floor(props.veegilenzDatas.directIndex ?? 0)]?.dark : theme.palette.grey['700'],
                Key: t('veegilenz.directIndex'),
                width: '100%',
                height: '28px',
                padding: '4px 8px',
                size: 'small',
                value: (props.veegilenzDatas.directIndexReason && props.veegilenzDatas.directIndexReason !== t('common.utils.unknown')) ? <Status statusAsInt={props.veegilenzDatas.directIndex} color={scoreColor[Math.floor(props.veegilenzDatas.directIndex ?? 0)]?.[props.veegilenzDatas.directIndex === 3 ? 'contrastText' : 'dark']} /> : t('common.utils.unknown')
              }}
            />
          </Box>
        </Tooltip>
        <KeyValueWithCard
          keyValueCard={{
            bgColor: props.veegilenzDatas.corporateNetwork ? scoreColor[Math.floor(props.veegilenzDatas.corporateNetwork)].light : theme.palette.grey['400'],
            color: props.veegilenzDatas.corporateNetwork ? scoreColor[Math.floor(props.veegilenzDatas.corporateNetwork)].dark : theme.palette.grey['700'],
            Key: t('veegilenz.corporateNetworkShort'),
            fullKey: t('veegilenz.corporateNetwork'),
            width: '100%',
            height: '28px',
            padding: '4px 8px',
            size: 'small',
            value: props.veegilenzDatas.corporateNetwork !== undefined ? <Status statusAsInt={props.veegilenzDatas.corporateNetwork} color={scoreColor[Math.floor(props.veegilenzDatas.corporateNetwork)].contrastText} /> : '-'
          }}
        />
        <KeyValueWithCard
          keyValueCard={{
            bgColor: props.veegilenzDatas.individualNetwork ? scoreColor[Math.floor(props.veegilenzDatas.individualNetwork)].light : theme.palette.grey['400'],
            color: props.veegilenzDatas.individualNetwork ? scoreColor[Math.floor(props.veegilenzDatas.individualNetwork)].dark : theme.palette.grey['700'],
            Key: t('veegilenz.individualNetworkShort'),
            fullKey: t('veegilenz.individualNetwork'),
            width: '100%',
            height: '28px',
            padding: '4px 8px',
            size: 'small',
            value: props.veegilenzDatas.individualNetwork !== undefined ? <Status statusAsInt={props.veegilenzDatas.individualNetwork} color={scoreColor[Math.floor(props.veegilenzDatas.individualNetwork)].contrastText} /> : '-'
          }}
        />
      </Stack>
    </Stack>
  );
}

export default VeegilenzCard;
