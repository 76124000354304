import React, { ReactElement, useState } from 'react';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { BaseGroup } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';
import {
  IconArrowRight,
  IconChevronDown,
  IconDotsVertical,
  IconFolder,
  IconRefresh,
  IconScreenshot,
  IconUsers
} from '@tabler/icons-react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Chip, Tooltip, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import IconBuildings from '@/assets/custom/IconBuildings';
import TitleComponent from '@/components/title';
import dnaConfig from '../../../../../config/dna.config.json';
import { useFullContainerRef } from '@/hooks/full-container';
import captureScreenshot from '@/utils/screenshot';

function AugmentDataButton(props: { data: BaseGroup }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id='augment-button'
        variant='contained'
        aria-controls={open ? 'augment-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        endIcon={
          <IconChevronDown
            style={{
              transform: open ? 'rotate(180deg)' : undefined,
              transition: '0.2s ease-in-out'
            }}
            size='1.6rem'
          />
        }
        onClick={handleClick}
      >
        {t('portfolios.header.btn')}
      </Button>
      <Menu
        id='augment-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'augment-button'
        }}
      >
        <MenuItem
          onClick={() => navigate(`${dnaConfig.routes.portfolios.relativeUrl}/${props.data._id}/${dnaConfig.routes.portfolios.childrens.augment.baseUrl}/${dnaConfig.childRoutes.segmentations.baseUrl}?entityType=deecPerson&groupId=persons`)}
        >
          <Stack direction='row' spacing={3} alignItems='center' p={2} width='100%'>
            <IconUsers size='1.2rem' color={theme.palette.primary.main} />
            <Typography variant='h4' color='primary' pr={2}>
              {t('portfolios.header.menu.individuals')} ({props.data.nbPersons})
            </Typography>
            <IconArrowRight size='1.2rem' style={{ marginLeft: 'auto' }} color={theme.palette.primary.main} />
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => navigate(`${dnaConfig.routes.portfolios.relativeUrl}/${props.data._id}/${dnaConfig.routes.portfolios.childrens.augment.baseUrl}/${dnaConfig.childRoutes.segmentations.baseUrl}?entityType=deecCompany&groupId=companies`)}
        >
          <Stack direction='row' spacing={3} alignItems='center' p={2} width='100%'>
            <IconBuildings size='1.2rem' color={theme.palette.secondary.main} />
            <Typography variant='h4' color='secondary' pr={2}>
              {t('portfolios.header.menu.companies')} ({props.data.nbCompanies})
            </Typography>
            <IconArrowRight size='1.2rem' style={{ marginLeft: 'auto' }} color={theme.palette.secondary.main} />
          </Stack>
        </MenuItem>
      </Menu>
    </div>
  );
}

function IdComponentPortfolios(): ReactElement {
  const data = useLoaderData() as { data : BaseGroup };
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ref = useFullContainerRef();

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid size='grow' minWidth='min-content'>
        <TitleComponent
          title={data.data.label}
          icon={IconFolder}
          chips={[
            <Chip
              key='persons'
              label={
                <Stack direction='row' spacing={2} alignItems='center'>
                  <IconUsers color={theme.palette.primary.dark} size='1.6rem' />
                  <Typography variant='h4' color={theme.palette.primary.dark}>{data.data.nbPersons}</Typography>
                </Stack>
              }
              sx={{ bgcolor: theme.palette.primary.light }}
            />,
            <Chip
              key='companies'
              label={
                <Stack direction='row' spacing={2} alignItems='center'>
                  <IconBuildings color={theme.palette.secondary.dark} size='1.6rem' />
                  <Typography variant='h4' color={theme.palette.secondary.dark}>{data.data.nbCompanies}</Typography>
                </Stack>
              }
              sx={{ bgcolor: theme.palette.secondary.light }}
            />,
            <Tooltip
              key='common.dates.lastUpdate'
              title={t('common.dates.lastUpdate')}
              arrow
              placement='top'
            >
              <Chip
                label={data.data.valuesComputation?.lastEndDate?.toLocaleString() ?? '--'}
                icon={<IconRefresh size='1.5rem' />}
                size='medium'
              />
            </Tooltip>
          ]}
        />
      </Grid>
      <Grid container spacing={2} alignItems='center' ml='auto'>
        {!location.pathname.includes(dnaConfig.routes.portfolios.childrens.augment.baseUrl) &&
        <Grid>
          <AugmentDataButton data={data.data} />
        </Grid>
        }
        <Grid>
          <IconButton
            id='menu-button'
            aria-controls={open ? 'menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            <IconDotsVertical size='1.2rem' />
          </IconButton>
          <Menu
            id='menu'
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'menu-button'
            }}
          >
            <MenuItem onClick={() => {
              captureScreenshot(ref);
              setAnchorEl(null);
            }} sx={{ p: 2 }}>
              <Stack direction='row' spacing={2} alignItems='center'>
                <IconScreenshot size='1.4rem' />
                <Typography>
                  {t('common.utils.captureScreenshot')}
                </Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default IdComponentPortfolios;
