import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconProps } from '@tabler/icons-react';
import {
  EntityType,
  SegmentationData,
  SegmentationPossibleFilter, VeegilenzCompanyFact,
  VeegilenzPersonFact
} from '@deecision/dna-interfaces';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { darken } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import SegmentationServices, {
  CustomSegmentationFiltersService
} from '@/main/containers/segmentations/services';
import { getEntityTypeEquivalent, getEntityTypeI18nKey } from '@/main/providers/getter';
import TitleComponent from '@/components/title';
import { CardPart } from '@/components/cards/keyvalue.cards';
import BaseWidget from '@/main/containers/widgets/base-widget';

export interface VeegilenzPortfolioDashboardConfig {
  id: string,
  entityType: EntityType,
  icon: React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<Icon>>,
  groupId: string
}

interface VeegilenzPortfolioDashboardProps {
  baseGroupId: string,
  config: VeegilenzPortfolioDashboardConfig,
  callSegmentation: (entityType?: string, groupId?: string, workflowId?: string, filterIds?: string[], filterValues?: string[]) => void
}

function VeegilenzPortfolioDashboard(props: VeegilenzPortfolioDashboardProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const segmentationServices = new SegmentationServices();
  const segmentationFiltersServices = new CustomSegmentationFiltersService<SegmentationPossibleFilter>();
  const [veegilenzCriterias, setVeegilenzCriterias] = useState<SegmentationPossibleFilter[]>([]);
  const [segmentationData, setSegmentationData] = useState<SegmentationData>();

  const outputEntities = {
    deecPerson: 'person1' as const,
    deecCompany: 'company' as const
  }[props.config.entityType];

  const criteriaType = {
    deecPerson: 'person',
    deecCompany: 'company'
  }[props.config.entityType];

  const filterId = {
    deecPerson: 'person_groupMember',
    deecCompany: 'company_groupMember'
  }[props.config.entityType];

  const facts = {
    deecPerson: Object.values(VeegilenzPersonFact),
    deecCompany: Object.values(VeegilenzCompanyFact)
  }[props.config.entityType];

  const possibleVeegilenzValues = {
    green: {
      code: 0,
      bgcolor: theme.palette.success.light,
      color: theme.palette.success.dark,
      hover: darken(theme.palette.success.light, 0.1)
    },
    orange: {
      code: 1,
      bgcolor: theme.palette.warning.light,
      color: theme.palette.warning.dark,
      hover: darken(theme.palette.warning.light, 0.1)
    },
    red: {
      code: 2,
      bgcolor: theme.palette.error.light,
      color: theme.palette.error.dark,
      hover: darken(theme.palette.error.light, 0.1)
    },
    black: {
      code: 3,
      bgcolor: theme.palette.grey[800],
      color: theme.palette.grey[200],
      hover: theme.palette.grey[700]
    }
  };

  const getVeegilenzCriterias = () => {
    segmentationFiltersServices.getAll({ filters: [{ scope: 'on', id: 'on', value: criteriaType }] })
      .then((res) => {
        setVeegilenzCriterias(res.data?.filter(filter => filter.categories.includes('veegilenz')) || []);
      });
  };

  const performSegmentation = () => {
    segmentationServices.perform({
      dataSetId: getEntityTypeEquivalent(props.config.entityType),
      globalFilteringItems: [],
      filters: [
        {
          id: filterId,
          filterId,
          type: 'filter',
          on: outputEntities,
          values: [
            `${props.baseGroupId}/${props.config.groupId}`
          ]
        }
      ],
      entitiesSettings: {
        includeEntities: false,
        findOptions: {}
      },
      outputEntities,
      segmentationCriterias: veegilenzCriterias.map(veegilenzCriteria => ({
        ...veegilenzCriteria,
        type: 'segmentationCriteria',
        filterId: veegilenzCriteria.id,
        filterLabel: veegilenzCriteria.label,
        on: outputEntities
      }))
    })
      .then(res => setSegmentationData(res.data));
  };

  useEffect(() => {
    getVeegilenzCriterias();
  }, []);

  useEffect(() => {
    if (veegilenzCriterias.length > 0) {
      performSegmentation();
    }
  }, [veegilenzCriterias]);

  const values = useMemo(() => facts.map(fact => ({
    key: t(`veegilenz.keys.${fact}`),
    value: <Stack direction='row' spacing={1} alignItems='center' height={28}>
      {Object.keys(possibleVeegilenzValues).map(possibleVeegilenzValue => (segmentationData ?
        <Chip
          key={possibleVeegilenzValue}
          label={segmentationData?.segmentationCriterias[veegilenzCriterias.find(veegilenzCriteria => veegilenzCriteria.id.includes(fact))?.id || fact]?.values?.find(value => value.value === possibleVeegilenzValue)?.count || 0}
          sx={{
            minWidth: 56,
            mt: '-11px !important',
            cursor: (segmentationData?.segmentationCriterias[veegilenzCriterias.find(veegilenzCriteria => veegilenzCriteria.id.includes(fact))?.id || fact]?.values?.find(value => value.value === possibleVeegilenzValue)?.count || 0) > 0 ? 'pointer' : 'default',
            bgcolor: possibleVeegilenzValues[possibleVeegilenzValue as keyof typeof possibleVeegilenzValues].bgcolor,
            '& .MuiChip-label': {
              color: possibleVeegilenzValues[possibleVeegilenzValue as keyof typeof possibleVeegilenzValues].color
            },
            border: '1px solid',
            borderColor: darken(possibleVeegilenzValues[possibleVeegilenzValue as keyof typeof possibleVeegilenzValues].bgcolor, 0.2),
            '&:hover': (segmentationData?.segmentationCriterias[veegilenzCriterias.find(veegilenzCriteria => veegilenzCriteria.id.includes(fact))?.id || fact]?.values?.find(value => value.value === possibleVeegilenzValue)?.count || 0) > 0
              ? {
                bgcolor: possibleVeegilenzValues[possibleVeegilenzValue as keyof typeof possibleVeegilenzValues].hover,
                borderColor: possibleVeegilenzValues[possibleVeegilenzValue as keyof typeof possibleVeegilenzValues].hover
              }
              : undefined
          }}
          onClick={
            (segmentationData?.segmentationCriterias[veegilenzCriterias.find(veegilenzCriteria => veegilenzCriteria.id.includes(fact))?.id || fact]?.values?.find(value => value.value === possibleVeegilenzValue)?.count || 0) > 0 ?
              () => props.callSegmentation(props.config.entityType, props.config.groupId, undefined, [veegilenzCriterias.find(veegilenzCriteria => veegilenzCriteria.id.includes(fact))?.id || fact], [possibleVeegilenzValue]) :
              undefined
          }
          disabled={!veegilenzCriterias.find(veegilenzCriteria => veegilenzCriteria.id.includes(fact))?.id}
          size='small'
        /> :
        <Skeleton width={56} height={24} />
      ))}
    </Stack>
  }))
    .flat(1),
  [segmentationData]
  );

  return (
    <BaseWidget
      title={
        <TitleComponent
          title={t('veegilenz.directIndex')}
          chips={[
            <Chip
              label={t(getEntityTypeI18nKey(getEntityTypeEquivalent(props.config.entityType)))}
              sx={{
                bgcolor: {
                  deecPerson: 'primary.light',
                  deecCompany: 'secondary.light'
                }[props.config.entityType],
                '& .MuiChip-label': {
                  color: {
                    deecPerson: 'primary.dark',
                    deecCompany: 'secondary.dark'
                  }[props.config.entityType]
                }
              }}
              size='small'
            />
          ]}
          size='small'
        />
      }
      size='small'
      fullHeight
      fullWidth
    >
      <CardPart
        values={values}
      />
    </BaseWidget>
  );
}

export default VeegilenzPortfolioDashboard;
