import React, { ReactElement, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaseGroup } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';
import { IconBuildings, IconFlagSearch, IconUsers } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import VeegilenzPortfolioDashboard, {
  VeegilenzPortfolioDashboardConfig
} from '@/main/containers/dashboards/portfolio/veegilenz';
import dnaConfig from '@/config/dna.config.json';
import DashboardSection from '@/main/containers/dashboards/section';
import VeegilenzGlobalSegmentationChartWidget from '@/main/containers/veegilenz/globalveegilenz';
import VeegilenzCountriesListModal from '../veegilenzcountriesmodal';

const veegilenzConfig: VeegilenzPortfolioDashboardConfig[] = [
  {
    id: 'persons',
    entityType: 'deecPerson',
    icon: IconUsers,
    groupId: 'persons'
  },
  {
    id: 'companies',
    entityType: 'deecCompany',
    icon: IconBuildings,
    groupId: 'companies'
  }
];

function VeegilenzTabsPortfolios(): ReactElement {
  const { t } = useTranslation();
  const data = useOutletContext() as { data: BaseGroup };
  const navigate = useNavigate();
  const [countriesListModal, setCountriesListModal] = useState<boolean>(false);

  const callSegmentation = (entityType?: string, groupId?: string, workflowId?: string, filterIds?: string[], filterValues?: string[]) => {
    if (groupId && entityType) {
      navigate(`../${dnaConfig.routes.portfolios.childrens.augment.baseUrl}?entityType=${entityType}&groupId=${groupId}${workflowId ? `&workflowId=${workflowId}` : ''}${filterIds ? `&filterIds=${filterIds.join(',')}` : ''}${filterValues ? `&filterValues=${filterValues.join(',')}` : ''}`);
    }
  };

  const onModalOpen = () => {
    setCountriesListModal(true);
  };

  const onModalClose = () => {
    setCountriesListModal(false);
  };

  return (
    <Grid container overflow='auto' spacing={4}>

      <Grid sx={{ marginLeft: 'auto' }}>
        <Box>
          <Button variant='contained' onClick={onModalOpen} color='info' startIcon={<IconFlagSearch />}>
            {t('veegilenz.lists.countries.label')}
          </Button>
        </Box>
      </Grid>

      <Grid size={12} container>
        <Grid size='grow' minHeight='55vh' minWidth='min-content' flexDirection='row' container>
          {veegilenzConfig.map(config => (
            <Grid size='grow' display='flex'>
              <VeegilenzGlobalSegmentationChartWidget
                baseGroupId={data.data._id}
                config={config}
                callSegmentation={callSegmentation}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid size='grow' minWidth='min-content' container>
        {veegilenzConfig.map(config => (
          <Grid key={config.id} size='grow' minWidth='min-content' height='min-content'>
            <DashboardSection
              title={config.entityType === 'deecPerson' ? t('entities.persons.label') : t('entities.companies.label')}
              icon={config.icon}
              direction='column'
            >
              <VeegilenzPortfolioDashboard
                baseGroupId={data.data._id}
                config={config}
                callSegmentation={callSegmentation}
              />
            </DashboardSection>
          </Grid>
        ))}
      </Grid>

      <VeegilenzCountriesListModal
        open={countriesListModal}
        onClose={onModalClose}
      />

    </Grid>
  );
}

export default VeegilenzTabsPortfolios;
