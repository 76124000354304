import React from 'react';
import { IconFolders } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import ConfigurateNewBatch from './configurateNewBatch';

const routeBatchesConfigurationCreation: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.confNewBatch.i18nKey,
  path: dnaConfig.routes.admin.confNewBatch.baseUrl,
  i18nKey: dnaConfig.routes.admin.confNewBatch.i18nKey,
  icon: IconFolders,
  component: <>Import A csv to create a recordSet</>,
  idComponent: <ConfigurateNewBatch />,
  slug: 'recordSetId',
  idSlug: 'id'
});

export default routeBatchesConfigurationCreation;
