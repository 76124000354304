import React, { ReactElement } from 'react';
import { IconUsers } from '@tabler/icons-react';
import { Grid2, lighten, Stack, Typography, useTheme } from '@mui/material';
import { CategorieCard } from '../components/tabs/adminHomePage';
import IconBuildings from '@/assets/custom/IconBuildings';

function EntitiesRootView(): ReactElement {
  const theme = useTheme();
  const possibleEntityTypes: string[] = [
    'deecCompany',
    'deecPerson',
    'rncsCompany',
    'rncsPerson',
    'capfiCompany',
    'capfiPerson'
  ];
  const categorieCardConfiguration = [
    {
      title: 'Deecision Entities',
      pt: 0,
      cards: [
        {
          title: possibleEntityTypes[1],
          icon: <IconUsers size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: lighten(theme.palette.primary.main, 0.6),
          redirectTo: possibleEntityTypes[1]
        },
        {
          title: possibleEntityTypes[0],
          icon: <IconBuildings size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: lighten(theme.palette.secondary.main, 0.6),
          redirectTo: possibleEntityTypes[0]
        }
      ]
    },
    {
      title: 'RNCS entities',
      pt: 0,
      cards: [
        {
          title: possibleEntityTypes[3],
          icon: <IconUsers size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: lighten(theme.palette.primary.main, 0.6),
          redirectTo: possibleEntityTypes[3]
        },
        {
          title: possibleEntityTypes[2],
          icon: <IconBuildings size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: lighten(theme.palette.secondary.main, 0.6),
          redirectTo: possibleEntityTypes[2]
        }
      ]
    }
  ];

  return (
    <Stack>
      {categorieCardConfiguration.map(category =>
        <Stack borderRadius={1} p={4} pt={category.pt} spacing={2}>
          <Typography variant='h5'>
            {category.title}
          </Typography>
          <Grid2 container height='85%' spacing={2}>
            {category.cards?.map(card =>
              <Grid2 minWidth={400} p={2}>
                <CategorieCard title={card.title} icon={card.icon} bgcolor={card.bgColor} redirectLink={card.redirectTo}/>
              </Grid2>
            )}
          </Grid2>
        </Stack>
      )}
    </Stack>
  );
}

export default EntitiesRootView;