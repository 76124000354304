import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  SegmentationData, SegmentationPossibleDisplayTypes, SegmentationPossibleFilter
} from '@deecision/dna-interfaces';
import { undefined } from 'zod';
import { WidgetSection } from './types';
import SegmentationServices, { CustomSegmentationFiltersService } from '../../segmentations/services';
import Dispatch from '@/main/containers/segmentations/render/charts/dispatch';
import { getBaseChartHeight } from '@/components/charts/utils';
import useWindowDimensions from '@/hooks/window-dimensions';

function PortfolioDashboardWidgetSegmentation(props: WidgetSection): ReactElement {
  const segmentationServices = new SegmentationServices();
  const possibleCriteriasService = new CustomSegmentationFiltersService<SegmentationPossibleFilter>();
  const { id } = useParams();
  const { height } = useWindowDimensions();
  const [displayInfo, setDisplayInfo] = useState<SegmentationPossibleDisplayTypes>(props.displayInfo?.displayType || 'barChart');
  const [segmentationData, setSegmentationData] = useState<SegmentationData>();
  const [possibleCriterias, setPossibleCriterias] = useState<SegmentationPossibleFilter[]>();
  const possibleCriteria = possibleCriterias?.find(criteria => criteria.id === props.criteria.filterId);

  const getCriterias = () => {
    possibleCriteriasService.getAll({ filters: [{ scope: 'on', id: 'on', value: 'person' }] })
      .then((res) => {
        setPossibleCriterias(res.data);
      });
  };

  const getSegmentationData =  () => {
    segmentationServices.perform({
      dataSetId: 'deecPersons',
      globalFilteringItems: [],
      filters: [
        {
          id: 'person_groupMember',
          filterId: 'person_groupMember',
          type: 'filter',
          on: 'person1',
          values: [
            `${id}/persons`
          ]
        }
      ],
      entitiesSettings: {
        includeEntities: true,
        findOptions: {}
      },
      outputEntities: 'person1',
      segmentationCriterias: [props.criteria]
    })
      .then(res => setSegmentationData(res.data));
  };

  useEffect(() => {
    getSegmentationData();
    getCriterias();
  }, [props.criteria]);

  return (possibleCriteria ?
    <Dispatch
      criteria={{
        possibleDisplayTypes: possibleCriteria?.possibleDisplayTypes || [],
        on: possibleCriteria?.on || 'person',
        valueType: possibleCriteria?.valueType || 'number',
        values: segmentationData?.segmentationCriterias[props.criteria.id].values,
        isMultipleValuesOrRangesEnabled: true,
        id: props.criteria.id,
        filterId: props.criteria.filterId || 'unknown',
        label: props.criteria.label,
        canUseForSegmentation: possibleCriteria?.canUseForSegmentation || true,
        categories: possibleCriteria?.categories || [],
        filterLabel: possibleCriteria?.label || 'unknown',
        displayInfo: {
          displayType: displayInfo,
          valueType: 'number'
        }
      }}
      filters={[]}
      addFilter={filter => props.callSegmentation(
        'deecPerson',
        'persons',
        'person1_link_person2',
        [props.criteria.filterId || ''],
        [filter.values?.join(',') || '']
      )}
      removeFilter={() => undefined}
      height={getBaseChartHeight(height)}
      handleChangeDisplayType={dt => setDisplayInfo(dt)}
    />
    : <></>
  );
}

export default PortfolioDashboardWidgetSegmentation;
