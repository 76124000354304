import React, { ReactElement, useEffect, useState } from 'react';
import { AsyncStep } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';
import { IconNumber123 } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import TitleComponent from '@/components/title';
import { DnaStepsService } from '@/api/services/batches';
import KeyValueList from '@/components/keyvalue/keyvaluelist';
import BaseWidget from '@/main/containers/widgets/base-widget';

function StepDetailsHeader(): ReactElement {
  const { id } = useParams();
  const stepServices = new DnaStepsService();
  const [stepData, setStepData] = useState<{step: AsyncStep, subStepsTree: AsyncStep[]}>();

  const fetchStepDataFromId = (stepId: string) => {
    stepServices.getStepAndSubSteps(stepId)
      .then((res) => {
        if (res.data) {
          setStepData(res.data);
        }
      });
  };

  const stepDatas = stepData ? [
    { key: 'Id', value: stepData?.step._id },
    { key: 'Type', value: stepData?.step.type },
    { key: 'Level', value: stepData?.step.level },
    { key: 'Priority', value: stepData?.step.priority },
    { key: 'Status', value: stepData?.step.status }
  ] : [];

  const parentStepDatas = stepData ? [
    { key: 'ParentStepId', value: stepData?.step.parentStep || 'None', redirectTo: stepData?.step.parentStep ? `/dna/admin/steps/${stepData?.step.parentStep}` : undefined },
    { key: 'parentsHierarchy', value: stepData?.step?.parentsHierarchy?.join(', ') }
  ] : [];

  const subStepDatas = stepData ? [
    { key: 'Nb subStep Found', value: stepData?.subStepsTree?.length || '0' }
  ] : [];

  const relatedBatchDatas = stepData ? [
    { key: 'Batch Id', value: stepData?.step.contextData?.batch?._id, redirectTo: stepData?.step.contextData?.batch?._id ? `/dna/admin/batches/${stepData?.step.contextData?.batch?._id}` : undefined },
    { key: 'RecordSet Id', value: stepData?.step.contextData?.batch?.recordSetId },
    { key: 'Status', value: stepData?.step.contextData?.batch?.status }
  ] : [];

  const relatedRecordSetDatas = stepData ? [
    { key: 'RecordSet Id', value: stepData?.step.contextData?.batchRecordSet?._id, redirectTo: stepData?.step.contextData?.batchRecordSet?._id ? `/dna/admin/recordSets/${stepData?.step.contextData?.batchRecordSet?._id}` : undefined  },
    { key: 'recordSet Code', value: stepData?.step.contextData?.batchRecordSet?.recordSetCode },
    { key: 'originalFileName', value: stepData?.step.contextData?.batchRecordSet?.originalFileName }
  ] : [];

  useEffect(() => {
    if (id) {
      fetchStepDataFromId(id);
    }
  }, [id]);

  return (
    <Grid container width='100%' direction='column' spacing={4}>
      <Grid>
        <TitleComponent title={`Step ${stepData?.step._id}`} icon={IconNumber123} />
      </Grid>
      
      <Stack spacing={2} direction='row'>
        <BaseWidget title='Step'>
          <KeyValueList values={[stepDatas]}/>
        </BaseWidget>

        <BaseWidget title='Parent Step'>
          <KeyValueList values={[parentStepDatas]}/>
        </BaseWidget>

        <BaseWidget title='Children Steps'>
          <KeyValueList values={[subStepDatas]}/>
        </BaseWidget>

        <BaseWidget title='Related Batch Info'>
          <KeyValueList values={[relatedBatchDatas]}/>
        </BaseWidget>

        <BaseWidget title='Related RecordSet Info'>
          <KeyValueList values={[relatedRecordSetDatas]}/>
        </BaseWidget>
      </Stack>

    </Grid>
  );
}

export default StepDetailsHeader;