import {
  GroupDesc,
  SegmentationCriteriaSpec, SegmentationPossibleDisplayTypes,
  SegmentationPossibleValueTypes,
  SegmentationRequest
} from '@deecision/dna-interfaces';
import { Icon, IconProps, TablerIcon } from '@tabler/icons-react';
import React from 'react';

export interface CustomGroupValues extends Pick<GroupDesc, 'subGroupId'> {
  augmented?: boolean,
  type?: 'select' | 'segmentation' | 'parent' | 'custom',
  icon?: TablerIcon,
  img?: string,
  description?: boolean,
  link?: string,
  options?: string[],
  segmentationCriterias?: SegmentationCriteriaSpec[],
  subGroups?: CustomGroupValues[]
}

export interface BasePortfolioDashboardProps {
  baseGroupId: string,
  group: CustomGroupValues,
  callSegmentation: (entityType?: string, groupId?: string, workflowId?: SegmentationRequest['workflowId'], filterIds?: string[], filterValues?: string[]) => void
}

export interface WidgetSection {
  baseGroupId: string,
  displayInfo?: {
    displayType: SegmentationPossibleDisplayTypes,
    valueType: SegmentationPossibleValueTypes
  },
  criteria: SegmentationCriteriaSpec,
  callSegmentation: (entityType?: string, groupId?: string, workflowId?: SegmentationRequest['workflowId'], filterIds?: string[], filterValues?: string[]) => void
}

export interface DashboardSection {
  id: string,
  type: 'full' | 'compact',
  noLabel?: boolean,
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<Icon>>,
  groups?: CustomGroupValues[],
  widgets?: Omit<WidgetSection, 'callSegmentation'>[]
}

export interface DashboardConfig {
  sections: DashboardSection[]
}

export const basePaperSx = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  p: 1
};

export const dashboardSpacing = 2;
