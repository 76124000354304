import React, { ReactElement, useEffect, useState } from 'react';
import { RecordSetInterface } from '@deecision/dna-interfaces';
import { useParams } from 'react-router-dom';
import { JsonEditor } from 'json-edit-react';
import { Grid2 } from '@mui/material';
import { BatchesRecordSetsService } from '@/api/services/batches';

function RecordSetTabsSelfObj(): ReactElement {
  const { id } = useParams();
  const recordSetServices = new BatchesRecordSetsService();
  const [data, setData] = useState<RecordSetInterface>();

  const fetchData = (recordSetId: string) => {
    recordSetServices.get(recordSetId)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch(err => alert(err));
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <Grid2 container spacing={2} alignItems='center'>
      {data &&
        <JsonEditor
          data={data}
          viewOnly
          rootName='RecordSet'
        />
      }
    </Grid2>
  );
}

export default RecordSetTabsSelfObj;