import React from 'react';
import { IconUserScan } from '@tabler/icons-react';
import { ROLES } from '@deecision/dna-interfaces/dist/constants/roles';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import EntitiesRootView from './entitiesRootView';

const routeEntities: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.entities.i18nKey,
  path: dnaConfig.routes.admin.entities.baseUrl,
  i18nKey: dnaConfig.routes.admin.entities.i18nKey,
  icon: IconUserScan,
  asA: ROLES.DEVELOPER,
  component: <EntitiesRootView />,
  displayIndex: true
});

export default routeEntities;
