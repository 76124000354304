import React, { ReactElement } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';

interface StatusCardProps {
  statusType: string,
  count: number,
  totalCount: number,
  onClick: () => void,
  bgColor?: string
}

function StatusCard(props: StatusCardProps): ReactElement {
  const percentage = ((props.count/props.totalCount) * 100).toPrecision(2);

  return (
    <Paper variant='hoverElevation1'>
      <Stack alignItems='center' justifyContent='center'>
        <Box>
          <Typography fontWeight='bold'>{props.statusType}</Typography>
        </Box>
        <Box>
          <Typography>{props.count} ({percentage}%) </Typography>
        </Box>
      </Stack>
    </Paper>
  );
}

export default StatusCard;