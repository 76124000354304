import React from 'react';
import { Icon123 } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import StepDetailsHeader from './stepDetailsHeader';
import SubStepTabsListing from './tabs/subStepListing.tabs';
import StepTabsSelfObj from './tabs/subStep.tabs.selfObj';
import StepListing from './stepListing';

const recordSetDetailTabs: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.admin.steps.tabs.listing.label,
    i18nKey: dnaConfig.routes.admin.steps.tabs.listing.i18nKey,
    path: dnaConfig.routes.admin.steps.tabs.listing.baseUrl,
    element: <SubStepTabsListing />
  },
  {
    id: dnaConfig.routes.admin.steps.tabs.details.label,
    i18nKey: dnaConfig.routes.admin.steps.tabs.details.i18nKey,
    path: dnaConfig.routes.admin.steps.tabs.details.baseUrl,
    element: <StepTabsSelfObj />
  }
];

const routeSteps: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.steps.i18nKey,
  path: dnaConfig.routes.admin.steps.baseUrl,
  i18nKey: dnaConfig.routes.admin.steps.i18nKey,
  icon: Icon123,
  component: <StepListing />,
  idComponent: <StepDetailsHeader />,
  displayIndex: true,
  tabs: recordSetDetailTabs
});

export default routeSteps;
