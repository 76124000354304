import { ApiResponse, ApiArrayResponse } from '@deecision/deecision-interfaces';
import axiosInstance from '../axios/instance.axios';
import { BaseService } from './base.api';

export interface AllStatsInterface {
  done: number,
  failed: number,
  requested: number,
  running: number,
  /* eslint-disable @typescript-eslint/naming-convention */
  update_needed: number,
  waiting_for_dependencies: number
  /* eslint-disable @typescript-eslint/naming-convention */
}

export interface StatsByStatusInterface {
  _id: string,
  connectorType: string,
  avgPrio: number,
  minPrio: number,
  maxPrio: number,
  count: number
}

export class DataElementsStatsService extends BaseService{
  constructor() {
    super({ axiosInstance, elementPrefixUrl: 'data-elements' });
  };

  get(id?: string): Promise<ApiResponse<unknown> | ApiArrayResponse<unknown>> {
    throw new Error(`Method not implemented. ${id}`);
  }

  getAll(props?: unknown): Promise<ApiResponse<unknown> | ApiArrayResponse<unknown>> {
    throw new Error(`Method not implemented.${props} `);
  }

  async getAllStatsByStatus(): Promise<ApiResponse<AllStatsInterface>> {
    return this.axiosInstance.get(this.constructUrl(`/stats/byStatus`))
      .then(res => this.handleResponse<AllStatsInterface>(res));
  }

  async getDEsByStatusType(status: string[]): Promise<ApiResponse<{stats: StatsByStatusInterface[], total: number }>>{
    let params = '';
    if (status.length !== 0) {
      params = `${status.map((sts, index) => `status=${sts}${index + 1 === status.length ? '' :  '&'}`)}`;
    }
    
    return this.axiosInstance.get(`${this.constructUrl(`/stats/typesWithStatus`)}?${params}`)
      .then(res => this.handleResponse<{stats: StatsByStatusInterface[], total: number }>(res));
  }

  async getDEsByStructureChanged(): Promise<ApiResponse<{stats: StatsByStatusInterface[], total: number }>>{
    return this.axiosInstance.get(this.constructUrl(`stats/typesWithDepsStructureChanged?value=true`))
      .then(res => this.handleResponse<{stats: StatsByStatusInterface[], total: number }>(res));
  }

  async getDEsByRecentCompleted(duration?: string): Promise<ApiResponse<{stats: StatsByStatusInterface[], total: number }>>{
    const durationParams = duration || 7200;
    
    return this.axiosInstance.get(this.constructUrl(`stats/recentCompletedExecs?duration=${durationParams}`))
      .then(res => this.handleResponse<{stats: StatsByStatusInterface[], total: number }>(res));
  }

}