import React from 'react';
import { ROLES } from '@deecision/dna-interfaces/dist/constants/roles';
import { IconHammer } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import AdminActionsHub from './actionsHub';

const routeSuperAdminActions: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.actions.i18nKey,
  path: dnaConfig.routes.admin.actions.baseUrl,
  i18nKey: dnaConfig.routes.admin.actions.i18nKey,
  icon: IconHammer,
  asA: ROLES.SUPER_ADMIN,
  component: <AdminActionsHub />,
  displayIndex: true
});

export default routeSuperAdminActions;