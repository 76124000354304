import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { AmfSummaryDataBlockV1, PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import {
  IconAlertTriangleFilled, IconCheck,
  IconDatabase,
  IconGrave,
  IconHome,
  IconId, IconInfoCircle,
  IconRefresh
} from '@tabler/icons-react';
import { Typography } from '@mui/material';
import { startCase } from 'lodash';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CountriesFlags from '@/components/countriesflags';
import { PersonGetter } from '../../providers/getter';
import Completeness from '../../../components/chips/completeness';
import Accuracy from '../../../components/chips/accuracy';
import Status from '../../../components/status';
import AmfSummaryTooltip from '../tooltips/amf-summary-tooltip';
import dnaConfig from '@/config/dna.config.json';
import RncsOriginChip from './rncsOriginChip';

function PersonEntitiesChips(props: { entity: PersonEntity, statusComplete?: boolean, size?: 'small' | 'medium' }): ReactElement[] {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [entity, setEntity] = useState<PersonEntity>(props.entity as PersonEntity);
  const personGetter = useMemo(() => new PersonGetter({
    data: entity,
    errorMsg: t('common.utils.unknown')
  }), [entity]);

  /*
  const completenessArray = [
    parseFloat(personGetter.getWeasseCompleteness().toString()),
    parseFloat(personGetter.getWeasseMaxCompleteness().toString()),
    parseFloat(personGetter.getTurnoverSumCompleteness().toString()),
    parseFloat(personGetter.getCapitalSumCompleteness().toString()),
    parseFloat(personGetter.getEbitdaSumCompleteness().toString()),
    parseFloat(personGetter.getValuationSumCompleteness().toString())
  ];
  */

  useEffect(() => {
    setEntity(props.entity as PersonEntity);
  }, [props.entity]);

  return ([
    <Tooltip
      key='common.identity.nationality'
      title={`${personGetter.getNationalitiesCodes().length < 2 ? t('common.identity.nationality') : t('common.identity.nationalities')}: ${personGetter.getNationalities().map(country => startCase(country.toLowerCase())).join(', ')}`}
      arrow
      placement='top'
    >
      <Chip
        label={
          <Stack direction='row' height='20px' spacing={1} alignItems='center' divider={<Divider orientation='vertical' />} sx={{ mr: 1 }}>
            {CountriesFlags({ countries: personGetter.getNationalitiesCodes(), size: props.size })}
          </Stack>
        }
        icon={<IconId size={props.size === 'small' ? '1.3rem' : '1.5rem'} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>,
    <Tooltip
      key='common.identity.country'
      title={`${personGetter.getCountriesCodes().length < 2 ? t('common.identity.country') : t('common.identity.countries')}: ${personGetter.getCountries().map(country => startCase(country.toLowerCase())).join(', ')}`}
      arrow
      placement='top'
    >
      <Chip
        label={
          <Stack direction='row' height='20px' spacing={1} alignItems='center' divider={<Divider orientation='vertical' />} sx={{ mr: 1 }}>
            {CountriesFlags({ countries: personGetter.getCountriesCodes(), size: props.size })}
          </Stack>
        }
        icon={<IconHome size={props.size === 'small' ? '1.3rem' : '1.5rem'} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>,
    <Completeness
      key='completenessTitleChip'
      completeness={personGetter.getPersonCompleteness()}
      size={props.size || 'medium'}
      icon={IconDatabase}
    />,
    personGetter.getIsDead() ?
      <Tooltip
        key='deceased-chip'
        title={
          <Typography>
            {t('common.utils.deceased')} ({personGetter.getDeathDate()}) <Accuracy accuracy={personGetter.getDeathMatchAccuracy()}/>
          </Typography>
        }
        arrow
        placement='top'
      >
        <Chip
          label={t('common.utils.deceased')}
          icon={<IconGrave size={props.size === 'small' ? '1.3rem' : '1.5rem'} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
          color='error'
          size={props.size}
        />
      </Tooltip>
      : <></>,
    ...personGetter.getTags().filter(tag => tag.family !== 'batchTag').map(tag => (
      <Tooltip key={`${tag.value}-${tag.family}`} title={t(`tags.families.${tag.family}`)} arrow placement='top'>
        <Chip label={t(`tags.values.${tag.value}`)} size={props.size} />
      </Tooltip>
    )),
    <Tooltip
      key='veegilenz-chip'
      title={
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='body2'>{personGetter.getVeegilenzReason() !== t('common.utils.unknown') ? t(`veegilenz.reason.${personGetter.getVeegilenzReason() || 'noReason'}`) : t('veegilenz.errorResponse.notSearched')}</Typography>
          {personGetter.getVeegilenzReason() !== t('common.utils.unknown') &&
            <Status
              statusAsInt={
                personGetter.getVeegilenzReason() === 'atLeastOneBlackFact' && 3 ||
                  personGetter.getVeegilenzReason() === 'atLeastOneRedFact' && 2 ||
                  personGetter.getVeegilenzReason() === 'atLeastOneOrangeFact' && 1 ||
                  personGetter.getVeegilenzReason() === 'moreThanTreeOrangeFacts' && 1 ||
                  personGetter.getVeegilenzReason() === 'onlyGreenFacts' && 0 ||
                  0
              }
              size='1rem'
            />
          }
        </Stack>
      }
      arrow
      placement='top'
    >
      <Chip
        label={t('veegilenz.label')}
        icon={<Box pl={1} display='flex' justifyContent='center'>
          <Status statusAsInt={personGetter.getVeegilenzLevel(true) as number} size={props.size === 'small' ? '1.3rem' : '1.5rem'} dark color={personGetter.getVeegilenzLevel() === t('common.utils.unknown') ? theme.palette.grey[700] : undefined} />
        </Box>}
        color={personGetter.getVeegilenzLevel(true) === 2 ? 'error' : personGetter.getVeegilenzLevel(true) === 1 ? 'warning' : personGetter.getVeegilenzLevel() === t('common.utils.unknown') ? undefined : personGetter.getVeegilenzLevel(true) === 0 ? 'success' : undefined}
        sx={{
          bgcolor: personGetter.getVeegilenzLevel(true) === 3 ? theme.palette.grey[800] : undefined,
          '& .MuiChip-label': {
            color: personGetter.getVeegilenzLevel(true) === 3 ? theme.palette.grey[200] : undefined
          }
        }}
        size={props.size}
      />
    </Tooltip>,
    personGetter.isAmfRelated()
      ? <Tooltip key='amf-chip' title={<AmfSummaryTooltip dataBlock={personGetter.getAmfSummary() as AmfSummaryDataBlockV1} />} arrow placement='top'>
        <Chip
          color='error'
          label={
            <Stack spacing={1} direction='row' alignItems='center'>
              <IconAlertTriangleFilled
                size='1rem'
                color={theme.palette.error.dark}
              />
              <Typography>{t('amf.chip.label')}</Typography>
            </Stack>
          }
        />
      </Tooltip>
      : <></>,
    personGetter.getHighestPoliticalExposureLevel()
      ?
      <Tooltip key='pep-chip' title={t(`politicalExposure.label`)} arrow placement='top'>
        <Chip
          /*
        icon={
          <IconMoneybag size={props.size === 'small' ? '1.3rem' : '1.5rem'} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />
        }
        */
          size={props.size}
          color='error'
          label={`${t(`politicalExposure.shortLabel`)} ${t(`politicalExposure.values.${personGetter.getHighestPoliticalExposureLevel()}`)}`}/>
      </Tooltip>
      : <></>,
    ...personGetter.getSanctions().map(sanction => (
      <Tooltip key={sanction} title={t(`veegilenz.sanctionType.${sanction}`)} arrow placement='top'>
        <Chip icon={<IconAlertTriangleFilled/>} label={t(`veegilenz.sanctionType.${sanction}`)} size={props.size}
          color='error'/>
      </Tooltip>
    )),
    <Tooltip
      key='common.dates.lastUpdate'
      title={t('common.dates.lastUpdate')}
      arrow
      placement='top'
    >
      <Chip
        label={personGetter.getDataDate()}
        icon={<IconRefresh size={props.size === 'small' ? '1.3rem' : '1.5rem'}
          style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>,
    props.statusComplete !== undefined
      ? <Tooltip
        key='statusComplete'
        title={personGetter.isLevelMax() ? t('common.status.isLevelMaxTooltip') : undefined}
        color={personGetter.isLevelMax() ? 'primary' : props.statusComplete ? 'success' : 'warning'}
        arrow
        placement='top'
        onClick={() => navigate(dnaConfig.routes.companies.tabs.origeen.baseUrl)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            color: theme.palette.primary.contrastText
          }
        }}
      >
        {personGetter.isLevelMax()
          ? <Chip
            label={t('common.status.isLevelMax')}
            icon={<IconInfoCircle size={props.size === 'small' ? '1.3rem' : '1.5rem'} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
            size={props.size}
          />
          : <Chip
            label={props.statusComplete ? t('common.status.complete') : t('common.status.incomplete')}
            icon={props.statusComplete ?<IconCheck size={props.size === 'small' ? '1.3rem' : '1.5rem'} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} /> : <IconAlertTriangleFilled size={props.size === 'small' ? '1.3rem' : '1.5rem'} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
            size={props.size}
          />
        }
      </Tooltip>
      : <></>,
    <RncsOriginChip key='rncs.origin' rncsOrigin={personGetter.getRncsOrigin()} />
  ]
  );
}

export default PersonEntitiesChips;
