import React, { Dispatch, ReactElement, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { IconChevronDown } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface AccordionProps {
  title?: string | ReactNode,
  defaultOpen?: boolean,
  disabled?: boolean,
  bgcolor?: string,
  detectHover?: boolean,
  borderColor?: string,
  setRef?: Dispatch<SetStateAction<HTMLDivElement | null>>,
  children?: ReactNode,
  onOpenChange?: (open: boolean) => void,
  disableAnimation?: boolean
}

function CustomAccordion(props: AccordionProps): ReactElement {
  const [open, setOpen] = useState(!!props.defaultOpen && !props.disabled);
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const handleOpen = () => {
    if (!open) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 400);
    }
    setOpen(prevState => !prevState);
  };

  useEffect(() => {
    setOpen(!!props.defaultOpen && !props.disabled);
  }, [props.disabled]);

  useEffect(() => {
    if (props.setRef) {
      props.setRef(ref.current);
    }
  }, [ref]);

  useEffect(() => {
    if (props.onOpenChange) {
      props.onOpenChange(open);
    }
  }, [open]);

  return (
    <Accordion
      ref={ref}
      slotProps={props.disableAnimation ? {
        transition: { timeout: 0 }
      } : undefined}
      sx={{
        bgcolor: props.bgcolor || 'transparent',
        width: '100%',
        p: 0,
        '&.Mui-expanded': {
          margin: '0 !important'
        },
        borderBottom: 'solid 1px',
        borderColor: props.borderColor,
        borderBottomColor: 'transparent',
        '&:last-of-type': {
          borderColor: props.borderColor || 'rgb(206, 212, 218)'
        },
        '&:hover': {
          borderBottom: props.detectHover ? 'solid 1px' : undefined,
          borderColor: props.detectHover ? theme.palette.primary.main : undefined
        }
      }}
      expanded={open && !props.disabled}
      onChange={handleOpen}
      disabled={props.disabled}
    >
      <AccordionSummary
        sx={{
          p: 4,
          pt: 0,
          pb: 0,
          bgcolor: 'transparent !important'
        }}
        expandIcon={<IconChevronDown size='1.2rem' />}
      >
        {typeof props.title === typeof '' ?
          <Typography variant='h4'>{props.title}</Typography> :
          props.title
        }
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0
        }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}

export default CustomAccordion;
