import React from 'react';
import { ROLES } from '@deecision/dna-interfaces/dist/constants/roles';
import { IconBuildingFactory2 } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import BatchesListing from './batchesListing';
import BatchesDetailsHeader from './batchesDetailsHeader';
import BatchesTabsSelfObj from './tabs/batches.tabs.selfObj';

const batchTabs: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.admin.batches.tabs.details.label,
    i18nKey: dnaConfig.routes.admin.batches.tabs.details.i18nKey,
    path: dnaConfig.routes.admin.batches.tabs.details.baseUrl,
    element: <BatchesTabsSelfObj />
  }
];

const routeBatches: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.batches.i18nKey,
  path: dnaConfig.routes.admin.batches.baseUrl,
  i18nKey: dnaConfig.routes.admin.batches.i18nKey,
  icon: IconBuildingFactory2,
  asA: ROLES.DEVELOPER,
  component: <BatchesListing />,
  idComponent: <BatchesDetailsHeader />,
  displayIndex: true,
  tabs: batchTabs
});

export default routeBatches;
