import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Button, Chip, IconButton, Stack, Tooltip } from '@mui/material';
import { IconArrowRight, IconDeviceDesktopAnalytics, IconRefresh } from '@tabler/icons-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AllStatsInterface, DataElementsStatsService } from '@/api/services/data.elements.stats.api';
import { CurrencyFormatterProps } from '@/utils/currencyFormatter';
import EvenOddTable from '@/components/tables/evenodd/table';
import { Column, Row } from '@/components/tables/types';
import { schedulingStatusColorMap } from '../Entities/entityPage/utils/statusColorMapping';
import TitleComponent from '@/components/title';

function DataElementsStatsView(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const statsServices = new DataElementsStatsService();
  const [data, setData] = useState<AllStatsInterface>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(true);

  const onStatusClick = (status?: string) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('status', status || '');
    navigate(`/admin/dataElementsStatus/details/byType?${newParams.toString()}`);
  };

  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'status', label: 'Status' },
    { id: 'count', label: 'Count' },
    { id: 'percentage', label: '%' },
    { id: 'actions', label: 'Actions' }
  ];

  const rows: Row<CurrencyFormatterProps>[] = data ?
    Object.entries(data).map(([key, value]) => ({
      id: key,
      status:
          <Box onClick={() => onStatusClick(key)} sx={{ cursor: 'pointer' }} width='min-content'>
            <Chip label={key} color={schedulingStatusColorMap[key]} size='small' />
          </Box>,
      count: value,
      percentage: ((value/totalCount) * 100).toPrecision(2),
      actions:
        <Stack direction='row'>
          <Tooltip arrow title={`See DataElements with ${key} status`}>
            <IconButton onClick={() => {
              navigate(`/admin/dataElementsStatus/details/byType?status=${key}`);
            }}>
              <IconArrowRight color='blue' size='1.2rem'/>
            </IconButton>
          </Tooltip>
        </Stack>
    }))
      .sort((a, b) => b.count - a.count)
    :
    [];

  const fetchData = () => {
    setRefresh(false);
    statsServices.getAllStatsByStatus()
      .then((res) => {
        if (res.data) {
          let count = 0;
          Object.values(res.data).forEach((value) => {
            count += value;
          });
          setTotalCount(count);
          setData(res.data);
        }
      });
  };

  const refreshData = () => {
    setRefresh(true);
  };

  useEffect(() => {
    if (refresh) {
      fetchData();
    }
  }, [refresh]);

  return (
    <Stack spacing={4}>
      <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between'>
        <TitleComponent title='Data Elements Monitoring' icon={IconDeviceDesktopAnalytics} />
        <Stack direction='row'>
          <Box>
            <Tooltip arrow title='Refresh Data' placement='top'>
              <IconButton title='Refresh' onClick={refreshData}>
                <IconRefresh />
              </IconButton>
            </Tooltip>
          </Box>
          <Button variant='contained' onClick={() => navigate(`/admin/dataElementsStatus/details/byType`)}>
            {t('common.utils.details')}
          </Button>
        </Stack>
      </Stack>

      {data ?
        <EvenOddTable<CurrencyFormatterProps>
          label='Stats'
          columns={columns}
          rows={rows || []}
          totalCount={totalCount}
          hideFooter
        />
        :
        null
      }
    </Stack>
  );
}

export default DataElementsStatsView;