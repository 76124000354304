import React, { ReactElement, useEffect, useState } from 'react';
import { AsyncStep } from '@deecision/dna-interfaces';
import { useParams } from 'react-router-dom';
import { JsonEditor } from 'json-edit-react';
import { Grid2 } from '@mui/material';
import { DnaStepsService } from '@/api/services/batches';

function StepTabsSelfObj(): ReactElement {
  const { id } = useParams();
  const stepServices = new DnaStepsService();
  const [data, setData] = useState<AsyncStep>();

  const fetchData = (stepId: string) => {
    stepServices.get(stepId)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch(err => alert(err));
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <Grid2 container spacing={2} alignItems='center'>
      {data &&
        <JsonEditor
          data={data}
          viewOnly
          rootName='Step'
        />
      }
    </Grid2>
  );
}

export default StepTabsSelfObj;