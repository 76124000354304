import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import { IconChartBar, IconChartDonut, IconGripVertical, IconTrash } from '@tabler/icons-react';
import IconButton from '@mui/material/IconButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Select from '@mui/material/Select';
import { SegmentationPossibleFilter } from '@deecision/dna-interfaces';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material';
import { startCase } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { CustomSegmentationData, PotentialSegmentationCriteria } from '../../types';
import { PossibleCriteriasTreeView } from './add';
import ClassificationChip from '../../components/classification.chips';

interface ListCriteriasBuilderSegmentationProps {
  possibleCriterias: SegmentationPossibleFilter[],
  potentialSegmentationCriterias: CustomSegmentationData['potentialSegmentationCriterias'],
  updateSegmentationPotentialCriteria: (potentialCriterias: PotentialSegmentationCriteria[]) => void,
  replaceSegmentationPotentialCriteria: (possibleCriteria: SegmentationPossibleFilter, oldId: string) => void,
  deleteSegmentationPotentialCriteria: (potentialCriteriaIds: string[]) => void
}

interface CriteriaForListCriteriasBuilderSegmentationProps {
  potentialSegmentationCriteria: CustomSegmentationData['potentialSegmentationCriterias'][0],
  index: number,
  moveCriteria: (dragIndex: number, hoverIndex: number) => void,
  setIsDragging: Dispatch<SetStateAction<boolean>>
}

interface DragItem {
  id: string,
  index: number
}

function CriteriaForListCriteriasBuilderSegmentation(props: ListCriteriasBuilderSegmentationProps & CriteriaForListCriteriasBuilderSegmentationProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'criteria',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor) {
      const clientOffset = monitor.getClientOffset();

      if (!ref.current) {
        return;
      }

      if  (clientOffset && clientOffset.y < 220) {
        document.getElementById('list-criterias-builder-segmentation')?.scrollIntoView({ behavior: 'smooth', block: 'start' });

        return;
      }

      if (clientOffset && clientOffset.y > window.innerHeight - 40) {
        document.getElementById('list-criterias-builder-segmentation')?.scrollIntoView({ behavior: 'smooth', block: 'end' });

        return;
      }

      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      props.moveCriteria(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'criteria',
    item: () => ({ id: props.potentialSegmentationCriteria.id, index: props.index }),
    collect: (monitor: { isDragging: () => unknown }) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  useEffect(() => {
    props.setIsDragging(!!isDragging);
    if (isDragging) {
      const cursorStyle = document.createElement('style');
      cursorStyle.innerHTML = '*{cursor: grabbing!important;}';
      cursorStyle.id = 'cursor-style';
      document.head.appendChild(cursorStyle);
    } else {
      document.getElementById('cursor-style')?.remove();
    }
  }, [isDragging]);

  return (
    <Paper
      data-handler-id={handlerId}
      ref={ref}
      sx={{
        width: '100%',
        bgcolor: theme.palette.grey[50],
        opacity,
        cursor: isDragging ? 'grabbing' : 'grab',
        '&:active': {
        }
      }}
    >
      <Stack direction='row' spacing={2} alignItems='center' width='100%'>
        <IconGripVertical size='1.6rem' style={{ cursor: 'move', minWidth: 'min-content' }} />
        <Stack direction='row' spacing={2} alignItems='center' overflow='auto'>
          <Select
            value={props.potentialSegmentationCriteria.filterId}
            variant='outlined'
            size='small'
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            <MenuItem value={props.potentialSegmentationCriteria.filterId} disabled>{props.potentialSegmentationCriteria.label}</MenuItem>
            <PossibleCriteriasTreeView {...props} addSegmentationCriterias={possibleCriterias => props.replaceSegmentationPotentialCriteria(possibleCriterias[0], props.potentialSegmentationCriteria.filterId)} addSegmentationCriteriaCategory={() => undefined} handleClose={handleClose} disableAddAll />
          </Select>
          {props.potentialSegmentationCriteria.classifications?.map(label =>
            <ClassificationChip label={label} />
          )}
          <Chip
            label={t(`entities.${props.potentialSegmentationCriteria.on}.multiple`)}
            sx={{
              bgcolor: props.potentialSegmentationCriteria.on === 'person' ? 'primary.light' : props.potentialSegmentationCriteria.on === 'company' ? 'secondary.light' : 'warning.light',
              color: props.potentialSegmentationCriteria.on === 'person' ? 'primary.dark' : props.potentialSegmentationCriteria.on === 'company' ? 'secondary.dark' : 'warning.dark'
            }}
            size='small'
          />
          {props.potentialSegmentationCriteria.categories.map(category => (
            <Tooltip key={category} title={t('common.utils.category')} arrow placement='top'>
              <Chip
                label={startCase(category.split(/(?=[A-Z])/).join(' '))}
                size='small'
              />
            </Tooltip>
          ))}
        </Stack>
        <div style={{ marginLeft: 'auto' }} />
        <ToggleButtonGroup
          value={props.potentialSegmentationCriteria.displayInfo.displayType}
          size='small'
          color='primary'
          sx={{ minWidth: 'min-content' }}
        >
          {props.potentialSegmentationCriteria.possibleDisplayTypes.map(displayType => (
            <ToggleButton
              key={displayType}
              value={displayType}
              onClick={() => props.updateSegmentationPotentialCriteria([...props.potentialSegmentationCriterias.map(psc => (psc.id === props.potentialSegmentationCriteria.id ? { ...psc, displayInfo: { ...psc.displayInfo, displayType } } : psc))])}
            >
              {displayType === 'barChart' && <IconChartBar size='1.2rem' />}
              {displayType === 'pieChart' && <IconChartDonut size='1.2rem' />}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <IconButton size='small' color='error' onClick={() => props.deleteSegmentationPotentialCriteria([props.potentialSegmentationCriteria.id])}>
          <IconTrash size='1.2rem' />
        </IconButton>
      </Stack>
    </Paper>
  );
}

function ListCriteriasBuilderSegmentation(props: ListCriteriasBuilderSegmentationProps): ReactElement[] {
  const [isDragging, setIsDragging] = useState(false);
  const [potentialSegmentationCriterias, setPotentialSegmentationCriterias] = useState<CustomSegmentationData['potentialSegmentationCriterias']>(props.potentialSegmentationCriterias);

  const moveCriteria = useCallback((dragIndex: number, hoverIndex: number) => {
    setPotentialSegmentationCriterias((prevPsc) => {
      const prevPscTmp = [ ...prevPsc ];

      prevPscTmp.splice(dragIndex, 1);
      prevPscTmp.splice(hoverIndex, 0, prevPsc[dragIndex]);

      return prevPscTmp;
    });
  }, []);

  const renderCriterias = useCallback((potentialSegmentationCriteria: typeof potentialSegmentationCriterias[0], index: number) => (
    <CriteriaForListCriteriasBuilderSegmentation
      {...props}
      key={potentialSegmentationCriteria.id}
      potentialSegmentationCriteria={potentialSegmentationCriteria}
      moveCriteria={moveCriteria}
      index={index}
      setIsDragging={setIsDragging}
    />
  ), [potentialSegmentationCriterias, props.possibleCriterias]);

  useEffect(() => {
    setPotentialSegmentationCriterias(props.potentialSegmentationCriterias);
  }, [props.potentialSegmentationCriterias]);

  useEffect(() => {
    if (!props.potentialSegmentationCriterias.every((psc, index) => psc.id === potentialSegmentationCriterias[index]?.id) && !isDragging) {
      props.updateSegmentationPotentialCriteria(potentialSegmentationCriterias);
    }
  }, [potentialSegmentationCriterias, isDragging]);

  return (potentialSegmentationCriterias.map((potentialSegmentationCriteria, index) => renderCriterias(potentialSegmentationCriteria, index)));
}

export default ListCriteriasBuilderSegmentation;
