import React, { ReactElement, useEffect, useState } from 'react';
import { BatchInterface, RecordSetInterface } from '@deecision/dna-interfaces';
import { Button, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { IconFileTypeXls, IconTrash } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TitleComponent from '@/components/title';
import { BatchesRecordSetsService } from '@/api/services/batches';
import BaseWidget from '@/main/containers/widgets/base-widget';
import KeyValueList, { KeyValue } from '@/components/keyvalue/keyvaluelist';
import CustomDialog from '@/components/dialog';

function RecordSetDetailsHeader(): ReactElement {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const batchesRecordSetsServices = new BatchesRecordSetsService();
  const [recordSetData, setRecordSetData] = useState<RecordSetInterface>();
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [batchCreatedFromCurrentSet, setBatchCreatedFromCurrentSet] = useState<BatchInterface[]>([]);

  const recordDatas: KeyValue[] = recordSetData ? [
    { key: 'Id', value: recordSetData?._id },
    { key: 'RecordSet Code', value: recordSetData?.recordSetCode },
    { key: 'originalFileName', value: recordSetData?.originalFileName },
    { key: 'nbRecordsPerType', value: recordSetData?.nbRecordsPerType ? Object.entries(recordSetData?.nbRecordsPerType)
      .map(([key, value]) => `"${key}": ${value}`)
      .join(', ')
      : ''
    },
    { key: 'description', value: recordSetData?.description },
    { key: 'importTime', value: recordSetData?.importTime }
  ] : [];

  const relatedRecordsDatas: KeyValue[] = batchCreatedFromCurrentSet ?
    batchCreatedFromCurrentSet.map((batch, index) => ({ key: `Batch ${index}`, value: batch._id, redirectTo: `/dna/admin/batches/${batch._id}` }))
    : [];

  const fetchRecordSetDataFromId = (recordSetId: string) => {
    batchesRecordSetsServices.get(recordSetId)
      .then((res) => {
        if (res.data) {
          setRecordSetData(res.data);
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const fetchRelatedBatchesFromSetId = (recordSetId: string) => {
    batchesRecordSetsServices.getRelatedBatches(recordSetId)
      .then((res) => {
        if (res.data) {
          setBatchCreatedFromCurrentSet(res.data);
        }
      })
      .catch(err => alert(err));
  };

  const onRecordSetDelete = () => {
    if (id) {
      batchesRecordSetsServices.delete(id)
        .then(() => {
          navigate('/admin/recordSets');
        });
    }
  };

  useEffect(() => {
    if (id) {
      fetchRecordSetDataFromId(id);
      fetchRelatedBatchesFromSetId(id);
    }
  }, [id]);

  return (
    <Grid container spacing={2} alignItems='center' width='100%' height='100%'>
      <Grid container justifyContent='space-between' width='100%' height='100%' alignItems='center'>
        <Grid>
          <TitleComponent title={`RecordSet ${recordSetData?.recordSetCode}`} icon={IconFileTypeXls} />
        </Grid>
        <Grid>
          <Button
            variant='contained'
            onClick={() => {
              navigate(`/admin/configurateNewBatch/${id}`);
            }}
          >
            {t('common.utils.configurate')} & {t('admin.batch.creation')}
          </Button>
          <IconButton
            color='error'
            onClick={() => {
              setDeleteDialog(true);
            }}
          >
            <IconTrash />
          </IconButton>

        </Grid>
      </Grid>

      <Grid container >
        <BaseWidget title='Record Set Details'>
          <KeyValueList values={[recordDatas]}/>
        </BaseWidget>

        <BaseWidget title='RecordSet Related Batches' fullHeight>
          <KeyValueList values={[relatedRecordsDatas]} />
        </BaseWidget>
      </Grid>

      <CustomDialog
        open={deleteDialog}
        handleClose={()=>{
          setDeleteDialog(false);
        }}
        title={t('common.utils.deletion')}
        content={`${t('common.utils.deletionConfirmationTitle')} "${recordSetData?.recordSetCode}" ? Related Batch & records will also be deleted`}
        buttonConfirmationText={t('common.utils.delete')}
        buttonConfirmationAction={onRecordSetDelete}
        buttonCancelText={t('common.utils.cancel')}
        desctructive
      />
    </Grid>
  );
}

export default RecordSetDetailsHeader;