import React, { ReactElement } from 'react';
import { Divider, Modal, Paper, Stack, Typography } from '@mui/material';
import { SPECIAL_COUNTRIES_LISTS_TO_DISPLAY } from '@deecision/dna-interfaces';
import { IconInfoCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import CountriesFlags from '@/components/countriesflags';
import TitleComponent from '@/components/title';
import i18n from '@/i18n/config';
import { getCountryName } from '@/utils';

interface VeegilenzCountriesListModalProps {
  open: boolean,
  onClose: () => void
}

function VeegilenzCountriesListModal(props: VeegilenzCountriesListModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
    >
      <Paper
        sx={{
          width: 'min-content',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4
        }}
      >
        <TitleComponent
          title={t('veegilenz.lists.countries.label')}
          icon={IconInfoCircle}
        />
        <Stack direction='row' width='50vw' height='75vh' justifyContent='space-between' p={4} overflow='scroll' mt={4}>
          {Object.keys(SPECIAL_COUNTRIES_LISTS_TO_DISPLAY).map(listId => (
            <Stack minWidth='min-content'>
              <Typography variant='h4' fontWeight='bold' textAlign='center'>{`${t(`veegilenz.lists.countries.${listId}`)}`}</Typography>
              <Divider />
              <Stack>
                {SPECIAL_COUNTRIES_LISTS_TO_DISPLAY[listId as keyof typeof SPECIAL_COUNTRIES_LISTS_TO_DISPLAY].map(country => (
                  <>
                    <Stack key={country} direction='row' alignItems='center' spacing={1}>
                      <CountriesFlags countries={[country]} key={country} />
                      <Typography variant='h5'>{getCountryName(country, i18n.language)}</Typography>
                    </Stack>
                    <Divider />
                  </>
                ))}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Paper>
    </Modal>
  );
}

export default VeegilenzCountriesListModal;