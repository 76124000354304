import { RecordSetInterface, RecordInterface, BatchInterface, NewBatchConfigurationDto, AsyncStep } from '@deecision/dna-interfaces';
import { ApiResponse, ApiArrayResponse } from '@deecision/deecision-interfaces';
import { BaseService } from './base.api';
import axiosInstance from '../axios/instance.axios';
import { RecordData } from '@/main/modules/admin/recordSet/components/addRecordModal';

export class BaseBatchService<T> extends BaseService {
  constructor(elementPrefixUrl: string) {
    super({ axiosInstance, elementPrefixUrl });
  };

  async get(id?: string): Promise<ApiResponse<T>> {
    return this.axiosInstance.get(this.constructUrl(`/${id}`))
      .then(res => this.handleResponse<T>(res));
  }

  async getAll(): Promise<ApiArrayResponse<T>> {
    return this.axiosInstance.get(this.constructUrl('/'), { })
      .then(res => this.handleArrayResponse<T>(res));
  }

  async delete(id: string): Promise<ApiResponse<void>> {
    return this.axiosInstance.delete(this.constructUrl(`/${id}`));
  }
}

export class BatchesRecordSetsService extends BaseBatchService<RecordSetInterface> {
  constructor() {
    super('batch-record-sets');
  };

  async getRelatedRecords(recordSetId: string): Promise<ApiArrayResponse<RecordInterface>> {
    return this.axiosInstance.get(this.constructUrl(`/${recordSetId}/batch-records`), { })
      .then(res => this.handleArrayResponse<RecordInterface>(res));
  }

  async getRelatedBatches(recordSetId: string): Promise<ApiArrayResponse<BatchInterface>> {
    return this.axiosInstance.get(this.constructUrl(`/${recordSetId}/batches`), { })
      .then(res => this.handleArrayResponse<BatchInterface>(res));
  }

  async createRecordInSet(recordSetId: string, type: 'person' | 'company', recordData: RecordData): Promise<ApiResponse<RecordInterface>> {
    return this.axiosInstance.post(this.constructUrl(`/${recordSetId}/record`), { recordData },  { params: { type } })
      .then(res => this.handleResponse<RecordInterface>(res));
  }

  async importCsvRecordSet(formData: FormData, importQueries: { recordSetCode: string, type: string, description?: string }): Promise<ApiResponse<RecordSetInterface>> {
    const query = new URLSearchParams(importQueries as Record<string, string>).toString();
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    const url = `${this.constructUrl('importCsv')}?${query}`;
  
    return this.axiosInstance.post(url, formData, { headers })
      .then(res => this.handleResponse<RecordSetInterface>(res));
  }

  async uploadCsvRecordsOnly(formData: FormData, recordSetId: string, type: string, replaceAllRecords: boolean): Promise<ApiResponse<RecordSetInterface>> {
    const query = new URLSearchParams({
      type,
      replaceAllRecords: replaceAllRecords.toString()
    }).toString();
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    const url = `${this.constructUrl(`${recordSetId}/importRecordsFromFileInExistingSet`)}?${query}`;
  
    return this.axiosInstance.post(url, formData, { headers })
      .then(res => this.handleResponse<RecordSetInterface>(res));
  }

  async exportRecordSetAsCsv(recordSetId: string): Promise<void> {
    const csvResponse = await this.axiosInstance.get(this.constructUrl(`${recordSetId}/export`), {});
    const BOM = '\uFEFF';
    const blob = new Blob([BOM + csvResponse.data], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `DNA-Export-RecordSet.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export class BatchesRecordService extends BaseBatchService<RecordInterface> {
  constructor() {
    super('batch-records');
  };

  async updateRecord(recordToUpdate: RecordInterface): Promise<ApiResponse<RecordInterface>> {
    const body = {
      record: recordToUpdate
    };

    return this.axiosInstance.put(this.constructUrl(`${recordToUpdate._id}`), body)
      .then(res => this.handleResponse(res));
  }
}

export class BatchesService extends BaseBatchService<BatchInterface> {
  constructor() {
    super('batches');
  };

  async createCustomBatch(batchConfigurationDto: NewBatchConfigurationDto): Promise<ApiResponse<BatchInterface>> {
    return this.axiosInstance.post(this.constructUrl(`/createCustomBatch`), batchConfigurationDto)
      .then(res => this.handleResponse<BatchInterface>(res));
  }
}

export class DnaStepsService extends BaseBatchService<AsyncStep> {
  constructor() {
    super('dna-steps');
  }

  async getStepAndSubSteps(id: string): Promise<ApiResponse<{step: AsyncStep, subStepsTree: AsyncStep[]}>> {
    return this.axiosInstance.get(this.constructUrl(`/${id}/subSteps`), { })
      .then(res => this.handleResponse<{step: AsyncStep, subStepsTree: AsyncStep[]}>(res));
  }

  async getTopSteps(): Promise<ApiResponse<Array<AsyncStep>>> {
    return this.axiosInstance.get(this.constructUrl(`/topSteps`), {})
      .then(res => this.handleArrayResponse<AsyncStep>(res));
  }
}