import React, { ReactElement } from 'react';
import { FormControl, Autocomplete, TextField, Box } from '@mui/material';
import { AdminFilters } from '../types';

interface AutocompleteFilterProps {
  filter: AdminFilters,
  activeFilters: AdminFilters[],
  handleFilterChange: (filter: AdminFilters, values: string[] | boolean) => void
}

function AutocompleteFilter(props: AutocompleteFilterProps): ReactElement {
  return (
    <Box width='25vw'>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          options={props.filter.values || ['']}
          value={props.activeFilters?.find(activeFilter => (activeFilter.propertyKey === props.filter.propertyKey))?.values || []}
          renderInput={params =>
            <TextField
              {...params}
              label={props.filter.label}
            />
          }
          onChange={(event, values: string[]) => {
            props.handleFilterChange(props.filter, values);
          }}
        />
      </FormControl>
    </Box>
  );
}

export default AutocompleteFilter;