import React, { ReactElement, useEffect, useState } from 'react';
import { AsyncStep } from '@deecision/dna-interfaces';
import { Tooltip, IconButton, Link } from '@mui/material';
import { Stack } from '@mui/system';
import { IconArrowRight, IconJson } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { DnaStepsService } from '@/api/services/batches';
import EvenOddTable from '@/components/tables/evenodd/table';
import { Row, Column } from '@/components/tables/types';
import { CurrencyFormatterProps } from '@/utils';
import JsonModal from '../recordSet/components/jsonModal';

function StepListing(): ReactElement {
  const navigate = useNavigate();
  const stepServices = new DnaStepsService();
  const [subStepsData, setSubStepsData] = useState<AsyncStep[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [jsonModalStates, setJsonModalStates] = useState<{ open: boolean, data: AsyncStep | null }>({ open: false, data: null });
  const [rows, setRows] = useState<Row<CurrencyFormatterProps>[]>([]);
  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'type', label: 'Type' },
    { id: 'status', label: 'Status' },
    { id: 'level', label: 'Level' },
    { id: 'priority', label: 'Priority' },
    { id: 'batchRecordSetCode', label: 'Batch RecordSetCode' },
    { id: 'actions', label: 'Actions' }
  ];

  const updateRows = () => {
    setRows(
      subStepsData.map(subStep => ({
        id: subStep._id,
        type:
          <Tooltip arrow title='Redirect to step'>
            <Link href={`/admin/steps/${subStep._id}`}>{subStep.type}</Link>
          </Tooltip>,
        status: subStep.status,
        level: subStep.level,
        priority: subStep.priority,
        batchRecordSetCode: <Link href={`/admin/batches/${subStep.contextData?.batch?._id}`}>{subStep.contextData?.batchRecordSet?.recordSetCode}</Link>,
        actions:
          <Stack direction='row'>
            <Tooltip arrow title='See Json Object'>
              <IconButton onClick={() => {
                setJsonModalStates({ open: true, data: subStep });
              }}>
                <IconJson color='grey'/>
              </IconButton>
            </Tooltip>
            <Tooltip arrow title='Go to Step Page' placement='top'>
              <IconButton onClick={() => {
                navigate(`/admin/steps/${subStep._id}`);
              }}>
                <IconArrowRight color='blue'/>
              </IconButton>
            </Tooltip>
          </Stack>
      }))
    );
  };

  const fetchRecordsDataFromStepId = () => {
    stepServices.getTopSteps()
      .then((res) => {
        if (res.data) {
          setSubStepsData(res.data);
          setTotalCount(res.data.length);
        }
      });
  };

  useEffect(() => {
    fetchRecordsDataFromStepId();
  }, []);

  useEffect(() => {
    updateRows();
  }, [subStepsData]);

  return (
    <Stack spacing={4}>
      <Stack>
        <EvenOddTable<CurrencyFormatterProps> label='entities' columns={columns} rows={rows || []} totalCount={totalCount} hideFooter/>
      </Stack>

      <JsonModal<AsyncStep>
        jsonModalStates={jsonModalStates}
        setJsonModalStates={setJsonModalStates}
        jsonRootTitle='Step'
      />

    </Stack>
  );
}

export default StepListing;