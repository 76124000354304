import React from 'react';
import { ROLES } from '@deecision/dna-interfaces/dist/constants/roles';
import { IconFolders } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';
import AdminPortfoliosListing from './portfoliosListing';

const routeAdminPortfolios: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.adminPortfolios.i18nKey,
  path: dnaConfig.routes.admin.adminPortfolios.baseUrl,
  i18nKey: dnaConfig.routes.admin.adminPortfolios.i18nKey,
  icon: IconFolders,
  asA: ROLES.ADMIN,
  component: <AdminPortfoliosListing />,
  displayIndex: true
});

export default routeAdminPortfolios;