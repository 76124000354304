import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { IconArrowRight, IconCornerDownRight } from '@tabler/icons-react';
import {
  IDataBlock,
  VeegilenzCompanyFact,
  VeegilenzPersonFact,
  VeegilenzSummaryAllDataBlockContent,
  VeegilenzCompanyIndirectLink,
  EntityRef,
  VeegilenzPersonFactAllDataBlockContent,
  VeegilenzCompanyFactAllDataBlockContent,
  VeegilenzPersonFactRelatedPEPV1DataBlockContent,
  VeegilenzCompanyFactRelatedPEPV1DataBlockContent,
  VeegilenzPersonFactAmfV1DataBlockContent,
  VeegilenzCompanyFactAmfV1DataBlockContent
} from '@deecision/dna-interfaces';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { startCase } from 'lodash';
import {
  VeegilenzCompanyFactReasonCode,
  VeegilenzPersonFactReasonCode,
  VeegilenzSummaryReasonCode
} from '@deecision/dna-interfaces/dist/constants';
import Link from '@mui/material/Link';
import TitleComponent from '@/components/title';
import { CardPart } from '@/components/cards/keyvalue.cards';
import Status from '../../../components/status';
import { KeyValue } from '@/components/keyvalue/keyvaluelist';
import { VeegilenzProps } from './types';
import { getEntityPath } from '@/main/providers/getter';

interface DisplayedValues {
  key: string,
  value: string | string[],
  align: 'right',
  statusAsInt: number,
  tooltip?: string | JSX.Element | undefined,
  tooltipWidth?: string | number | undefined,
  flags?: boolean
}

function DirectIndexVeegilenzPepTooltip(props: {
  dataBlock: VeegilenzPersonFactRelatedPEPV1DataBlockContent | VeegilenzCompanyFactRelatedPEPV1DataBlockContent
}): ReactElement {
  const { t } = useTranslation();

  return <Stack spacing={1}>
    {props.dataBlock.metadata?.sources.map((source) => {
      const key = source.origins.map(o => o.entityRef.entityId).join('___');
      const ultimateOrigin = source.origins[source.origins.length - 1];
      const intermediaries = source.origins.slice(0, -1);
      const exposureTypes = source.exposures
        .map(exposure => `${t(`veegilenz.politicalExposures.types.${exposure.type}.${exposure.subType}`)} ${exposure.description}`)
        .join(', ');

      return (
        <Typography key={key}>
          {ultimateOrigin ? (
            <a
              href={getEntityPath(ultimateOrigin.entityRef)}
              style={{ color: 'inherit' }}
            >
              {ultimateOrigin.entityRef.name ??
                ultimateOrigin.entityRef.entityId}
            </a>
          ) : (
            ''
          )}
          {` ${exposureTypes} `}
          {intermediaries.length > 0 ?
            <span>
              {'( Via: '}
              {intermediaries.map(origin => (
                <a
                  key={origin.entityRef.entityId}
                  href={getEntityPath(origin.entityRef)}
                  style={{ color: 'inherit' }}
                >
                  {origin.entityRef.name ?? origin.entityRef.entityId}
                </a>
              ))}
              {' )'}
            </span>
            : (
              ''
            )}
        </Typography>
      );
    })
    }
  </Stack>;
}

function DirectIndexVeegilenzAmfTooltip(props: {
  dataBlock: VeegilenzPersonFactAmfV1DataBlockContent | VeegilenzCompanyFactAmfV1DataBlockContent
}): ReactElement {
  const { t } = useTranslation();

  return <Stack spacing={1}>
    {(props.dataBlock.metadata?.sanctions ?? []).map((sanction) => {
      if (sanction.downloadUrl) {
        return <Typography>
          <Link sx={{
            color: 'primary.light'
          }}
          href={sanction.downloadUrl}
          target='_blank'>
            {t(`veegilenz.amf.sanction`)} {sanction.amfId} - {new Date(sanction.date).toLocaleDateString()}
          </Link>
        </Typography>;
      }

      return <Typography>
        {t(`veegilenz.amf.sanction`)} {sanction.amfId} - {new Date(sanction.date).toLocaleDateString()}
      </Typography>;
    })
    }
    {(props.dataBlock.metadata?.transactions ?? []).map((transaction) => {
      if (transaction.downloadUrl) {
        return <Typography>
          <Link sx={{
            color: 'primary.light'
          }}
          href={transaction.downloadUrl}
          target='_blank'>
            {t(`veegilenz.amf.transaction`)} {transaction.amfId} - {new Date(transaction.date).toLocaleDateString()}
          </Link>
        </Typography>;
      }

      return <Typography>
        {t(`veegilenz.amf.transaction`)} {transaction.amfId} - {new Date(transaction.date).toLocaleDateString()}
      </Typography>;
    })
    }
  </Stack>;
}

function GetIndirectLinks(props: { indirectLinks?: VeegilenzCompanyIndirectLink[] }): ReactElement {
  const { t } = useTranslation();

  const getEntityName = (entityRef: EntityRef, coma?: boolean) => <Typography variant='body2' noWrap>
    {entityRef.name || entityRef.entityId}{coma ? ',' : ''}
  </Typography>;

  return props.indirectLinks ? (
    <Stack spacing={2}>
      {props.indirectLinks.map(indirectLink => (
        <Grid
          container
          key={indirectLink.companyRef.entityId}
          spacing={1}
          alignItems='center'
        >
          <Grid container>
            {indirectLink.level1PersonRefs.map((entity, index) => (
              <Grid key={entity.entityId}>
                {getEntityName(entity, indirectLink.level1PersonRefs.length > 1 && index !== indirectLink.level1PersonRefs.length - 1)}
              </Grid>
            ))}
          </Grid>
          {indirectLink.level1PersonRefs.length > 0 && (
            <Grid>
              <IconArrowRight size={16} />
            </Grid>
          )}
          <Grid container>
            {indirectLink.level2PersonRefs.map((entity, index) => (
              <Grid key={entity.entityId}>
                {getEntityName(entity, indirectLink.level2PersonRefs.length > 1 && index !== indirectLink.level2PersonRefs.length - 1)}
              </Grid>
            ))}
          </Grid>
          {indirectLink.level2PersonRefs.length > 0 && (
            <Grid>
              <IconArrowRight size={16} />
            </Grid>
          )}
          <Grid>
            <Typography variant='body2' noWrap>
              {indirectLink.companyRef.name || indirectLink.companyRef.entityId}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Stack>
  ) : (
    <>{t('common.utils.unknown')}</>
  );
}

function getSeverityLevel(summaryReason: VeegilenzSummaryReasonCode | 'noReason' ) {
  switch (summaryReason) {
  case 'atLeastOneBlackFact':
    return 3;
  case 'atLeastOneRedFact':
    return 2;
  case 'atLeastOneOrangeFact':
  case 'moreThanTreeOrangeFacts':
    return 1;
  case 'onlyGreenFacts':
    return 0;
  default:
    return 0;
  }
};

function DirectIndexVeegilenz(props: VeegilenzProps): ReactElement {
  const { t } = useTranslation();
  const dataBlocksWithFact = useLoaderData() as {
    data: IDataBlock<VeegilenzPersonFactAllDataBlockContent | VeegilenzCompanyFactAllDataBlockContent>[]
  };
  const dataBlocksWithFacts = useLoaderData() as {
    data: IDataBlock<VeegilenzSummaryAllDataBlockContent>[]
  };

  const getTooltipReason = (reasonCode: VeegilenzPersonFactReasonCode | VeegilenzCompanyFactReasonCode) => (reasonCode !== 'noReason'
    ? t(`veegilenz.reason.${reasonCode}`)
    : undefined);

  const values = useMemo((): DisplayedValues[] => {
    const VeegilenzFactType = props.entityType === 'deecPerson' ? VeegilenzPersonFact : VeegilenzCompanyFact;
    const possibleVeegilenzFactValues:string[] = Object.values(VeegilenzFactType);

    const valuesToDisplay: DisplayedValues[] = [];
    possibleVeegilenzFactValues.forEach((possibleFact) => {
      const dataBlocks = dataBlocksWithFact.data.filter(({ data }) => data.fact === possibleFact);
      if (dataBlocks.length === 0){
        valuesToDisplay.push({
          key: t(`veegilenz.keys.${possibleFact}`),
          value: t('veegilenz.errorResponse.notSearched'),
          align: 'right' as const,
          statusAsInt: 0
        });
      }

      dataBlocks.forEach(({ data }) => {
        let value: KeyValue['value'] =  t('common.utils.unknown');
        let tooltip: string | JSX.Element | undefined;
        let tooltipWidth: string | number | undefined;
        let flags: boolean = false;
        let nbItems: number | undefined;

        const {
          metadata,
          reasonCode,
          fact,
          levelAsInt
        } = data;

        switch (fact) {
        // Fact is a residence, nationality or address
        case VeegilenzPersonFact.RESIDENCE:
        case VeegilenzPersonFact.NATIONALITY :
        case VeegilenzCompanyFact.ADDRESS:
          value = metadata?.countryCodes;
          tooltip = getTooltipReason(reasonCode);
          flags = true;
          break;
          // Fact is a mandate
        case VeegilenzPersonFact.MANDATES_CURRENT:
        case VeegilenzPersonFact.MANDATES_CURRENT_AS_BO:
        case VeegilenzPersonFact.MANDATES_CURRENT_WITH_AML_RISKS:
        case VeegilenzPersonFact.MANDATES_CURRENT_WITH_LISTED_COMPANIES:
        case VeegilenzPersonFact.MANDATES_CURRENT_HOLDINGS:
        case VeegilenzPersonFact.MANDATES_PAST:
        case VeegilenzPersonFact.MANDATES_PAST_AS_BO:
        case VeegilenzPersonFact.MANDATES_PAST_WITH_AML_RISKS:
        case VeegilenzPersonFact.MANDATES_PAST_WITH_LISTED_COMPANIES:
        case VeegilenzPersonFact.MANDATES_PAST_HOLDINGS:
          value = metadata?.entityRefs?.length.toString();
          tooltip = metadata?.entityRefs?.map(entityRef => startCase(entityRef.name?.toLowerCase())).join(', ');
          break;
          // Fact is a state
        case VeegilenzCompanyFact.STATE:
          value = metadata?.state ? t(`common.utils.${metadata?.state}`) : t('common.utils.unknown');
          tooltip = getTooltipReason(reasonCode);
          break;
          // Fact is a financial bilans
        case VeegilenzCompanyFact.FINANCIAL_BILANS:
          value = metadata?.lastBilanDate
            ? `${metadata.lastBilanDate.replaceAll('-', '/')} (${t(`veegilenz.bilanType.${metadata.lastBilanType || 'unknown'}`)})`
            : t(`veegilenz.reason.${reasonCode}`);
          tooltip = getTooltipReason(reasonCode);
          break;
          // Fact is a financial stability
        case VeegilenzCompanyFact.FINANCIAL_STABILITY:
          value = `${t('veegilenz.ebitdaDiffPercent')} ${metadata?.ebitdaDiffPercent || t('common.utils.unknown')}% < ${metadata?.ebitdaDiffTreshold || t('common.utils.unknown')}%`;
          tooltip = getTooltipReason(reasonCode);
          break;
          // Fact is a sanctions
        case VeegilenzCompanyFact.SANCTIONS:
          value = metadata?.sanctions?.length.toString();
          tooltip = metadata?.sanctions?.map(sanction => t(`veegilenz.sanctionType.${sanction.type}`)).join(', ');
          break;
          // Fact is a bodacc
        case VeegilenzCompanyFact.BODACC_COLLECTIVE_PROCEDURES:
        case VeegilenzCompanyFact.BODACC_TRANSACTIONS:
        case VeegilenzCompanyFact.BODACC_RADIATIONS:
        case VeegilenzCompanyFact.BODACC_MISC_MODIFICATIONS:
        case VeegilenzCompanyFact.BODACC_CONCILIATIONS:
          value = metadata?.publications?.length.toString();
          tooltip = metadata?.publications?.map(publication => publication.dateparution.replaceAll('-', '/')).join(', ');
          break;
          // Fact is a company risk
        case VeegilenzCompanyFact.RISKS_AML:
        case VeegilenzCompanyFact.RISKS_ESG:
        case VeegilenzCompanyFact.RISKS_LISTED_COMPANY:
        case VeegilenzCompanyFact.RISKS_HOLDING:
          value = metadata?.isRisky ? t('common.utils.yes') : t('common.utils.no');
          tooltip = getTooltipReason(reasonCode);
          break;
          // Fact is a link
        case VeegilenzCompanyFact.LINKED_TO_HOLDING:
        case VeegilenzCompanyFact.LINKED_TO_FINANCIAL_INSTITUTION:
        case VeegilenzCompanyFact.LINKED_TO_LISTED_COMPANY:
          if (metadata?.directLinkEntityRefs) {
            value = `${metadata.directLinkEntityRefs?.length || 0} direct`;
            tooltip = metadata.directLinkEntityRefs.map(entityRef => startCase(entityRef.name?.toLowerCase()) || entityRef.entityId).join(', ');
          } else if (metadata?.indirectLinks) {
            const indirectLinkQuantity = metadata.indirectLinks?.length || 0;
            value = `${indirectLinkQuantity} indirect`;
            tooltip = indirectLinkQuantity>0?  <GetIndirectLinks indirectLinks={metadata?.indirectLinks} /> : undefined;
          }
          break;
        case VeegilenzPersonFact.PEP_DIRECT:
          value = metadata?.nbExposures?.toString();
          tooltip = metadata?.exposures
            ?.map(exposure => `${t(`veegilenz.politicalExposures.types.${exposure.type}.${exposure.subType}`)} ${exposure.description}`)
            .join(', ');
          break;
        case VeegilenzPersonFact.PEP_FAMILY_MEMBER:
        case VeegilenzPersonFact.PEP_INDIRECT:
          value = metadata?.nbSources?.toString();
          tooltip = (metadata?.sources.length || 0) > 0
            ? <DirectIndexVeegilenzPepTooltip dataBlock={data} />
            : undefined;
          tooltipWidth = 'max-content';
          break;
        case VeegilenzPersonFact.AMF:
          nbItems = (metadata?.sanctions?.length ?? 0)
            + (metadata?.transactions?.length ?? 0);
          value = nbItems ? nbItems.toString() : '--';
          tooltip = nbItems ? <DirectIndexVeegilenzAmfTooltip dataBlock={data}/> : undefined;
          break;
        default:
          value = t('common.utils.unknown');
          break;
        }

        valuesToDisplay.push({
          key: t(`veegilenz.keys.${fact}`),
          value: value || t('common.utils.unknown'),
          tooltip,
          tooltipWidth,
          align: 'right' as const,
          statusAsInt: levelAsInt,
          flags
        });
      });
    });

    return valuesToDisplay;
  }, [dataBlocksWithFact, t]);

  const summaryDataBlockData = dataBlocksWithFacts.data.find(dataBlock => dataBlock.dataInfo.path.includes('summary'))?.data;
  const summaryReason = summaryDataBlockData?.reasonCode || 'noReason';

  return (
    <Paper variant='hoverElevation1'>
      <Grid container spacing={4} width='min-content' alignItems='center'>
        <Grid size='grow' pt={2}>
          <TitleComponent title={t('veegilenz.directIndex')} />
        </Grid>
        <Grid>
          <Tooltip
            title={
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='body2'>
                  {t(`veegilenz.reason.${summaryReason}`)}
                </Typography>
                <Status statusAsInt={getSeverityLevel(summaryReason)} size={16} />
              </Stack>
            }
            arrow
            placement='right'
            hidden={
              dataBlocksWithFacts.data.find(
                dataBlock =>
                  dataBlock.dataInfo.path.includes('summary')
              )?.data?.levelAsInt === undefined
            }
          >
            <Box height={24} width={24} mr={2}>
              <Status statusAsInt={summaryDataBlockData?.levelAsInt || 0} size={24}/>
            </Box>
          </Tooltip>
        </Grid>
        <Grid size={12} p={2} pr={4} pl={4}>
          <CardPart
            values={values.map((value, index) => {
              const isSameThanPreviousIndex = index !== 0 && value?.key === values?.[index - 1]?.key;

              return {
                ...value,
                key:
                  isSameThanPreviousIndex ? (
                    <IconCornerDownRight
                      size={16}
                      style={{ marginLeft: '8px' }}
                    />
                  ) : value?.key
              };
            })}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DirectIndexVeegilenz;
