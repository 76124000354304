import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Paper, FormControl, TextField, InputLabel, Select, MenuItem, Button, Typography, Switch } from '@mui/material';
import { Stack } from '@mui/system';
import { BatchesRecordSetsService } from '@/api/services/batches';

interface ImportCsvModalProps {
  modalImportCsvOpen: boolean,
  setModalImportCsvOpen: React.Dispatch<React.SetStateAction<boolean>>,
  refreshData: React.Dispatch<React.SetStateAction<boolean>>,
  importMode: 'recordSet' | 'record',
  recordSetId?: string
}

function ImportCsvModal(props: ImportCsvModalProps): ReactElement {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const recordSetServices = new BatchesRecordSetsService();
  const [importQueries, setImportQueries] = useState<{recordSetCode: string, type: string, description?: string}>({ recordSetCode: '', type: '' });
  const [replaceAllRecords, setReplaceAllRecords] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files ? e.target.files[0] : null);
  };

  const handleQueryChange = (value: string, propertyKey: string) => {
    setImportQueries(prevState => ({
      ...prevState,
      [propertyKey]: value
    }));
  };

  const handleClose = () => {
    setReplaceAllRecords(false);
    props.setModalImportCsvOpen(false);
  };

  const uploadOnRecordSetMode = (formData: FormData) => {
    recordSetServices.importCsvRecordSet(formData, importQueries)
      .then(() => {
        props.refreshData(true);
        handleClose();
      });
  };

  const uploadOnRecordMode = (formData: FormData) => {
    if (props.recordSetId) {
      recordSetServices.uploadCsvRecordsOnly(formData, props.recordSetId, importQueries.type, replaceAllRecords)
        .then(() => {
          props.refreshData(true);
          handleClose();
        });
    }
  };

  const handleFileUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        if (props.importMode === 'recordSet') {
          uploadOnRecordSetMode(formData);
        }
        if (props.importMode === 'record') {
          uploadOnRecordMode(formData);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      alert('Please select a file first');
    }
  };

  return (
    <Modal
      open={props.modalImportCsvOpen}
      onClose={handleClose}
    >
      <Paper
        sx={{
          width: 500,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4
        }}
      >
        <Stack spacing={4}>
          {props.importMode === 'recordSet' &&
            <>
              <Typography variant='h3'>Import a File (.csv) to create a new RecordSet</Typography>
              <FormControl fullWidth>
                <TextField
                  label='Record Set Code'
                  variant='outlined'
                  value={importQueries.recordSetCode}
                  onChange={(e) => {
                    handleQueryChange(e.target.value, 'recordSetCode');
                  } } />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label='Description'
                  variant='outlined'
                  value={importQueries.description}
                  onChange={(e) => {
                    handleQueryChange(e.target.value, 'description');
                  } } />
              </FormControl>
            </>
          }
          {props.importMode === 'record' &&
            <>
              <Typography variant='h3'>Import a File (.csv) to create Records for recordSet</Typography>
              <FormControl fullWidth>
                <Typography id='type-label'>Replace existing records</Typography>
                <Switch
                  value={replaceAllRecords}
                  onChange={() => {
                    setReplaceAllRecords(prevState => !prevState);
                  }}
                />
              </FormControl>
            </>
          }

          <FormControl fullWidth>
            <InputLabel id='type-label'>Type</InputLabel>
            <Select
              label='Type'
              variant='outlined'
              value={importQueries.type}
              onChange={(e) => {
                handleQueryChange(e.target.value, 'type');
              } }
            >
              <MenuItem value='person'>Person</MenuItem>
              <MenuItem value='company'>Company</MenuItem>
            </Select>
          </FormControl>

          <input type='file' accept='.csv' onChange={handleFileChange} />
          <Button  variant='contained' onClick={handleFileUpload}>{t('admin.recordSet.creation.uploadCsv')}</Button>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default ImportCsvModal;