import { useKeycloak } from '@react-keycloak/web';
import { CanProps } from '@/auth/types';
import CheckAbility from '@/auth/check-ability';

function Can(props: CanProps) {
  const { keycloak } = useKeycloak();

  return new CheckAbility({ ...props, keycloak }).render();
}

export default Can;
