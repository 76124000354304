import { AxiosInstance, AxiosResponse } from 'axios';
import { ApiArrayResponse, ApiResponse } from '@deecision/deecision-interfaces';
import { Params } from 'react-router-dom';
import { ZodError, ZodSchema } from 'zod';
import ServiceError from '@/api/services/error';
import { DISABLE_ZOD_VALIDATION_WARNING } from '@/env/env';

export interface BaseServiceInterface {
  handleResponse<T>(res: AxiosResponse<ApiResponse<T> | ApiArrayResponse<T>>): Promise<ApiResponse<T> | ApiArrayResponse<T>>
}

export interface BaseServiceProps {
  axiosInstance: AxiosInstance,
  elementPrefixUrl: string,
  schema?: ZodSchema
}
export abstract class BaseService<T = unknown> implements BaseServiceInterface {
  axiosInstance: AxiosInstance;

  elementPrefixUrl: string;

  schema?: ZodSchema;

  enableAlertSnackbar: boolean;

  protected constructor(props: BaseServiceProps) {
    this.axiosInstance = props.axiosInstance;
    this.elementPrefixUrl =
      props.elementPrefixUrl.charAt(0) === '/' ? props.elementPrefixUrl : `/${props.elementPrefixUrl}`;
    this.schema = props.schema;
    this.enableAlertSnackbar = DISABLE_ZOD_VALIDATION_WARNING !== 'true';
  }

  constructUrl(call?: string): string {
    return (
      call ?
        `${this.elementPrefixUrl}${call.charAt(0) === '/' ? call : `/${call}`}` :
        this.elementPrefixUrl
    );
  }

  downloadPdf(data: string, name: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${name}.pdf`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  abstract get(id?: string): Promise<ApiResponse<T> | ApiArrayResponse<T>>
  abstract getAll(props?: unknown): Promise<ApiResponse<T> | ApiArrayResponse<T>>
  post?(data: T): Promise<ApiResponse<T>>
  put?(id: string, data: T): Promise<ApiResponse<T>>
  delete?(id: string): Promise<ApiResponse<T>>

  handleResponse<U>(res: AxiosResponse<ApiResponse<U>>): Promise<ApiResponse<U>> {
    return this._handleResponse<ApiResponse<U>>(res);
  }

  handleArrayResponse<U = T>(res: AxiosResponse<ApiArrayResponse<U>>): Promise<ApiArrayResponse<U>> {
    return this._handleResponse<ApiArrayResponse<U>>(res);
  }

  private _DisplayValidationError(res: AxiosResponse<unknown>, error?: ZodError): void {
    if (!error) return;

    const alertSnackbar = document.getElementById('zodAlertSnackbar');

    if (alertSnackbar && this.enableAlertSnackbar) {
      console.log(`${res.config.method?.toUpperCase() || 'GET'} ${res.config.baseURL}${res.config.url}`, error);
      alertSnackbar.style.display = 'block';
    }
  }

  private _SchemaValidation<U>(res: AxiosResponse<U>): void {
    if (this.schema) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const result = this.schema.safeParse(res.data.data);

      this._DisplayValidationError(res, result.error);
    }
  }

  private _ArraySchemaValidation<U>(res: AxiosResponse<U>): void {
    if (this.schema) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const result = this.schema.array().safeParse(res.data.data);

      this._DisplayValidationError(res, result.error);
    }
  }

  private _handleResponse<U = ApiResponse<unknown> | ApiArrayResponse<unknown>>(res: AxiosResponse<U>): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      if (res.status < 200 || res.status >= 300) {
        reject(new ServiceError(res.status, 'call failed'));
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (!res.data || !res.data.data) {
        reject(new ServiceError(res.status === 200 ? 204 : res.status, 'no data'));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
      } else if (Array.isArray(res.data.data)) {
        this._ArraySchemaValidation<U>(res);
        resolve(res.data);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
      } else if (res.data.error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        reject(new ServiceError(res.status, 'Server returned an error', res.data.error));
      } else {
        this._SchemaValidation<U>(res);
        resolve(res.data);
      }
    });
  }
}

export class CustomBaseService<T> extends BaseService<T> {
  constructor(props: BaseServiceProps) {
    super({ ...props });
  }

  get(id?: string, props?: unknown, params?: Params<string>): Promise<ApiResponse<T> | ApiArrayResponse<T>> {
    console.log(id, props, params);

    return new Promise(() => {});
  }

  getAll(props?: unknown): Promise<ApiResponse<T> | ApiArrayResponse<T>> {
    console.log(props);

    return new Promise(() => {});
  }

  post?(data: T): Promise<ApiResponse<T>> {
    console.log(data);

    return new Promise(() => {});
  }

  put?(id: string, data: T): Promise<ApiResponse<T>> {
    console.log(id, data);

    return new Promise(() => {});
  }

  delete?(id: string): Promise<ApiResponse<T>> {
    console.log(id);

    return new Promise(() => {});
  }
}
