import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { BaseCustomUserData, CustomSegmentationData, CustomUserDataAccess } from '../../types';
import CustomAccordion from '../../../../../components/accordion';
import AdvancedConfigBuilderSegmentation, {
  AdvancedConfigBuilderSegmentationProps
} from './advanced';
import Button from '@mui/material/Button';
import { Divider, FormLabel, useTheme } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useLocation } from 'react-router-dom';

interface ConfigBuilderSegmentationProps extends Omit<AdvancedConfigBuilderSegmentationProps, 'customSegmentationData' | 'setCustomSegmentationData'> {
  customSegmentation: BaseCustomUserData<CustomSegmentationData>,
  setCustomSegmentation: (customSegmentation: BaseCustomUserData<CustomSegmentationData> | undefined) => void,
  access: CustomUserDataAccess,
  setAccess: (access: CustomUserDataAccess) => void
}

function ConfigBuilderSegmentation(props: ConfigBuilderSegmentationProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const [name, setName] = useState<string>(props.customSegmentation?.title);
  const [advancedOpen, setAdvancedOpen] = useState(false);

  return (
    <CustomAccordion
      title={t('segmentation.builder.config.label')}
      bgcolor={theme.palette.background.default}
      defaultOpen
    >
      <Stack spacing={4} p={4} alignItems='flex-start'>
        <Stack width='100%'>
          <Typography variant='h5' p={2}>
            {t('segmentation.builder.config.name')}
          </Typography>
          <TextField
            value={name}
            fullWidth
            onChange={e => setName(e.target.value)}
            onFocus={() => setName(props.customSegmentation?.title)}
            onBlur={() => props.setCustomSegmentation(props.customSegmentation ? name ? { ...props.customSegmentation, title: name } : props.customSegmentation : undefined)}
          />
        </Stack>
        {location.pathname.includes('/factory/') &&
          <FormControl>
          <FormLabel id='selectAccess'>{t('segmentation.builder.config.access.label')}</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={props.access}
            onChange={(e) => props.setAccess(e.target.value as CustomUserDataAccess)}
          >
            <FormControlLabel value='me' control={<Radio />} label={t('segmentation.builder.config.access.me')} />
            <FormControlLabel value='admins' control={<Radio />} label={t('segmentation.builder.config.access.admins')} />
            <FormControlLabel value='everyone' control={<Radio />} label={t('segmentation.builder.config.access.everyone')} />
          </RadioGroup>
        </FormControl>
        }
        <Divider sx={{ width: '100%' }} />
        {advancedOpen ?
          [
            <Button onClick={() => setAdvancedOpen(false)} endIcon={<IconChevronUp size='1.2rem' />} size='small'>
              {t('segmentation.builder.config.advanced.close')}
            </Button>,
            <AdvancedConfigBuilderSegmentation
              {...props}
              customSegmentationData={props.customSegmentation.data}
              setCustomSegmentationData={data => props.setCustomSegmentation(props.customSegmentation ? { ...props.customSegmentation, data } : undefined)}
            />
          ] :
          <Button onClick={() => setAdvancedOpen(true)} endIcon={<IconChevronDown size='1.2rem' />} size='small'>
            {t('segmentation.builder.config.advanced.label')}
          </Button>
        }
      </Stack>
    </CustomAccordion>
  );
}

export default ConfigBuilderSegmentation;
