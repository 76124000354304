import React, { ReactElement, useEffect, useState } from 'react';
import { BatchInterface } from '@deecision/dna-interfaces';
import { useParams } from 'react-router-dom';
import { JsonEditor } from 'json-edit-react';
import { Grid2 } from '@mui/material';
import { BatchesService } from '@/api/services/batches';

function BatchesTabsSelfObj(): ReactElement {
  const { id } = useParams();
  const batchServices = new BatchesService();
  const [data, setData] = useState<BatchInterface>();

  const fetchData = (batchId: string) => {
    batchServices.get(batchId)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch(err => alert(err));
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <Grid2 container spacing={2} alignItems='center'>
      {data &&
        <JsonEditor
          data={data}
          viewOnly
          rootName='Batch'
        />
      }
    </Grid2>
  );
}

export default BatchesTabsSelfObj;