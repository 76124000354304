import { Column } from '@/components/tables/types';
import { CurrencyFormatterProps } from '@/utils';

function computeColumnsByEntityType(props: { entityType: string }): Column<CurrencyFormatterProps>[] {
  const commonColumnsToAllEntityTypes = [
    { id: 'entityType', label: 'Entity Type' },
    { id: 'entityId', label: 'Entity Id' },
    { id: 'name', label: 'Name' }
  ];
  const entityTypeToColumns: Record<string, Column<CurrencyFormatterProps>[]> = {
    deecCompany: [
      ...commonColumnsToAllEntityTypes,
      { id: 'lvl', label: 'Lvl' },
      { id: 'state', label: 'State' },
      { id: 'codeAPE', label: 'Code APE' },
      { id: 'turnover', label: 'Turnover' },
      { id: 'ebitda', label: 'EBITDA' },
      { id: 'capital', label: 'Capital' },
      { id: 'valuation', label: 'Valuation' },
      { id: 'legalFormCode', label: 'Legal Form Code' }
    ],
    deecPerson: [
      ...commonColumnsToAllEntityTypes,
      { id: 'lvl', label: 'Lvl' },
      { id: 'nbCompanies', label: 'Nb Companies' },
      { id: 'turnoverSum', label: 'Turnover SUM' },
      { id: 'ebitdaSum', label: 'EBITDA SUM' },
      { id: 'capitalSum', label: 'Capital SUM' },
      { id: 'valuationSum', label: 'Valuation SUM' },
      { id: 'weasse', label: 'Weasse' },
      { id: 'weasseMax', label: 'Weasse Max' }
    ],
    rncsCompany: [
      ...commonColumnsToAllEntityTypes
    ],
    rncsPerson: [
      ...commonColumnsToAllEntityTypes
    ],
    capfiCompany: [
      ...commonColumnsToAllEntityTypes
    ],
    capfiPerson: [
      ...commonColumnsToAllEntityTypes
    ]
  };

  return entityTypeToColumns[props.entityType] ?? [];
}

export default computeColumnsByEntityType;