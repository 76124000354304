import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { AnyEntity, EntityLinksAggregation, EntityRef } from '@deecision/dna-interfaces';
import { SimpleSort } from '@/components/sort';
import { SimpleFilter } from '@/components/filters/simple';
import { CompanyGetter } from '../../providers/getter';
import { SettingsProps } from '@/components/filters/simple/switch';

export const cardHeight = 250;

export const labelEdgeColor = {
  sha: '#1E90FF',    // DodgerBlue for Shareholder
  exe: '#FF8C00',    // DarkOrange for Executive
  both: '#9e2f9e'    // Purple for both Shareholder and Executive
};

export interface DataElementChipType {
  parent: {
    dataInfo: string | undefined,
    scheduling: string | undefined
  },
  children: {
    dataInfo: string | undefined,
    scheduling: string | undefined
  }
}

export interface NodeType {
  id: string,
  type?: 'mainEntity' | 'personEntity' | 'companyEntity' | 'warningShaParent',
  position: { x: number, y: number },
  nodeFrom?: string,
  nodeFromType?: string,
  hidden?: boolean,
  category?: string,
  data: {
    id?: string,
    label?: string,
    entityId?: string,
    category?: string,
    entityType?: string,
    entity?: EntityLinksAggregation,
    entityBasePoint?: { x: number, y: number },
    isAdvisor?: boolean,
    advisorType?: 'representative' | 'represented',
    deploy?: boolean,
    nodes?: MutableRefObject<NodeType[]>,
    filters?: MutableRefObject<SimpleFilter[]>,
    filtersElementView?: MutableRefObject<SimpleFilter[]>,
    setNodes?: Dispatch<SetStateAction<NodeType[]>>,
    setEdges?: Dispatch<SetStateAction<EdgeType[]>>,
    source?: 'Right' | 'Left',
    target?: 'Right' | 'Left',
    branchNodeIds?: string[],
    filtersId?: (string | null)[],
    prxmee?: number,
    switchPrxmee? : MutableRefObject<boolean>,
    companyLinkedNodes?: string[],
    linkedNotes?: string[],
    parentNode?: NodeType | null,
    nodeNameFrom?: string | null,
    nodeFromType?: string,
    sharesPercentage?: number,
    labelBetweenPerson?: string[],
    isListedCompany?: boolean,
    entityGetter?: CompanyGetter,
    entityData?: (CompanyGetter | undefined)[],
    sortListRef?: MutableRefObject<SimpleSort[]>,
    settingsList?: MutableRefObject<SettingsProps[]>,
    shareholderTotalPercentage?: number,
    cardData?: AnyEntity, // This is the getEntity response to display the card data
    cardLinks?: unknown // This is the getEntityLinks response to display the card links
  }
}

export interface EdgeType {
  id: string,
  source: string,
  target: string,
  type?: 'custom',
  label?: string[],
  linkTo?: 'person' | 'company' | 'warningSha',
  edgeFrom?: string,
  edgeFromType?: string,
  branchNodeIds?: (string | undefined)[],
  data?: {
    mainEntity?: string,
    entity?: EntityRef,
    sourceType?: string,
    targetType?: string,
    arrowAt?: 'start' | 'end',
    filtersId?: (string | null)[],
    labelEdgeColor?: string | undefined,
    filtersElementView?: MutableRefObject<SimpleFilter[]>,
    settingsList?: MutableRefObject<SettingsProps[]>,
    highlight?: {
      color?: string
    }
  }
}
