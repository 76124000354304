import React from 'react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import Link from '@mui/material/Link';
import { Row } from '@/components/tables/types';
import { CompanyGetter, PersonGetter } from '@/main/providers/getter';
import { CurrencyFormatterProps } from '@/utils';

function computeRowsByEntityType(props: { entityType: string, entitiesList: (CompanyEntity | PersonEntity)[] }): Row<CurrencyFormatterProps>[] {

  const commonRowsToAllEntities = (entity: CompanyEntity | PersonEntity) => ({
    id: entity?.entityId,
    entityType: entity?.entityType,
    entityId: entity?.entityId ? (
      <Link href={`${entity.entityId}`}>
        {entity.entityId}
      </Link>
    ) : (
      'N/A'
    )
  });

  const entityTypeToRows: Record<string, Row<CurrencyFormatterProps>[]> = {
    // Deecision Rows
    deecCompany: props.entitiesList.map(entity => ({
      ...commonRowsToAllEntities(entity),
      name: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getName(),
      state: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getState(),
      codeAPE: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getApeCode(),
      legalFormCode: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getLegalForm(),
      turnover: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getTurnover(),
      ebitda: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getEbitda(),
      capital: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getCapital(),
      valuation: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getValuation(),
      lvl: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getLevel()
    })),
    deecPerson: props.entitiesList.map(entity => ({
      ...commonRowsToAllEntities(entity),
      name: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getName(),
      nbCompanies: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getNbCompanies(),
      turnoverSum: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getTurnoverSum(),
      ebitdaSum: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getEbitdaSum(),
      capitalSum: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getCapitalSum(),
      valuationSum: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getValuationSum(),
      weasse: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getWeasse(),
      weasseMax: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getWeasseMax(),
      lvl: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getLevel()
    })),

    // RNCS Rows
    rncsCompany: props.entitiesList.map(entity => ({
      ...commonRowsToAllEntities(entity),
      name: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getName()
    })),
    rncsPerson: props.entitiesList.map(entity => ({
      ...commonRowsToAllEntities(entity),
      name: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getName()
    })),

    // Capfi Rows
    capfiCompany: props.entitiesList.map(entity => ({
      ...commonRowsToAllEntities(entity),
      name: new CompanyGetter({ data: entity as CompanyEntity, errorMsg: 'N/A' }).getName()
    })),
    capfiPerson: props.entitiesList.map(entity => ({
      ...commonRowsToAllEntities(entity),
      name: new PersonGetter({ data: entity as PersonEntity, errorMsg: 'N/A' }).getName()
    }))
  };

  return entityTypeToRows[props.entityType] ?? [];
}

export default computeRowsByEntityType;