import React, { ReactElement, useEffect, useState } from 'react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Box, Button, Chip, Stack } from '@mui/material';
import { IconBuildingEstate, IconBuildings, IconUser } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import BaseDnaEntitiesService from '../../../entities/services/entities';
import BaseDnaRncsEntitiesService from '../../../entities/services/rncs';
import TitleComponent from '@/components/title';
import PersonWidgetsIdentity from '@/main/containers/widgets/persons/identity';
import CompanyWidgetsIdentity from '@/main/containers/widgets/companies/identity';
import CompanyWidgetsVeegilenz from '@/main/containers/widgets/companies/veegilenz';
import CompanyWidgetsFinancial from '@/main/containers/widgets/companies/financial';
import dnaConfig from '../../../../../config/dna.config.json';

function EntityDetailPageHeader(): ReactElement {
  const { t } = useTranslation();
  const { entityType } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname.split('/');
  const id = pathSegments[4];
  const isPerson = entityType?.toLowerCase().includes('person');
  const [entityData, setEntityData] = useState<CompanyEntity | PersonEntity | null>(null);
  const urlToRedirectToEntityPage = `/${isPerson ? 'persons' : 'companies'}/${id}`;

  const entityService = (entityType?.includes('deec') ?
    new BaseDnaEntitiesService<CompanyEntity>({ entityType })
    : new BaseDnaRncsEntitiesService<CompanyEntity>({ entityType }));

  const [dynamicTabsLinkEnabled] = useState({
    [dnaConfig.routes.companies.tabs.financial.baseUrl]: '',
    [dnaConfig.routes.companies.tabs.veegilenz.baseUrl]: ''
  });

  const fetchData = (entityId: string) => {
    if (!id) {
      return;
    }
    entityService.get(entityId)
      .then((res) => {
        if (res.data) {
          setEntityData(res.data);
        }
      });
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Stack width='100%' direction='row' justifyContent='space-between'>
        <TitleComponent
          title={entityData?.name}
          icon={IconBuildingEstate}
          chips={[
            <Chip label='Admin View' color='error' />
          ]}
        />
        <Box width='200px'>
          <Button
            variant='contained'
            startIcon={isPerson ? <IconUser size='1.6rem' /> : <IconBuildings size='1.6rem' />}
            sx={{ minWidth: 'max-content' }}
            onClick={() => {
              navigate(`${urlToRedirectToEntityPage}`);
            }}>
            {t('common.utils.seeEntityPage')}
          </Button>
        </Box>
      </Stack>
      <Grid size={12} overflow='auto'>
        <Stack spacing={2} direction='row' alignItems='stretch' justifyContent='stretch' flexWrap='nowrap' width='max-content' pb={2}>
          {entityData && entityType?.toLowerCase().includes('person') ?
            <PersonWidgetsIdentity data={entityData as PersonEntity} simple small />
            :
            null
          }
          {entityData && entityType?.toLowerCase().includes('company') ?
            <>
              <CompanyWidgetsIdentity data={entityData as CompanyEntity} />
              <CompanyWidgetsVeegilenz data={entityData as CompanyEntity} link={dynamicTabsLinkEnabled[dnaConfig.routes.companies.tabs.veegilenz.baseUrl]} />
              <CompanyWidgetsFinancial data={entityData as CompanyEntity} link={dynamicTabsLinkEnabled[dnaConfig.routes.companies.tabs.financial.baseUrl]} />
            </>
            :
            null
          }
        </Stack>
      </Grid>
    </Grid>
  );
}

export default EntityDetailPageHeader;
