import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useLoaderData } from 'react-router-dom';
import { IDataBlock, VeegilenzSummaryAllDataBlockContent } from '@deecision/dna-interfaces';
import { IconDatabase } from '@tabler/icons-react';
import BaseWidget from '@/main/containers/widgets/base-widget';
import TitleComponent from '@/components/title';
import Completeness from '@/components/chips/completeness';

function VeegilenzComputed(): ReactElement {
  const { t } = useTranslation();
  const veegilenzSummaryDataBlocks = useLoaderData() as {
    data: IDataBlock<VeegilenzSummaryAllDataBlockContent>[]
  };
  const completeness = veegilenzSummaryDataBlocks?.data
    ?.find(dataBlock => dataBlock.dataInfo.path.includes('veegilenz.summary'))
    ?.data.completeness.value;
  const computed = veegilenzSummaryDataBlocks?.data
    ?.find(dataBlock => dataBlock.dataInfo.path.includes('veegilenz.summary'))
    ?.data.completeness.knownCount;
  const total = veegilenzSummaryDataBlocks?.data
    ?.find(dataBlock => dataBlock.dataInfo.path.includes('veegilenz.summary'))
    ?.data.completeness.totalCount;

  return (
    <BaseWidget
      title={
        <TitleComponent
          title={t('veegilenz.completenessTitle')}
          icon={IconDatabase}
        />
      }
    >
      <Stack width='100%' spacing={4} pl={2} pr={2}>
        <Completeness completeness={completeness} size='medium' />
        <Stack p={2} direction='row' spacing={2} alignItems='center' width='100%' justifyContent='space-between'>
          <Typography>
            {t('veegilenz.computed')}
          </Typography>
          <Typography variant='h4' fontWeight={700}>
            {computed}/{total}
          </Typography>
        </Stack>
      </Stack>
    </BaseWidget>
  );
}

export default VeegilenzComputed;
