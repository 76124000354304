import React, { ReactElement, useEffect, useState } from 'react';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { toNumber } from 'lodash';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { IconSearch } from '@tabler/icons-react';
import { CurrencyFormatterProps } from '@/utils';
import EvenOddTable from '@/components/tables/evenodd/table';
import BaseDnaEntitiesService from '../../../entities/services/entities';
import BaseDnaRncsEntitiesService from '../../../entities/services/rncs';
import { Column } from '@/components/tables/types';
import computeColumnsByEntityType from './utils/computeColumnsByEntityType';
import computeRowsByEntityType from './utils/computeRowsByEntityType';
import { MakeParamsProps } from '@/api';

function EntitiesListing(): ReactElement {
  const [searchParams] = useSearchParams();
  const { entityType } = useParams();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [autocompleteValue, setAutocompleteValue] = useState<string | null>(null);

  const entityService = (entityType?.includes('deec') ?
    new BaseDnaEntitiesService<CompanyEntity>({ entityType })
    : new BaseDnaRncsEntitiesService<CompanyEntity>({ entityType }));

  const [entitiesList, setEntitiesList] = useState<CompanyEntity[] | null>(null);
  const [totalCount, setTotalCount] = useState(0);

  const columns: Column<CurrencyFormatterProps>[] = computeColumnsByEntityType({ entityType: entityType || '' });
  const rows = computeRowsByEntityType({ entityType: entityType || '', entitiesList: entitiesList || [] });

  const fetchAllEntitiesFromEntityType = () => {
    const optionParams: MakeParamsProps = {
      pagingParams: autocompleteValue ? {} :
        {
          page,
          pageSize
        },
      filters: autocompleteValue ?
        [ { scope: 'fe_name', id: autocompleteValue, value: autocompleteValue },
          { scope: 'autocomplete', id: 'true', value: 'true' }]
        :
        []
    };
    entityService.getAll(optionParams)
      .then((res) => {
        setTotalCount(res.totalCount);
        if (res.data) {
          const entitiesListResponse = Array.isArray(res.data) ? res.data : [];
          setEntitiesList(entitiesListResponse);
        }
      });
  };

  const updatePaginationParams = () => {
    const paramsPage = searchParams.get('page');
    const paramsPageSize = searchParams.get('pageSize');
  
    setPage(!paramsPage ? 0 : toNumber(paramsPage));
    setPageSize(!paramsPageSize ? 20 : toNumber(paramsPageSize));
  };

  const onAutocompleteValueChange = (value: string) => {
    setAutocompleteValue(value);
  };

  useEffect(() => {
    fetchAllEntitiesFromEntityType();
  }, [page, pageSize, autocompleteValue]);

  useEffect(() => {
    updatePaginationParams();
  }, [window.location.href]);

  return (
    <Stack spacing={4}>
      <Stack width='40vw' display='flex' flexDirection='row' alignItems='center'>
        <Box mr={4}>
          <IconSearch size='1.8rem' />
        </Box>
        <Autocomplete
          fullWidth
          value={autocompleteValue}
          options={entitiesList?.map(entity => entity.name) || ['']}
          renderInput={params =>
            <TextField
              {...params}
              label='Select Name'
              value={autocompleteValue}
              onChange={(event) => {
                onAutocompleteValueChange(event.target.value);
              }}
            />
          }
          onChange={(event, value) => {
            onAutocompleteValueChange(value || '');
          }}
        />
      </Stack>
      <EvenOddTable<CurrencyFormatterProps> label='entities' columns={columns} rows={rows || []} totalCount={totalCount}/>
    </Stack>
  );
}

export default EntitiesListing;
