import React from 'react';
import html2canvas from 'html2canvas';

const captureScreenshot = async (ref?: React.MutableRefObject<HTMLDivElement | HTMLElement | null>) => {
  const currentRef = ref?.current;
  if (!currentRef) return;

  try {
    const canvas = await html2canvas(currentRef);
    const image = canvas.toDataURL('image/png');

    const link = document.createElement('a');
    link.href = image;
    link.download = `dna-screenshot-${new Date().toLocaleDateString()}-${new Date().getHours().toString().padStart(2, '0')}h${new Date().getMinutes().toString().padStart(2, '0')}_${new Date().getSeconds().toString().padStart(2, '0')}.png`;
    link.click();
  } catch (error) {
    console.error(error);
  }
};

export default captureScreenshot;
