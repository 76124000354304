import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { IconChartRadar } from '@tabler/icons-react';
import { lighten, Typography, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import BaseWidget from '@/main/containers/widgets/base-widget';
import { WidgetProps } from '@/main/containers/widgets/types';
import RadarCharts from '@/components/charts/radar';
import TitleComponent from '@/components/title';
import WidgetsRadarDetail from './radarDetails';
import { baseChartColors } from '@/components/charts/utils';

function WidgetsRadar(props: WidgetProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const yearN = Object.entries(props.data.dataViews?.data?.confeedenz?.year?.n?.axisScore || {}).map(([, value]) => (
    value
  ));
  const yearNMinus1 = Object.entries(props.data.dataViews?.data?.confeedenz?.year?.nMinus1?.axisScore || {}).map(([, value]) => (
    value
  ));

  const datas = [
    yearNMinus1.map(item => ({
      count: item.value,
      value: t(`entities.persons.person.charts.radar.${item.axis}`)
    })),
    yearN.map(item => ({
      count: item.value,
      value: t(`entities.persons.person.charts.radar.${item.axis}`)
    }))
  ];
  const legends = [
    'N-1',
    'N'
  ];
  const legendsTooltipLabel = {
    'N-1': `(${props.data.dataViews?.data?.confeedenz?.year?.nMinus1?.startDate}-${props.data.dataViews?.data?.confeedenz?.year?.nMinus1?.endDate})`,
    'N': `(${props.data.dataViews?.data?.confeedenz?.year?.n?.startDate}-${props.data.dataViews?.data?.confeedenz?.year?.n?.endDate})`
  };
  const colors = baseChartColors(theme);
  colors[1] = colors[0];
  colors[0] = lighten('#00008B', 0.35);

  return (
    <BaseWidget
      {...props}
      title={
        <TitleComponent title={t('entities.persons.person.charts.radar.decisionRadar')} icon={IconChartRadar} small />
      }
      chart
      size='small'
    >
      <Stack spacing={4}>
        <Box height='32vh' width='100%' display='flex' justifyContent='center' alignItems='center'>
          { yearN.every(item => item.value === 0) && yearNMinus1.every(item => item.value === 0) ?
            <Typography align='center'>{t('common.utils.noData')}</Typography> :
            <RadarCharts
              datas={datas}
              legends={legends}
              maxValue={10}
              legendPosition='bottom'
              legendTooltipLabel={
                legendsTooltipLabel.N.includes('undefined') && legendsTooltipLabel['N-1'].includes('undefined') ?
                  undefined :
                  legendsTooltipLabel
              }
              colors={colors}
            />
          }
        </Box>
        <Box>
          { props.data.dataViews?.data?.confeedenz &&
            <WidgetsRadarDetail yearN={yearN} yearNMinus1={yearNMinus1} entityType={props.data.entityType}/>
          }
        </Box>
      </Stack>
    </BaseWidget>
  );
}

export default WidgetsRadar;
