import React, { ReactElement, useState } from 'react';
import { Button, Modal, Paper, Stack, TextField, Typography } from '@mui/material';
import { StatsByStatusInterface } from '@/api/services/data.elements.stats.api';
import { DataElementsQueryService } from '@/api/services/query';

interface PriorityModalInterface {
  priorityModalStates: {
    open: boolean,
    data: StatsByStatusInterface | null
  },
  setPriorityModalStates: React.Dispatch<React.SetStateAction<{
    open: boolean,
    data: StatsByStatusInterface | null
  }>>,
  refresh: React.Dispatch<React.SetStateAction<boolean>>
}

function PriorityModal(props: PriorityModalInterface): ReactElement {
  const [priority, setPriority] = useState<number>(0);
  const dataElementsServices = new DataElementsQueryService({});

  const handleClose = () => {
    props.setPriorityModalStates({ open: false, data: null });
  };

  const updatePriority = () => {
    if (props.priorityModalStates.data) {
      dataElementsServices.updatePriority(props.priorityModalStates.data?._id, priority)
        .then(() => {
          props.refresh(true);
          handleClose();
        });
    }
  };

  return (
    <Modal
      open={props.priorityModalStates.open}
      onClose={handleClose}
    >
      <Paper
        sx={{
          width: '50wv',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
          maxHeight: '90vh',
          overflow: 'auto'
        }}
      >
        <Stack spacing={4}>
          {props.priorityModalStates.data &&
            <>
              <Typography variant='h3'>Modify settings.tmpBoost for {props.priorityModalStates.data._id}</Typography>
            </>
          }
          <TextField
            label='Enter a number'
            variant='outlined'
            type='number'
            value={priority}
            onChange={e => setPriority(Number(e.target.value))}
          />
          <Stack direction='row' width='100%' justifyContent='flex-end' spacing={4}>
            <Button variant='text' onClick={handleClose} color='error'>Cancel</Button>
            <Button variant='contained' onClick={updatePriority}>Edit Priority</Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default PriorityModal;