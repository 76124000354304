import React, { ReactElement, useEffect, useState } from 'react';
import { RecordInterface } from '@deecision/dna-interfaces';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { IconEdit, IconJson, IconTrash } from '@tabler/icons-react';
import { BatchesRecordService, BatchesRecordSetsService } from '@/api/services/batches';
import { Column, Row } from '@/components/tables/types';
import EvenOddTable from '@/components/tables/evenodd/table';
import { CurrencyFormatterProps } from '@/utils';
import AddNewRecordButton from '../components/addNewRecordButton';
import EditRecordModal from '../components/editRecordModal';
import AddRecordModal, { RecordAddState } from '../components/addRecordModal';
import JsonModal from '../components/jsonModal';
import CustomDialog from '@/components/dialog';
import ImportCsvModal from '../components/importCsvModal';

interface RecordActionState {
  open: boolean,
  data: RecordInterface | null
}

function RecordSetTabsRecordListing(): ReactElement {
  const { t } = useTranslation();
  const { id } = useParams();
  const recordServices = new BatchesRecordService();
  const recordSetServices = new BatchesRecordSetsService();

  // States for Records
  const [recordsData, setRecordsData] = useState<RecordInterface[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  // States for Actions Records
  const [openImport, setOpenImport] = useState<boolean>(false);
  const [editRecordModalStates, setEditRecordModalStates] = useState<RecordActionState>({ open: false, data: null });
  const [jsonRecordModalStates, setJsonRecordModalStates] = useState<RecordActionState>({ open: false, data: null });
  const [deleteRecordDialogStates, setDeleteRecordDialogStates] = useState<RecordActionState>({ open: false, data: null });
  const [addRecordModalStates, setAddRecordModalStates] = useState<RecordAddState>({
    open: false,
    data: null,
    recordSetId: '',
    type: 'person'
  });

  const configurateAndCreateNewRecord = (templateType?: 'company' | 'person' | 'file' | undefined) => {
    if (!templateType || !id) {
      alert('Cannot add new record: no recordSetId or no type defined');

      return;
    }
    if (templateType === 'file') {
      setOpenImport(true);

      return;
    }

    const commonData = {
      type: recordsData.length > 0 ? recordsData[0].data.type : '',
      banque: '',
      marche: '',
      portfolio: ''
    };
    const partialData = {
      company: { siren: '', denomination: '' },
      person: { firstName: '', lastName: '', birthDate: '' }
    };

    setAddRecordModalStates({
      open: true,
      data: {
        ...commonData,
        ...partialData[templateType]
      },
      type: templateType,
      recordSetId: id
    });
  };

  const fetchRecordsDataFromRecordSetId = (recordSetId: string) => {
    setRefreshData(false);
    recordSetServices.getRelatedRecords(recordSetId)
      .then((res) => {
        if (res.data) {
          setRecordsData(res.data);
          setTotalCount(res.totalCount);
        }
      });
  };

  const onRecordDelete = () => {
    if (deleteRecordDialogStates.data?._id) {
      recordServices.delete(deleteRecordDialogStates.data?._id)
        .then(() => {
          setDeleteRecordDialogStates({ open: false, data: null });
          setRefreshData(true);
        });
    }
  };

  const columns: Column<CurrencyFormatterProps>[] = [
    // Person
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'birthDate', label: 'Birth Date' },
    // Company
    { id: 'siren', label: 'Siren' },
    { id: 'denomination', label: 'Denomination' },
    
    { id: 'type', label: 'Type' },
    { id: 'aum', label: 'AuM' },
    { id: 'actions', label: 'Actions' }
  ];

  const rows: Row<CurrencyFormatterProps>[] = recordsData.map(record => ({
    id: record._id,
    firstName: record.data.firstName,
    lastName: record.data.lastName,
    birthDate: record.data.birthDate,
    siren: record.data.siren,
    denomination: record.data.denomination,
    type: record.data.type,
    aum: record.data.aum,
    actions:
      <Stack direction='row'>
        <Tooltip arrow title='Edit'>
          <IconButton onClick={() => {
            setEditRecordModalStates({ open: true, data: record });
          }}>
            <IconEdit color='navy'/>
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='Delete'>
          <IconButton onClick={() => {
            setDeleteRecordDialogStates({ open: true, data: record });
          }}>
            <IconTrash color='red'/>
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='See Json Object'>
          <IconButton onClick={() => {
            setJsonRecordModalStates({ open: true, data: record });
          }}>
            <IconJson color='grey'/>
          </IconButton>
        </Tooltip>
      </Stack>
  }));

  useEffect(() => {
    if (refreshData && id) {
      fetchRecordsDataFromRecordSetId(id);
    }
  }, [id, refreshData]);

  return (
    <>
      {id &&
        <Stack spacing={4}>
          <Stack pb={4}>
            <Stack width='100%' flexDirection='row' justifyContent='end'>
              <AddNewRecordButton onAddRecord={configurateAndCreateNewRecord}/>
            </Stack>
            <EvenOddTable<CurrencyFormatterProps>
              label='entities'
              columns={columns}
              rows={rows || []}
              totalCount={totalCount}
              hideFooter
            />
            
            {recordsData.length > 0 &&
              <>
                <EditRecordModal
                  editRecordModalStates={editRecordModalStates}
                  setEditRecordModalStates={setEditRecordModalStates}
                  refreshData={setRefreshData}
                />
                <AddRecordModal
                  addRecordModalStates={addRecordModalStates}
                  setAddRecordModalStates={setAddRecordModalStates}
                  refreshData={setRefreshData}
                />
                <CustomDialog
                  open={deleteRecordDialogStates.open}
                  handleClose={()=>{
                    setDeleteRecordDialogStates({ open: false, data: null });
                  }}
                  title={t('common.utils.deletion')}
                  content={`${t('common.utils.deletionConfirmationTitle')} ${deleteRecordDialogStates.data?.data.firstName || deleteRecordDialogStates.data?.data.denomination}`}
                  buttonConfirmationText={t('common.utils.delete')}
                  buttonConfirmationAction={onRecordDelete}
                  buttonCancelText={t('common.utils.cancel')}
                  desctructive
                />

                <JsonModal<RecordInterface>
                  jsonModalStates={jsonRecordModalStates}
                  setJsonModalStates={setJsonRecordModalStates}
                />

                <ImportCsvModal
                  modalImportCsvOpen={openImport}
                  setModalImportCsvOpen={setOpenImport}
                  refreshData={setRefreshData}
                  importMode='record'
                  recordSetId={id}
                />
              </>
            }
          </Stack>

        </Stack>
      }
    </>
  );
}

export default RecordSetTabsRecordListing;