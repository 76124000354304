import React, { ReactElement, useEffect, useState } from 'react';
import { RecordSetInterface } from '@deecision/dna-interfaces';
import { IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { IconArrowRight, IconFileExcel, IconFolders, IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurrencyFormatterProps } from '@/utils';
import { Column, Row } from '@/components/tables/types';
import EvenOddTable from '@/components/tables/evenodd/table';
import { BatchesRecordSetsService } from '@/api/services/batches';
import NewRecordSetButton from './components/createRecordSetButton';
import ImportCsvModal from './components/importCsvModal';
import CustomDialog from '@/components/dialog';

function RecordSetsListing(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const recordSetServices = new BatchesRecordSetsService();
  const [batchesData, setBatchesData] = useState<RecordSetInterface[]>([]);
  const [modalImportCsvOpen, setModalImportCsvOpen] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [deleteDialog, setDeleteDialog] = useState<{open: boolean, data: RecordSetInterface | null}>({ open: false, data: null });

  const handleModalOpening = (modalType: 'fileImport' | 'manualCreation') => {
    if (modalType === 'fileImport') {
      setModalImportCsvOpen(!modalImportCsvOpen);
    }
    if (modalType === 'manualCreation') {
      // Do something
    }
  };

  const actionExportSetAsCsv = async (recordSetId: string) => {
    await recordSetServices.exportRecordSetAsCsv(recordSetId);
  };

  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'recordSetCode', label: 'Record Set Code' },
    { id: 'nbRecordsPerType', label: 'Nb Records Per Type' },
    { id: 'description', label: 'Description' },
    { id: 'importTime', label: 'Import Time' },
    { id: 'actions', label: 'Actions' }
  ];
  const rows: Row<CurrencyFormatterProps>[] = batchesData.map(recordSet => ({
    id: recordSet._id,
    recordSetCode: <Link href={`${recordSet._id}`}>{recordSet.recordSetCode}</Link>,
    nbRecordsPerType: recordSet.nbRecordsPerType instanceof Map
      ? Array.from(recordSet.nbRecordsPerType.entries())
        .map(([key, value]) => `"${key}": ${value}`)
        .join(', ')
      : recordSet.nbRecordsPerType
        ? Object.entries(recordSet.nbRecordsPerType)
          .map(([key, value]) => `"${key}": ${value}`)
          .join(', ')
        : undefined,
    description: recordSet.description,
    importTime: recordSet.importTime,
    actions:
    <Stack direction='row'>
      <Tooltip arrow title='Delete' placement='top'>
        <IconButton onClick={() => {
          setDeleteDialog({ open: true, data: recordSet });
        }}>
          <IconTrash color='red'/>
        </IconButton>
      </Tooltip>
      <Tooltip arrow title='Export .csv'>
        <IconButton onClick={() => {
          actionExportSetAsCsv(recordSet._id);
        }}>
          <IconFileExcel />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title='Go to RecordSet Page' placement='top'>
        <IconButton onClick={() => {
          navigate(`/admin/recordSets/${recordSet._id}`);
        }}>
          <IconArrowRight color='blue'/>
        </IconButton>
      </Tooltip>
    </Stack>
  }));

  const onRecordSetDelete = () => {
    if (deleteDialog.data?._id) {
      recordSetServices.delete(deleteDialog.data._id)
        .then(() => {
          setDeleteDialog({ open: false, data: null });
          setRefreshData(true);
        });
    }
  };

  const fetchData = () => {
    recordSetServices.getAll()
      .then((res) => {
        if (res.data) {
          setBatchesData(res.data);
          setTotalCount(res.totalCount);
        }
      });
    setRefreshData(false);
  };

  useEffect(() => {
    if (refreshData) {
      fetchData();
    }
  }, [refreshData]);

  return (
    <Stack spacing={4}>
      <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={2}>
          <IconFolders />
          <Typography variant='h1'>{t('admin.recordSet.listing')}</Typography>
        </Stack>
        <Stack direction='row' alignItems='center' spacing={2}>
          <NewRecordSetButton handleModalOpening={handleModalOpening} />
        </Stack>
      </Stack>

      <EvenOddTable<CurrencyFormatterProps> label='entities' columns={columns} rows={rows || []} totalCount={totalCount} hideFooter/>

      <CustomDialog
        open={deleteDialog.open}
        handleClose={()=>{
          setDeleteDialog({ open: false, data: null });
        }}
        title={t('common.utils.deletion')}
        content={`${t('common.utils.deletionConfirmationTitle')} ${deleteDialog.data?.recordSetCode} ? Related Batch & records will also be deleted`}
        buttonConfirmationText={t('common.utils.delete')}
        buttonConfirmationAction={onRecordSetDelete}
        buttonCancelText={t('common.utils.cancel')}
        desctructive
      />
      <ImportCsvModal
        modalImportCsvOpen={modalImportCsvOpen}
        setModalImportCsvOpen={setModalImportCsvOpen}
        refreshData={setRefreshData}
        importMode='recordSet'
      />
    </Stack>
  );
}

export default RecordSetsListing;