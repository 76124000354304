type PossibleColors = 'warning' | 'error' | 'success' | 'info' | 'primary';

export enum ColorEnum {
  BLUE = 'info',
  ORANGE = 'warning',
  GREEN = 'success',
  RED = 'error'
}

export const schedulingStatusColorMap: Record<string, PossibleColors> = {
  created: ColorEnum.BLUE,
  updateNeeded: ColorEnum.BLUE,
  waiting_for_dependencies: ColorEnum.ORANGE,
  requested: ColorEnum.BLUE,
  inProgress: ColorEnum.ORANGE,
  done: ColorEnum.GREEN,
  failed: ColorEnum.RED,
  startable: ColorEnum.BLUE
};

export const dataInfoStatusColorMap: Record<string, PossibleColors> = {
  missing: ColorEnum.RED,
  available: ColorEnum.GREEN,
  incomplete: ColorEnum.ORANGE
};