import React, { ReactElement, useEffect, useState } from 'react';
import { AsyncStep } from '@deecision/dna-interfaces';
import { useParams } from 'react-router-dom';
import { Grid2, Stack, Link, Tooltip, IconButton } from '@mui/material';
import { IconJson } from '@tabler/icons-react';
import { DnaStepsService } from '@/api/services/batches';
import EvenOddTable from '@/components/tables/evenodd/table';
import { Column, Row } from '@/components/tables/types';
import { CurrencyFormatterProps } from '@/utils';
import AdminCustomFilters from '../../Entities/listing/filters/adminCustomFilters';
import JsonModal from '../../recordSet/components/jsonModal';
import { AdminFilters } from '../../Entities/listing/filters/types';

function SubStepTabsListing(): ReactElement {
  const { id } = useParams();
  const stepServices = new DnaStepsService();
  const [subStepsData, setSubStepsData] = useState<AsyncStep[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filters, setFilters] = useState<AdminFilters[]>([]);
  const [filteredSubSteps, setFilteredSubSteps] = useState<AsyncStep[]>([]);
  const [jsonModalStates, setJsonModalStates] = useState<{ open: boolean, data: AsyncStep | null }>({ open: false, data: null });
  const [rows, setRows] = useState<Row<CurrencyFormatterProps>[]>([]);
  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'type', label: 'Type' },
    { id: 'status', label: 'Status' },
    { id: 'level', label: 'Level' },
    { id: 'priority', label: 'Priority' },
    { id: 'actions', label: 'Actions' }
  ];

  const updateRows = () => {
    setRows(
      filteredSubSteps.map(subStep => ({
        id: subStep._id,
        type:
          <Tooltip arrow title='Redirect to step'>
            <Link href={`/admin/steps/${subStep._id}`}>{subStep.type}</Link>
          </Tooltip>,
        status: subStep.status,
        level: subStep.level,
        priority: subStep.priority,
        actions:
          <Stack direction='row'>
            <Tooltip arrow title='See Json Object'>
              <IconButton onClick={() => {
                setJsonModalStates({ open: true, data: subStep });
              }}>
                <IconJson color='grey'/>
              </IconButton>
            </Tooltip>
          </Stack>
      }))
    );
  };

  const buildRecordsFilters = (data: AsyncStep[]) => {
    const filtersParsing: AdminFilters[] = [];
    filtersParsing.push({
      propertyKey: 'type',
      label: 'Type',
      type: 'multiselect',
      values: [...new Set(data.map(subStep => subStep.type))]
    });
    filtersParsing.push({
      propertyKey: 'level',
      label: 'Level',
      type: 'multiselect',
      values: [...new Set(data.map(subStep => subStep.level.toString()))]
    });
    filtersParsing.push({
      propertyKey: 'priority',
      label: 'Priority',
      type: 'multiselect',
      values: [...new Set(data.map(subStep => subStep.priority.toString()))]
    });

    setFilters(filtersParsing);
  };

  const updateFilteredData = (filteredData: AsyncStep[]) => {
    setFilteredSubSteps(filteredData);
  };

  const fetchRecordsDataFromStepId = (stepId: string) => {
    stepServices.getStepAndSubSteps(stepId)
      .then((res) => {
        if (res.data) {
          setSubStepsData(res.data.subStepsTree);
          setTotalCount(res.data.subStepsTree?.length);
          setFilteredSubSteps(res.data.subStepsTree);
          buildRecordsFilters(res.data.subStepsTree);
        }
      });
  };

  useEffect(() => {
    if (id) {
      fetchRecordsDataFromStepId(id);
    }
  }, [id]);

  useEffect(() => {
    updateRows();
  }, [filteredSubSteps]);

  return (
    <>
      {id &&
        <Stack spacing={4}>
          <Grid2>
            <AdminCustomFilters<AsyncStep>
              referenceData={subStepsData}
              updateData={updateFilteredData}
              filters={filters}
            />
          </Grid2>
          <Stack>
            <EvenOddTable<CurrencyFormatterProps> label='entities' columns={columns} rows={rows || []} totalCount={totalCount}/>
          </Stack>

          <JsonModal<AsyncStep>
            jsonModalStates={jsonModalStates}
            setJsonModalStates={setJsonModalStates}
            jsonRootTitle='Step'
          />

        </Stack>
      }
    </>
  );
}

export default SubStepTabsListing;