import React, { ReactElement, useEffect, useState } from 'react';
import { RecordInterface } from '@deecision/dna-interfaces';
import { Modal, Paper, FormControl, TextField, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { BatchesRecordService } from '@/api/services/batches';
import { CompanyRecordData, PersonRecordData } from './addRecordModal';

interface EditRecordModalInterface {
  editRecordModalStates: {
    open: boolean,
    data: RecordInterface | null
  },
  setEditRecordModalStates: React.Dispatch<React.SetStateAction<{
    open: boolean,
    data: RecordInterface | null
  }>>,
  refreshData: React.Dispatch<React.SetStateAction<boolean>>
}

function EditRecordModal(props: EditRecordModalInterface): ReactElement {
  const recordServices = new BatchesRecordService();
  const isPerson = props.editRecordModalStates.data?.type === 'person';
  const [editFormAsPerson, setEditFormAsPerson] = useState<PersonRecordData>({
    firstName: props.editRecordModalStates.data?.data.firstName,
    lastName: props.editRecordModalStates.data?.data.lastName,
    birthDate: props.editRecordModalStates.data?.data.birthDate,
    type: props.editRecordModalStates.data?.data.type,
    marche: props.editRecordModalStates.data?.data.marche,
    banque: props.editRecordModalStates.data?.data.banque,
    portfolio: props.editRecordModalStates.data?.data.portfolio
  });
  const [editFormAsCompany, setEditFormAsCompany] = useState<CompanyRecordData>({
    siren: props.editRecordModalStates.data?.data.siren,
    denomination: props.editRecordModalStates.data?.data.denomination,
    type: props.editRecordModalStates.data?.data.type,
    marche: props.editRecordModalStates.data?.data.marche,
    banque: props.editRecordModalStates.data?.data.banque,
    portfolio: props.editRecordModalStates.data?.data.portfolio
  });

  const handleClose = () => {
    props.setEditRecordModalStates(prevState => ({ ...prevState, open: false }));
  };

  const handleEditChange = (value: string, propertyKey: string, recordTypeToEdit: 'person' | 'company') => {
    if (recordTypeToEdit === 'person') {
      setEditFormAsPerson(prevState => ({
        ...prevState,
        [propertyKey]: value
      }));
    }
    if (recordTypeToEdit === 'company') {
      setEditFormAsCompany(prevState => ({
        ...prevState,
        [propertyKey]: value
      }));
    }
  };

  const updateRecord = async () => {
    try {
      if (!props.editRecordModalStates.data) {
        return;
      }

      const { data: record } = props.editRecordModalStates;
      const updatedData = isPerson
        ? { ...record.data, ...editFormAsPerson }
        : { ...record.data, ...editFormAsCompany };
      const editedRecord = {
        ...record,
        data: updatedData
      };

      await recordServices.updateRecord(editedRecord)
        .then(() => {
          props.refreshData(true);
          handleClose();
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  useEffect(() => {
    if (props.editRecordModalStates.data && props.editRecordModalStates.data.data) {
      if (isPerson) {
        setEditFormAsPerson(props.editRecordModalStates.data.data);
      } else {
        setEditFormAsCompany(props.editRecordModalStates.data.data);
      }
    }
  }, [props.editRecordModalStates.data]);

  return (
    <Modal
      open={props.editRecordModalStates.open}
      onClose={handleClose}
    >
      <Paper
        sx={{
          width: 500,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4
        }}
      >
        <Stack spacing={4}>
          <Typography variant='h3'>Edit {props.editRecordModalStates.data?.data.firstName || props.editRecordModalStates.data?.data.denomination} data</Typography>
          {isPerson ?
            Object.entries(editFormAsPerson).map(([key, value]) => (
              <FormControl key={key} fullWidth>
                <TextField
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  variant='outlined'
                  value={value}
                  onChange={e => handleEditChange(e.target.value, key, 'person')}
                />
              </FormControl>
            ))
            :
            Object.entries(editFormAsCompany).map(([key, value]) => (
              <FormControl key={key} fullWidth>
                <TextField
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  variant='outlined'
                  value={value}
                  onChange={e => handleEditChange(e.target.value, key, 'company')}
                />
              </FormControl>
            ))
          }
          <Stack direction='row' width='100%' justifyContent='flex-end' spacing={4}>
            <Button variant='text' onClick={handleClose} color='error'>Cancel</Button>
            <Button variant='contained' onClick={updateRecord}>Edit Record</Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

export default EditRecordModal;