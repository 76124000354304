import React, { ReactElement, useEffect, useState } from 'react';
import { BaseGroup } from '@deecision/dna-interfaces';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import { IconArrowRight, IconFolders, IconJson, IconPencil, IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DashboardGroupService } from '@/api/services/dashboard.group';
import EvenOddTable from '@/components/tables/evenodd/table';
import { Column, Row } from '@/components/tables/types';
import { CurrencyFormatterProps } from '@/utils';
import TitleComponent from '@/components/title';
import BaseGroupModalCreation from './components/baseGroupModalCreation';
import JsonModal from '../recordSet/components/jsonModal';
import CustomDialog from '@/components/dialog';
import BaseGroupModalEdit from './components/baseGroupModalEdit';

function AdminPortfoliosListing(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dashboardServices = new DashboardGroupService();
  const [portfolios, setPortfolios] = useState<BaseGroup[]>([]);
  const [creationModal, setCreationModal] = useState<boolean>(false);
  const [portfolioDeleteModal, setPortfolioDeleteModal] = useState<{open: boolean, data: BaseGroup | null}>({ open: false, data: null });
  const [portfolioEditModal, setPortfolioEditModal] = useState<{open: boolean, data: BaseGroup | null}>({ open: false, data: null });
  const [jsonPortfolioModalStates, setJsonPortfolioModalStates] = useState<{open: boolean, data: BaseGroup | null}>({ open: false, data: null });
  const [refresh, setRefresh] = useState<boolean>(true);

  const columns: Column<CurrencyFormatterProps>[] = [
    { id: 'label', label: 'Label' },
    { id: 'banqueId', label: 'Banque Id' },
    { id: 'nbPersons', label: 'Nb Persons' },
    { id: 'nbCompanies', label: 'Nb Companies' },
    { id: 'valueComputationStatus', label: 'ValueCompStatus' },
    { id: 'actions', label: 'Actions' }
  ];
  const rows: Row<CurrencyFormatterProps>[] = portfolios.map(portfolio => ({
    id: portfolio._id,
    label: portfolio.label,
    banqueId: portfolio.banqueId,
    nbPersons: portfolio.nbPersons,
    nbCompanies: portfolio.nbCompanies,
    valueComputationStatus: portfolio.valuesComputation?.status,
    actions:
      <Stack direction='row'>
        <Tooltip arrow title='Edit' placement='top'>
          <IconButton onClick={() => {
            setPortfolioEditModal({ open: true, data: portfolio });
          }}>
            <IconPencil color='blue'/>
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='Delete' placement='top'>
          <IconButton onClick={() => {
            setPortfolioDeleteModal({ open: true, data: portfolio });
          }}>
            <IconTrash color='red'/>
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='See Json Object' placement='top'>
          <IconButton onClick={() => {
            setJsonPortfolioModalStates({ open: true, data: portfolio });
          }}>
            <IconJson color='grey'/>
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='Go to Portfolio' placement='top'>
          <IconButton onClick={() => {
            navigate(`/portfolios/${portfolio._id}`);
          }}>
            <IconArrowRight color='blue'/>
          </IconButton>
        </Tooltip>
      </Stack>
  }));

  const onPortfolioDelete = () => {
    if (portfolioDeleteModal.data?._id) {
      dashboardServices.delete(portfolioDeleteModal.data?._id)
        .then(() => {
          setPortfolioDeleteModal({ open: false, data: null });
          setRefresh(true);
        });
    }
  };

  const onClearButtonClick = () => {
    dashboardServices.clearAllGroups()
      .then(() => {
        setRefresh(true);
      });
  };

  const onBuildButtonClick = () => {
    dashboardServices.buildAllGroups()
      .then(() => {
        setRefresh(true);
      });
  };

  const onRefreshButtonClick = () => {
    dashboardServices.refreshAllGroups()
      .then(() => {
        setRefresh(true);
      });
  };

  const fetchData = () => {
    setRefresh(false);
    dashboardServices.getAll()
      .then((res) => {
        if (res.data) {
          setPortfolios(res.data);
        }
      });
  };

  useEffect(() => {
    if (refresh) {
      fetchData();
    }
  }, [refresh]);

  return (
    <Stack spacing={4}>
      <Stack direction='row' alignItems='center' spacing={2} justifyContent='space-between'>
        <TitleComponent title='BaseGroup' icon={IconFolders}/>
        <Stack direction='row' alignItems='center' spacing={2}>
          <Box width='120px'>
            <Button variant='outlined' onClick={onClearButtonClick} fullWidth>
              Clean Groups
            </Button>
          </Box>
          <Box width='120px'>
            <Button variant='outlined' onClick={onBuildButtonClick} fullWidth>
              Build Groups
            </Button>
          </Box>
          <Box width='140px'>
            <Button variant='outlined' onClick={onRefreshButtonClick}>
              Refresh Groups
            </Button>
          </Box>
          <Box width='180px'>
            <Button variant='contained' onClick={() => setCreationModal(true)}>
              Create BaseGroup
            </Button>
          </Box>
        </Stack>
      </Stack>

      <EvenOddTable<CurrencyFormatterProps>
        label='BaseGroup'
        columns={columns}
        rows={rows || []}
        totalCount={portfolios.length}
        hideFooter
      />

      <BaseGroupModalCreation
        creationModal={creationModal}
        setCreationModal={setCreationModal}
        refreshData={setRefresh}
      />
      <BaseGroupModalEdit
        editModal={portfolioEditModal}
        setEditModal={setPortfolioEditModal}
        refreshData={setRefresh}
      />
      <CustomDialog
        open={portfolioDeleteModal.open}
        handleClose={()=>{
          setPortfolioDeleteModal({ open: false, data: null });
        }}
        title={t('common.utils.deletion')}
        content={`${t('common.utils.deletionConfirmationTitle')} ${portfolioDeleteModal.data?.label}`}
        buttonConfirmationText={t('common.utils.delete')}
        buttonConfirmationAction={onPortfolioDelete}
        buttonCancelText={t('common.utils.cancel')}
        desctructive
      />
      <JsonModal<BaseGroup>
        jsonModalStates={jsonPortfolioModalStates}
        setJsonModalStates={setJsonPortfolioModalStates}
      />
    </Stack>
  );
}

export default AdminPortfoliosListing;