import React, { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Chip, useTheme } from '@mui/material';
import { WeasseEconomicalPotentialLevel } from '@deecision/dna-interfaces';
import { colorTableForLevels } from '@/components/charts/utils';

export const EconomicalPotentialLevelInfo = [
  {
    level: 7,
    levelString: WeasseEconomicalPotentialLevel.LEVEL_7,
    label: 'common.levels.level_7',
    description: 'economicalPotential.informations.level_7',
    bgcolor: colorTableForLevels.level_7,
    color: 'primary.contrastText'
  },
  {
    level: 6,
    levelString: WeasseEconomicalPotentialLevel.LEVEL_6,
    label: 'common.levels.level_6',
    description: 'economicalPotential.informations.level_6',
    bgcolor: colorTableForLevels.level_6,
    color: 'grey.800'
  },
  {
    level: 5,
    levelString: WeasseEconomicalPotentialLevel.LEVEL_5,
    label: 'common.levels.level_5',
    description: 'economicalPotential.informations.level_5',
    bgcolor: colorTableForLevels.level_5,
    color: 'grey.800'
  },
  {
    level: 4,
    levelString: WeasseEconomicalPotentialLevel.LEVEL_4,
    label: 'common.levels.level_4',
    description: 'economicalPotential.informations.level_4',
    bgcolor: colorTableForLevels.level_4,
    color: 'grey.800'
  },
  {
    level: 3,
    levelString: WeasseEconomicalPotentialLevel.LEVEL_3,
    label: 'common.levels.level_3',
    description: 'economicalPotential.informations.level_3',
    bgcolor: colorTableForLevels.level_3,
    color: 'grey.800'
  },
  {
    level: 2,
    levelString: WeasseEconomicalPotentialLevel.LEVEL_2,
    label: 'common.levels.level_2',
    description: 'economicalPotential.informations.level_2',
    bgcolor: colorTableForLevels.level_2,
    color: 'grey.800'
  },
  {
    level: 1,
    levelString: WeasseEconomicalPotentialLevel.LEVEL_1,
    label: 'common.levels.level_1',
    description: 'economicalPotential.informations.level_1',
    bgcolor: colorTableForLevels.level_1,
    color: 'grey.800'
  },
  {
    level: 0,
    levelString: WeasseEconomicalPotentialLevel.LEVEL_0,
    label: 'common.levels.level_0',
    description: 'economicalPotential.informations.level_0',
    bgcolor: colorTableForLevels.level_0,
    color: 'primary.contrastText'
  }
];

function EconomicalPotentialInfo(): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack spacing={6} sx={{ color: theme.palette.text.primary }}>
      {EconomicalPotentialLevelInfo.map(info => (
        <Stack key={info.label} spacing={2}>
          <Chip
            label={t(info.label)}
            sx={{
              bgcolor: info.bgcolor,
              '.MuiChip-label': {
                fontWeight: 700,
                color: theme.palette.primary.contrastText
              }
            }}
          />
          <Typography
            variant='body1'
            dangerouslySetInnerHTML={{
              __html: t(info.description)
            }}
          />
        </Stack>
      ))}
    </Stack>
  );
}

export default EconomicalPotentialInfo;
