import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { SegmentationData } from '@deecision/dna-interfaces/dist/segmentation/segmentation';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { IconInfoCircle, IconScreenshot, IconX } from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import { PotentialSegmentationCriteria } from '@/main/containers/segmentations/types';
import EconomicalPotentialInfo from '@/main/containers/economical-potential/info';
import captureScreenshot from '@/utils/screenshot';

export const criteriasWithInfo = {
  'person_weasse_economical_potential': <EconomicalPotentialInfo />
};

function CriteriasInfo(props: { criteria: PotentialSegmentationCriteria & SegmentationData['segmentationCriterias'][0] }): ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Tooltip title={t('common.informations.tooltip')} arrow>
        <IconButton onClick={() => setOpen(true)} color='primary'>
          <IconInfoCircle size='1.2rem' />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        maxWidth='sm'
        fullWidth
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <DialogTitle id='scroll-dialog-title' sx={{ fontSize: '1.2rem' }}>
            {t('common.utils.informations')}
          </DialogTitle>
          <DialogActions>
            <IconButton
              onClick={() => captureScreenshot(descriptionElementRef)}
            >
              <IconScreenshot size='1.4rem' />
            </IconButton>
            <IconButton onClick={handleClose}>
              <IconX size='1.2rem' />
            </IconButton>
          </DialogActions>
        </Stack>
        <DialogContent dividers>
          <DialogContentText
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {criteriasWithInfo[props.criteria.filterId as keyof typeof criteriasWithInfo]}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CriteriasInfo;
