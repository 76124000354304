import React, { ReactElement } from 'react';
import { isArray } from 'lodash';
import { FormControl, InputLabel, Select, SelectChangeEvent, MenuItem } from '@mui/material';
import { AdminFilters } from '../types';

interface MultiSelectFilterProps {
  filter: AdminFilters,
  activeFilters: AdminFilters[],
  handleFilterChange: (filter: AdminFilters, values: string[] | boolean) => void
}

function MultiSelectFilter(props: MultiSelectFilterProps): ReactElement {
  return (
    <FormControl variant='outlined' size='small' style={{ minWidth: `${props.filter.propertyKey.length + 5}ch`, width: 'auto' }}>
      <InputLabel>{props.filter.label}</InputLabel>
      <Select
        multiple
        label={props.filter.label}
        name={props.filter.propertyKey}
        value={props.activeFilters?.find(activeFilter => activeFilter.propertyKey === props.filter.propertyKey)?.values || []}
        onChange={(e: SelectChangeEvent<string[]>) => {
          const valueAsArray = isArray(e.target.value) ? e.target.value : [e.target.value];

          props.handleFilterChange(props.filter, valueAsArray);
        }}
      >
        {props.filter.values?.map(value => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelectFilter;