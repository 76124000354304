import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import {
  BasePortfolioDashboardProps,
  DashboardConfig,
  DashboardSection as DashboardSectionType,
  dashboardSpacing
} from './types';
import PortfolioDashboardGroup from './group';
import PortfolioDashboardDataflow from './dataflow';
import PortfolioDashboardGroupSelect from './groupselect';
import PortfolioDashboardGroupSegmentation from './groupsegmentation';
import PatrimonialComplexityPortfolioDashboardContainers from './patrimonialcomplexity';
import DashboardSection from '@/main/containers/dashboards/section';
import PortfolioDashboardProxemee, { getProxemeeSubGroups } from '@/main/containers/dashboards/portfolio/proxemee';
import PortfolioDashboardWidgetSegmentation from '@/main/containers/dashboards/portfolio/widget';

function CompactSection(props: Omit<BasePortfolioDashboardProps, 'group'> & { section: DashboardSectionType }): ReactElement[] {
  if (props.section.type === 'compact' && props.section.widgets) {
    return ([
      <Grid
        key={`compact-with-widgets-${props.section.id}`}
        container
        spacing={6}
      >
        <Grid
          container
          spacing={4}
          size='auto'
          minWidth='min-content'
          maxWidth='min-content'
          height='min-content'
        >
          {[
            ...CompactSection({
              ...props,
              section: {
                ...props.section,
                widgets: undefined
              }
            })
          ]}
        </Grid>
        <Grid
          key={`compact-with-widgets-${props.section.id}-widgets`}
          size='grow'
          minWidth='min-content'
          container
          spacing={4}
          justifyContent='end'
        >
          {props.section.widgets.map(widget => (
            <Grid key={widget.baseGroupId} width='calc(100% - 1px)' minWidth='min-content'>
              <PortfolioDashboardWidgetSegmentation {...props} {...widget} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    ]);
  }

  return (props.section.type === 'compact' && props.section.groups?.map(group => (
    <Grid size={12} key={`compact-${group.subGroupId}`} overflow='auto'>
      <PortfolioDashboardDataflow {...props} group={group} />
    </Grid>
  )) || []
  );
}

function FullSection(props: Omit<BasePortfolioDashboardProps, 'group'> & { section: DashboardSectionType }): ReactElement[] {
  if (props.section.groups && getProxemeeSubGroups({ subGroupId: props.section.id, subGroups: props.section.groups } ).length > 0) {
    return [<PortfolioDashboardProxemee full {...{ baseGroupId: props.baseGroupId, group: { subGroupId: props.section.id, subGroups: props.section.groups }, callSegmentation: props.callSegmentation }} />];
  }

  return (props.section.type === 'full' && props.section.groups?.map(group => (
    <Grid size={12} container key={`full-${group.subGroupId}`} overflow='auto'>
      {{
        select: <PortfolioDashboardGroupSelect {...props} group={group} />,
        segmentation: <PortfolioDashboardGroupSegmentation {...props} group={group} />,
        parent: <PortfolioDashboardGroup {...props} group={group} />,
        custom: group.subGroupId === 'patrimonialComplexity' ? <PatrimonialComplexityPortfolioDashboardContainers {...props} group={group} /> : null
      }[group.type || 'parent']}
    </Grid>
  )) || []
  );
}

function PortfolioDashboardContainers(props: { dashboardConfig: DashboardConfig } & Omit<BasePortfolioDashboardProps, 'group'>): ReactElement {
  const { t } = useTranslation();

  return (
    <Grid container alignItems='stretch' spacing={6} flexWrap='wrap'>
      {props.dashboardConfig.sections.map(section => (
        <Grid
          size={section.type === 'compact' ? 12 : section.id === 'alerts' ? 'auto' : 'grow'}
          width={section.id === 'alerts' ? 'min-content' : '100%'}
          minWidth={380}
          key={section.id}
        >
          <DashboardSection
            title={section.noLabel ? undefined : t(`segmentation.dashboard.sections.${section.id}.label`)}
            icon={section.noLabel ? undefined : section.icon}
            direction='column'
            spacing={section.id === 'alerts' ? dashboardSpacing : 6}
          >
            {[
              ...CompactSection({ ...props, section }),
              ...FullSection({ ...props, section })
            ]}
          </DashboardSection>
        </Grid>
      ))}
    </Grid>
  );
}

export default PortfolioDashboardContainers;
