import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { IconDeviceFloppy, IconTrash } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { EntityType, GroupDesc } from '@deecision/dna-interfaces';
import Button from '@mui/material/Button';
import { isEqual } from 'lodash';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { VennDiagramConfig, VennDiagramSegment } from '@/main/containers/vennDiagram/types';
import CustomAccordion from '@/components/accordion';
import VennDiagramServices from '@/main/containers/vennDiagram/services';
import AddFiltersBuilderVenn, { PossibleFiltersTreeView } from '@/main/containers/vennDiagram/builder/segments/add';
import ColorSelector from '@/main/containers/vennDiagram/builder/segments/color-selector';
import GenericSegmentVennDiagram from '@/main/containers/vennDiagram/builder/segments/generic';
import { mainRadius } from '@/theme/theme';

function Segment(props: { data: VennDiagramConfig, objectType: EntityType, segment: Partial<VennDiagramSegment>, onSegmentChange: (segment: VennDiagramSegment) => void, deleteSegment: (segmentId: string) => void }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [segment, setSegment] = useState<typeof props.segment>(props.segment);
  const [possibleFilters, setPossibleFilters] = useState<GroupDesc[]>();
  const dataService = new VennDiagramServices();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState((props.segment.groupIds?.length || 0) < 1);

  const getPossibleFilters = () => {
    dataService.getGroupDescs().then((res) => {
      setPossibleFilters((res.data as GroupDesc[] & { label: string })
        .filter(possibleFilter => possibleFilter.objectType === props.objectType)
      );
    });
  };

  useEffect(() => {
    getPossibleFilters();
  }, []);

  return (
    <CustomAccordion
      key={`${props.segment.label}-${props.segment.behavior}-${props.segment.color}-${props.segment.groupIds?.length || 0}`}
      title={
        <Stack direction='row' spacing={4} alignItems='center' width='100%' pr={4}>
          <Typography variant='h5'>
            {segment.label || props.segment.label || t('segmentation.vennDiagram.builder.segments.fields.title')}
          </Typography>
          {!expanded ?
            <Box
              sx={{
                height: '1.6rem',
                width: '1.6rem',
                backgroundColor: segment.color,
                borderRadius: '50%'
              }}
            />
            : null
          }
          <Chip
            label={t(`segmentation.vennDiagram.builder.segments.behavior.${segment.behavior}`)}
            size='small'
          />
          <Chip
            label={`${segment.groupIds?.length || 0} ${t('segmentation.vennDiagram.render.filters.label')}`}
            size='small'
            color='primary'
          />
          <IconButton
            sx={{
              ml: 'auto !important'
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (segment.id) {
                props.deleteSegment(segment.id);
              }
            }}
          >
            <IconTrash size='1.6rem' color={theme.palette.error.main} />
          </IconButton>
        </Stack>
      }
      defaultOpen={(props.segment.groupIds?.length || 0) < 1}
      borderColor={(props.segment.groupIds?.length || 0) < 1 ? 'primary.main' : undefined}
      bgcolor={theme.palette.background.paper}
      onOpenChange={setExpanded}
    >
      <Stack spacing={4}>
        <Stack spacing={1} p={4} width='100%'>
          <FormLabel required>
            {t('segmentation.vennDiagram.builder.segments.fields.name')}
          </FormLabel>
          <FormControl fullWidth>
            <TextField
              onClick={e => e.stopPropagation()}
              value={segment.label}
              onChange={e => setSegment({ ...segment, label: e.target.value })}
            />
          </FormControl>
        </Stack>
        <Stack spacing={1} p={4}>
          <FormLabel required>
            {t('segmentation.vennDiagram.builder.segments.fields.color')}
          </FormLabel>
          <ColorSelector
            segment={segment}
            modifyColor={color => setSegment({ ...segment, color })}
            usedColors={props.data.segments.map(s => s.color)}
          />
        </Stack>
        <Divider />
        <Grid container spacing={2} width='100%' pl={4} pr={4}>
          {(segment.groupIds?.length || 0) > 1 ?
            <Grid>
              <Stack spacing={2} height='100%'>
                <Box
                  pt={6}
                  width='100%'
                  height='100%'
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Box
                    height='100%'
                    width='50%'
                    borderLeft='2px solid'
                    borderTop='2px solid'
                    borderColor={segment.color}
                    sx={{
                      borderTopLeftRadius: mainRadius
                    }}
                  />
                </Box>
                <Tooltip title={t(`segmentation.vennDiagram.builder.segments.behavior.${segment.behavior}Tooltip`)} arrow placement='bottom'>
                  <FormControl>
                    <Select
                      native
                      variant='outlined'
                      size='small'
                      value={segment.behavior}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setSegment({
                          ...segment,
                          behavior: e.target.value as VennDiagramSegment['behavior']
                        });
                      }}
                      sx={{
                        width: '76px',
                        '& .MuiSelect-select': {
                          border: 'none'
                        }
                      }}
                    >
                      <option value='union'>{t('segmentation.vennDiagram.builder.segments.behavior.union')}</option>
                      <option value='intersection'>{t('segmentation.vennDiagram.builder.segments.behavior.intersection')}</option>
                    </Select>
                  </FormControl>
                </Tooltip>
                <Box
                  pb={6}
                  width='100%'
                  height='100%'
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Box
                    height='100%'
                    width='50%'
                    borderLeft='2px solid'
                    borderBottom='2px solid'
                    borderColor={segment.color}
                    sx={{
                      borderBottomLeftRadius: mainRadius
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
            : null
          }
          {(segment.groupIds?.length || 0) > 0 ?
            <Grid size='grow'>
              <Stack spacing={1} height='100%' justifyContent='space-evenly'>
                {segment.groupIds?.map(groupId => (
                  <Paper elevation={0} sx={{ width: '100%', p: 2 }}>
                    <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
                      <FormControl fullWidth>
                        <Select
                          open={open}
                          onOpen={() => setOpen(true)}
                          onClose={() => setOpen(false)}
                          id='select-group-id-select'
                          size='small'
                          value={groupId}
                        >
                          <MenuItem value={groupId} disabled>
                            {possibleFilters ? possibleFilters.find(possibleFilter => `${possibleFilter.baseGroupId}/${possibleFilter.subGroupId}` === groupId)?.label as string || groupId : groupId}
                          </MenuItem>
                          {possibleFilters ?
                            <PossibleFiltersTreeView
                              possibleFilters={possibleFilters.filter(possibleFilter => !segment.groupIds?.includes(`${possibleFilter.baseGroupId}/${possibleFilter.subGroupId}`))}
                              addSegmentationFilters={(filters: GroupDesc[]) => setSegment({ ...segment, groupIds: [...(segment.groupIds?.filter(grpId => grpId !== groupId) || []), ...filters.map(filter => `${filter.baseGroupId}/${filter.subGroupId}`)] })}
                              addSegmentationFilterCategory={() => undefined}
                              handleClose={() => setOpen(false)}
                              disableAddAll
                            /> : null
                          }
                        </Select>
                      </FormControl>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setSegment({ ...segment, groupIds: segment.groupIds?.filter(g => g !== groupId) });
                        }}
                      >
                        <IconTrash size='1.2rem' color={theme.palette.error.main} />
                      </IconButton>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            </Grid> :
            null
          }
          {segment.behavior && (segment.groupIds?.length || 0) > 0 ?
            <Grid>
              <GenericSegmentVennDiagram
                behavior={segment.behavior}
                color={segment.color || '#808080'}
                height={200}
              />
            </Grid>
            : null
          }
        </Grid>
        {possibleFilters ?
          <Box p={4} pt={0}>
            <AddFiltersBuilderVenn
              possibleFilters={possibleFilters.filter(possibleFilter => !segment.groupIds?.includes(`${possibleFilter.baseGroupId}/${possibleFilter.subGroupId}`))}
              addSegmentationFilters={(filters: GroupDesc[]) => setSegment({ ...segment, groupIds: [...(segment.groupIds || []), ...filters.map(filter => `${filter.baseGroupId}/${filter.subGroupId}`)] })}
              addSegmentationFilterCategory={(category?: string) => setSegment({ ...segment, groupIds: [...(segment.groupIds || []), ...possibleFilters?.filter(possibleFilter => (!segment.groupIds?.includes(`${possibleFilter.baseGroupId}/${possibleFilter.subGroupId}`) && category ? possibleFilter.categories?.includes(category) : false)).map(possibleFilter => `${possibleFilter.baseGroupId}/${possibleFilter.subGroupId}`) || []] })}
            />
          </Box> : null
        }
        <Divider />
        <Stack direction='row' spacing={2} p={4}>
          <Button
            variant='contained'
            startIcon={<IconDeviceFloppy size='1.2rem' />}
            onClick={() => props.onSegmentChange(segment as VennDiagramSegment)}
            disabled={isEqual(segment, props.segment) || !segment.label || !segment.color || !((segment.groupIds?.length || 0) > 0)}
          >
            {t('common.utils.save')}
          </Button>
        </Stack>
      </Stack>
    </CustomAccordion>
  );
}

export default Segment;
