import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { IDataBlock, VeegilenzSummaryAllDataBlockContent } from '@deecision/dna-interfaces';
import { Box, Divider, Typography } from '@mui/material';
import { IconAlertTriangle } from '@tabler/icons-react';
import * as _ from 'lodash';
import { WidgetProps } from '@/main/containers/widgets/types';
import TitleComponent from '@/components/title';
import BaseWidget from '@/main/containers/widgets/base-widget';
import VEEGILENZ from '@/assets/VEEGILENZ.svg';
import GradientSlider from '@/components/slider';
import { CompanyGetter, PersonGetter } from '@/main/providers/getter';
import { DataBlocksQueryService } from '@/api/services/query';
import { veegilenzSummaryPaths } from '@/main/containers/veegilenz';
import Completeness from '@/components/chips/completeness';
import dnaConfig from '@/config/dna.config.json';

function WidgetVeegilenz(props: WidgetProps & { noCompletude?: boolean }) {
  const { t } = useTranslation();
  const entityGetter = props.data.entityType === 'deecPerson'
    ? new PersonGetter({ data: props.data, errorMsg: t('common.utils.unknown') })
    : new CompanyGetter({ data: props.data, errorMsg: t('common.utils.unknown') });
  const dataBlockService = new DataBlocksQueryService<VeegilenzSummaryAllDataBlockContent>({ dataPath: veegilenzSummaryPaths });
  const [veegilenzDataBlocks, setVeegilenzDataBlocks] = useState<IDataBlock<VeegilenzSummaryAllDataBlockContent>[]>();
  const score = parseFloat(entityGetter.getVeegilenzLevel(true).toString());

  useEffect(() => {
    dataBlockService.get(props.data.entityId)
      .then(res => setVeegilenzDataBlocks(res.data));
  }, []);

  if (_.isEmpty(veegilenzDataBlocks)) {
    return <BaseWidget
      {...props}
      title={
        <TitleComponent
          title={
            <img src={VEEGILENZ} alt='Veegilenz' height={18} />
          }
          icon={IconAlertTriangle}
          small
        />
      }
      link={!props.noCompletude ? `../${dnaConfig.routes.persons.tabs.veegilenz.baseUrl}` : undefined}
      fullHeight
      disableRipple
    >
      <Typography align='center'>
        {t('common.utils.noData')}
      </Typography>
    </BaseWidget>;
  }

  return (
    <BaseWidget
      {...props}
      title={
        <TitleComponent
          title={
            <img src={VEEGILENZ} alt='Veegilenz' height={18} />
          }
          icon={IconAlertTriangle}
          small
        />
      }
      link={!props.noCompletude ? `../${dnaConfig.routes.persons.tabs.veegilenz.baseUrl}` : undefined}
      fullHeight
      disableRipple
    >
      <Stack spacing={2} minWidth={380} width='100%' p={2} pt={0}>
        <Typography variant='h5'>{t('veegilenz.score')}</Typography>
        <Box pl={score > 0.5 ? 2 : 6} pr={score < 2.5 ? 2 : 6}>
          <GradientSlider
            value={score}
            indexStart={1}
          />
        </Box>
        {!props.noCompletude
          ? <Divider />
          : null
        }
        {!props.noCompletude
          ? <Typography maxWidth={364}>
            <span style={{ marginRight: 4 }}>
              <Completeness
                completeness={veegilenzDataBlocks?.[0].data?.completeness?.value || 0}
              />
            </span>
            {t('veegilenz.completeness')}
          </Typography>
          : null
        }
      </Stack>
    </BaseWidget>
  );
}

export default WidgetVeegilenz;
